import React, { Suspense, lazy } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import '../assets/css/PreciosSection/styles.css';
// import CarouselSeguros from '../components/Carousel-Seguros';

// Lazy load para componentes secundarios

const SectionUnidadesMedicas = lazy(() => import('../components/SectionUnidadesMedicas.jsx'));
const CarouselSeguros = lazy(() => import('../components/Carousel-Seguros.jsx'));


const HomePage = ({ title, description, keywords, canonical }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link rel='canonical' href={canonical} />
    </Helmet>
    <header className="App-header">
      <HeroSection />
    </header>
    <main className="main-content py-5">
      {/* Suspense para cargar SectionUnidadesMedicas */}
      <Suspense fallback={<div className="loader">Cargando unidades médicas...</div>}>
        <SectionUnidadesMedicas />
      </Suspense>
    </main>


    <Container fluid className='p-5'>
      <Row className="row gy-4">

        <Col lg={6} data-aos="fade-up" data-aos-delay="100" className='d-flex flex-column justify-content-center align-items-center content'>
          <h3 className='text-primary h1'>Conoce nuestro equipo de expertos</h3>
          <p className="fst-italic fw-500">Con mas de 15 años de experiencia en el área de la Medicina, conociendo a los pacientes, a las enfermedades y a los profesionales de la salud, nos dedicamos a brindar la mejor atención a nuestros pacientes.</p>
        </Col>

        <Col lg={6} className="about-images aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <Row className="row gy-4">
            <Col xs={6} className='d-flex align-items-center'>
              <img
                src="https://elmedicointeractivo.com/wp-content/uploads/2022/05/gremio-medico-se-defiende--e1652261255864.jpg"
                className="img-fluid rounded-4"
                alt=""
                loading="lazy"
                width="500" // Ancho fijo
                height="300" // Alto fijo
              />
            </Col>
            <Col xs={6}>
              <Row className="row gy-4">
                <Col lg={12}>
                  <img loading="lazy" src="https://estepais.com/wp-content/uploads/2019/10/imagenes-estepais-doctores.jpg" className="img-fluid rounded-4" alt=""></img>
                </Col>
                <Col lg={12}>
                  <img loading="lazy" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJDCvGiKOVfCrELAnLAD8jea2Bl-zuxvI4ig&s" className="img-fluid rounded-4" alt=""></img>
                </Col>
              </Row>
            </Col>
          </Row>

        </Col>
        <div>
          <Link to="/Doctores" className="button-equipo-medico mt-5 mt-lg-2"><span>Ver Equipo Médico</span><i className="bi bi-arrow-right"></i></Link>
        </div>

      </Row>
      <Suspense fallback={<div className="loader">Cargando seguros..</div>}>
        <CarouselSeguros />
      </Suspense>
    </Container>
    

  </>
);

export default HomePage;
