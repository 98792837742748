import React, { Suspense, lazy} from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import { Link } from 'react-router-dom';
// Importamos los servicios desde PreciosyServicios.jsx
import PreciosyServicios from '../components/PreciosyServicios.jsx';
import ServiceCard2 from '../components/extras/ServiceCard2.jsx';

const CarouselSeguros = lazy(() => import('../components/Carousel-Seguros.jsx'));

const CheckUpPage = ({ pageData }) => {
  // Filtramos los servicios que pertenecen a la categoría CheckUp
  const checkUpServices = Object.values(PreciosyServicios).filter(service =>
    service.modules && service.modules.includes('CheckUp')
  );

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section className="unidades-section">
        <Container fluid>
          <h1 className='text-uppercase'>CLÍNICA DE {pageData.title}</h1>
          <hr />
          <Row className='p-lg-5 d-flex align-items-center'>
            <Col className='p-lg-5' md={6}>
              <div className="d-flex flex-column">
                <p>Es un examen clínico diagnóstico y preventivo de el tubo digestivo, sitio de alta frecuencia de enfermedades, sangrado y cáncer en la población mexicana.</p>
                <ul className='d-flex flex-column align-items-start unstyled'>
                  <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios CheckUp</Link>
                  <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Endoscopia</Link>
                  <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Endoscopia Digestiva Alta</Link>
                </ul>
              </div>
            </Col>
            <Col className='p-5 align-items-center' md={6}>
              <div className="info-item d-flex justify-content-around">
                <div>
                  <img className='img-fluid rounded-4' width={500} src="https://unidad-gastroenterologia.com/images/unidades/gastroenterologia.webp" alt="Imagen de unidad de gastroenterologia" />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
        </Container>

        <Container className='text-start check-section'>
          <h4>Entre los Beneficios de realizarse un Check Up está la detección oportuna de diversos tipos de enfermedades, que si se les detecta a tiempo pueden ser tratadas con eficacia.</h4>
          <p>Entre las enfermedades que se pueden detectar oportunamente con el Check Up Gastrointestinal están:</p>
          <ul className='unstyled'>
            <li><i className="bi bi-chevron-right mx-1"></i>Cáncer de Esófago</li>
            <li><i className="bi bi-chevron-right mx-1"></i>Cáncer de Colon y Recto</li>
            <li><i className="bi bi-chevron-right mx-1"></i>Cáncer Gástrico</li>
            <li><i className="bi bi-chevron-right mx-1"></i>Enfermedades de la Vesícula Biliar como la Colecisitis Litiasicas, Coledocolitiasis</li>
            <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
              <Link to={'/reflujo'}>Enfermedad Por Reflujo Gastroesofágico (ERGE)</Link>
            </li>
            <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
              <Link to={'/esofago-barret'}>Esofago Barret</Link>
            </li>
            <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
              <Link to={'/polipectomia-y-mucosectomia-endoscopica'}>Poliposis Intestinal</Link>
            </li>
          </ul>
        </Container>

        <Container fluid>
          <div className='service-section p-4'>
            <h4>Servicios que ofrecemos en la <br></br>Unidad de {pageData.title}:</h4>
            <p className='p-2 p-md-5'>
              Nuestra Unidad de <span className='text-primary fw-bold'>Check-Up</span> es un centro especializado en la detección temprana y
              prevención de enfermedades digestivas. Nuestro objetivo es ofrecerte una <span className='text-primary'>
              atención médica integral</span> con los más altos estándares de calidad, respaldados por los últimos avances en 
              la <span className='text-primary fw-bold'>Gastroenterología</span>.
            </p>
            <p className='p-2 text-primary fw-bold'>
              Realizarte un Check-Up te permitirá:
            </p>
            <ul className='unstyled flex-column text-start px-2'>
              <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Detectar enfermedades graves en etapas tempranas, como el cáncer gástrico o de colon.</li>
              <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Monitorear tu salud digestiva de manera personalizada.</li>
              <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Recibir tratamientos oportunos para evitar complicaciones.</li>
            </ul>
          </div>

          <Row className='text-start d-flex justify-content-center'>
            {checkUpServices.map((service) => (
              <Col key={service.id} md={3} className="d-flex">
                <ServiceCard2 service={service} />
              </Col>
            ))}
          </Row>
        </Container>
        <Suspense fallback={<div className="loader">Cargando seguros..</div>}>
          <CarouselSeguros />
        </Suspense>
      </section>
    </>
  );
};

export default CheckUpPage;
