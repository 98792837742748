import React, { useState, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import DoctorList from '../components/DoctorList';
import ServiceListPH from '../components/ServiceListPH.jsx';

const CarouselSeguros = lazy(() => import('../components/Carousel-Seguros.jsx'));


const PHMeteriaManometriaPage = ({ pageData }) => {
    const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section className="unidades-section">
        <Container fluid>
          <div className="my-5">
            <h1>Clínica de Fisiología Digestiva</h1>
            <hr />
            <Row className='p-lg-5 d-flex align-items-center'>
              <Col className='p-lg-5' md={6}>
                <div className="info-item d-flex align-items-center">
                  <div>
                    <p>Una clínica de fisiología digestiva se especializa en el diagnóstico y tratamiento de trastornos del sistema digestivo. A continuación se presentan algunos de los servicios y enfoques típicos que puedes encontrar en una clínica de este tipo.</p>
                    {/* <p>La pHMetría esofágica es un estudio que mide el pH del contenido esofágico para evaluar la presencia y la frecuencia del reflujo ácido. Este procedimiento es útil para diagnosticar transtornos com eso reflujo gastroesofágico. Para llevar a cabo la pHMería se introduce un sensor a traves de la nariz y se coloca en el esofago, donde permanece durante 24 horas para registrar los niveles de acidez. Este procedimiento lo realiza un Gástroenterologo con alta especialidad en fisiología digestiva.</p> */}
                  </div>
                </div>
              </Col>
              <Col className='p-5 align-items-center' md={6}>
                <div className="info-item d-flex justify-content-around">
                  <div>
                    <img className='img-fluid rounded-4' width={300} src="https://unidad-gastroenterologia.com/images/gastro_coloproctologia.jpg" alt="Imagen de Coloproctología" />
                  </div>
                </div>
              </Col>
              {/* <Col>
                <div className="info-item d-flex align-items-center">
                  <div>
                    <h2 className='p-3 p-lg-5'>Información médica sobre diagnóstico y tratamiento de enfermedades relacionadas con {pageData.title}.</h2>
                  </div>
                </div>
              </Col> */}
            </Row>
            <Row>
              <h3>Servicios Comunes</h3>
              <div className='text-start text-ph'>
                <h4>Evaluación Diagnóstica</h4>
                <ul>
                  <li>Endoscopias (gastroscopias, colonoscopias)</li>
                  <li>Estudios de imagen (ecografías, tomografías)</li>
                  <li>Análisis de laboratorio (pruebas de sangre, análisis de heces)</li>
                </ul>
                <h4>Tratamientos</h4>
                <ul>
                  <li>Terapias nutricionales y dietas especializadas.</li>
                  <li>Manejo de enfermedades como reflujo gastroesofágico, gastritis, síndrome del intestino irritable, entre otros.</li>
                </ul>
                <h4>Rehabilitación Digestiva</h4>
                <ul>
                  <li>Programas de recuperación tras cirugías digestivas.</li>
                  <li>Tratamiento de trastornos funcionales del intestino.</li>
                </ul>
                <h4>Educación al Paciente</h4>
                <ul>
                  <li>Talleres y charlas sobre la salud digestiva, hábitos alimenticios saludables y técnicas de manejo de estrés que pueden afectar la digestión.</li>
                </ul>
                <h4>Investigación</h4>
                <ul>
                  <li>Participación en estudios clínicos para evaluar nuevos tratamientos y terapias para trastornos digestivos.</li>
                </ul>
                <h4>Importancia de la Fisiología Digestiva</h4>
                <p>La fisiología digestiva es crucial porque afecta la absorción de nutrientes, la regulación del sistema inmunológico y el equilibrio de la microbiota intestinal. Un diagnóstico y tratamiento adecuados pueden mejorar significativamente la calidad de vida de los pacientes.</p>
                <p>Si necesitas más información específica o asesoramiento sobre un tema particular relacionado con la fisiología digestiva, no dudes en preguntar.</p>
              </div>


            </Row>
            <div className='doctor-section-content'>
                <h2>Servicios que ofrecemos en la <br></br>Unidad de pHMetría y Manometría:</h2>
                <h4 className='p-2 p-lg-5 text-start text-lg-center'>Bienvenido a nuestra Clínica de pHMetría y Manometría, un centro especializado en el diagnóstico, tratamiento y prevención de enfermedades del colón, recto y ano. Nos mantenemos a la vanguardia con técnicas modernas, como ligaduras con bandas elasticas, cirugía laser, colonoscopía de alta resolución para garantizar resultados optimos con tiempos de recuperación minima. Contamos con un equipo de Coloproctologos altamente capacitados.</h4>
                <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === 'Procedimiento' ? 'active' : ''}`} onClick={() => handleServiceSelect('Procedimiento')}>Procedimiento</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <ServiceListPH serviceToShow={selectedService} moduleToShow="pHMetria" />
              </div>
            <div className='doctor-section-content'>
              <h2>Conoce a nuestro Equipo Médico:</h2>
              <h4 className='p-2 p-lg-5 text-start text-lg-center'>Contamos con un Equipo de Gastroenterólogos altamente capacitados y con experiencia en diversas Sub Especialidades, Nuestos Médicos estan comprometidos a mantenerse al dia con las ultimas investigaciones
              y técnicas en el campo de la Gastroenterológia, garantizando así tratamientos basados en evidencias y protocolos actualizados :</h4>
              <div>
              </div>
              <DoctorList specialtyToShow={'Motilidad Intestinal'} groupToShow={'PHmetria'} />
            </div>
            <Suspense fallback={<div className="loader">Cargando seguros..</div>}>
              <CarouselSeguros/>
            </Suspense>
          </div>
        </Container>
      </section>
    </>
  );
};

export default PHMeteriaManometriaPage;