const DynamicPagesData = {
    Gastroenterologia: {
        UlceraGastrica: {
          title: 'Úlcera Gástrica: Causas, Tratamientos y Complicaciones',
          description: 'Descubre información completa sobre la úlcera gástrica: síntomas, causas, diagnóstico y las últimas opciones de tratamiento endoscópico.',
          keywords: 'úlcera gástrica, síntomas úlcera, tratamiento de úlcera, complicaciones gástricas',
          canonical: 'https://unidad-gastroenterologia.com/ulcera',
          link: '/ulcera',
          className: 'gastroenterologia-page',
          image: 'https://unidad-gastroenterologia.com/static/images/ulcera-gastrica.jpg',
          sections: [
              {
                  titleSection: '¿Qué es la Úlcera Gástrica?',
                  content: [
                      'La úlcera gástrica, también conocida como úlcera péptica, es una lesión en la mucosa gástrica causada por un desequilibrio entre los factores protectores y agresores del estómago.',
                      'Este desequilibrio puede deberse a infecciones bacterianas como el Helicobacter pylori o al uso excesivo de medicamentos antiinflamatorios no esteroides (AINEs).',
                  ],
                  image: 'https://unidad-gastroenterologia.com/static/images/ulcera-gastrica-explicacion.jpg',
              },
              {
                  titleSection: 'Causas Principales de la Úlcera Gástrica',
                  content: [
                      '- **Infección por Helicobacter pylori**: Es responsable de la mayoría de los casos de úlceras gástricas.',
                      '- **Uso de medicamentos AINEs**: Estos medicamentos reducen la producción de moco protector en el estómago.',
                      '- **Factores genéticos y estrés**: Pueden aumentar el riesgo de desarrollar úlceras.',
                  ],
                  image: 'https://unidad-gastroenterologia.com/static/images/causas-ulcera.jpg',
              },
              {
                  titleSection: 'Opciones de Tratamiento',
                  content: [
                      'El tratamiento puede incluir:',
                      '- Antibióticos para eliminar el Helicobacter pylori.',
                      '- Inhibidores de bomba de protones (IBPs) para reducir la acidez gástrica.',
                      '- Cambios en el estilo de vida, como evitar el alcohol y el tabaco.',
                  ],
              },
          ],
      },
      Gastritis: {
        title: 'Gastritis: Causas, Diagnóstico y Tratamientos',
        description: 'La gastritis es una inflamación de la mucosa gástrica que puede ser aguda o crónica. Conoce sus causas, síntomas y opciones de tratamiento.',
        keywords: 'gastritis, inflamación estomacal, Helicobacter pylori, síntomas de gastritis, tratamiento gastritis, endoscopia gástrica',
        canonical: 'https://unidad-gastroenterologia.com/gastritis',
        link: '/gastritis',
        className: 'enfermedades-estomago-page',
        image: 'https://unidad-gastroenterologia.com/images/gastritis.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Gastritis?',
            content: [
              'La gastritis es una inflamación de la mucosa del estómago que puede clasificarse en:',
              '- **Aguda**: Inflamación de corta duración.',
              '- **Crónica**: Inflamación prolongada que puede llevar a atrofia gástrica.',
              'Cada tipo puede identificarse claramente mediante una endoscopia.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/gastritis-diagnostico.jpg',
          },
          {
            titleSection: 'Agentes Causantes de Gastritis',
            content: [
              'La gastritis crónica está frecuentemente asociada con:',
              '- Infección por *Helicobacter pylori*.',
              '- Uso de antiinflamatorios no esteroides (AINEs) como aspirina y diclofenaco.',
              '- Reflujo biliar hacia el estómago.',
              '- Consumo excesivo de alcohol y cafeína.',
            ],
          },
          {
            titleSection: 'Síntomas de la Gastritis',
            content: [
              'Los síntomas más comunes incluyen:',
              '- Dolor abdominal y sensación de ardor en la boca del estómago.',
              '- Náuseas y pérdida de apetito.',
              '- Vómitos de contenido gástrico en casos más graves.',
              '- En gastritis crónica, puede haber intolerancia alimentaria y pérdida significativa de peso.'
            ],
          },
          {
            titleSection: 'Diagnóstico: Endoscopia Gastronómica',
            content: [
              'El diagnóstico de gastritis se realiza mediante:',
              '- Endoscopia gastrointestinal alta.',
              '- Toma de biopsia de la mucosa gástrica para confirmar la presencia de *Helicobacter pylori*.',
            ],
          },
          {
            titleSection: 'Tratamiento de la Gastritis',
            content: [
              'El tratamiento depende de la causa subyacente:',
              '- **Infección por Helicobacter pylori**: Se requiere un tratamiento de erradicación con antibióticos, lo que puede prevenir complicaciones graves como úlceras gástricas y cáncer de estómago.',
              '- **Agentes agresores**: Suspender el uso de alcohol, aspirina, cafeína y otros irritantes.',
              '- Durante la fase de curación, se recomienda una dieta libre de alimentos ácidos, picantes y altamente condimentados para proteger la mucosa gástrica.',
            ],
            image: 'https://unidad-gastroenterologia.com/images/tratamiento-gastritis.jpg',
          },
          {
            titleSection: 'Medicamentos para la Gastritis',
            content: [
              'Para neutralizar el ácido gástrico, se utilizan terapias de supresión ácida como:',
              '- Antagonistas H2 (por ejemplo, ranitidina).',
              '- Inhibidores de bomba de protones (por ejemplo, omeprazol).',
              '- Antiácidos (por ejemplo, gel de aluminio y magnesio).'
            ],
          },
          {
            titleSection: 'Pronóstico de la Gastritis',
            content: [
              'El pronóstico varía según la causa:',
              '- **Gastritis aguda**: Suele resolverse rápidamente con el tratamiento adecuado.',
              '- **Gastritis crónica**: Puede ser más difícil de tratar, especialmente cuando está asociada con infecciones persistentes de *Helicobacter pylori*.',
              'Un diagnóstico y tratamiento oportuno son esenciales para prevenir complicaciones graves.'
            ],
          },
        ],
      },
    },
    Coloproctologia: {
        PolipectomiaEndoscopica: {
          title: 'Polipectomia y Mucosectomia Endoscopica',
          description: 'Procedimiento para tratar el reflujo gastroesofágico...',
          keywords: 'funduplicatura, cirugía, reflujo gastroesofágico',
          canonical: 'https://unidad-gastroenterologia.com/polipectomia-y-mucosectomia-endoscopica',
          link: '/polipectomia-y-mucosectomia-endoscopica',
          className: 'coloproctologia-page',
          image: 'https://unidad-gastroenterologia.com/images/polipectomia-con-asa.jpg',
          sections: [
            {
              titleSection: 'Polipectomia y Mucosectomia Endoscopica',
              content: [
                'El Pólipo de colon a toda prominencia o proyecccion de mucosa de colon que protruye hacia la luz. Es decir, son abultamiento que salen en la mucosa que recubre interiormente el intestino grueso.',
              ],
              image: 'https://unidad-gastroenterologia.com/images/polipectomia-con-asa.jpg'
            },
            {
              titleSection: '¿QUÉ SON POLIPOS DE COLON?',
              content: [
                'El Pólipo de colon a toda prominencia o proyecccion de mucosa de colon que protruye hacia la luz. Es decir, son abultamiento que salen en la mucosa que recubre interiormente el intestino grueso.',
                'Se dividen según su aspecto en pediculados (tienen un tallo) y sesiles (no lo tienen).',
                'Son importantes también su tamaño y su número. Si son más de 100, es una enfermedad que se llama Poliposis.',
                'Cada polipo debe extirparse, esta biopsia debe ser estudiado por medico patòlogo para definir si se trata de un pólipos de colon neoplásicos o polipoadenomatoso o de un no neoplásicos tambien llamados hiperplásicos, hamartomatosos o inflamatorios.',
                'Lo polipos que son de importancia son los polipos neoplasicos ya que son los que pueden transformarse en cáncer.'
              ],
            },
            {
              titleSection: 'Síntomas de los Polipos',
              content: [
                'Los pólipos practicamente no generan sintomas. Si son grandes pueden sangrar, ocacionando sangrado rectal o una anemia por falta de hierro. La mayor preocupación de los pólipos es su degeneración maligna o trasnformaciòn en cancer.',
              ],
            },
            {
              titleSection: 'DIAGNÓSTICO',
              content: [
                'El mejor método para diagnosticar un polipo es la COLONOSCOPIA, visualiza directamente los pólipos, permite la toma de biopsias hacer un diagnóstico histológico de los mismos e incluso consigue su tratamiento adecuado mediante resección endoscópica completa, la llamada polipectomía.'
              ],
            },
            {
              titleSection: 'TRATAMIENTO ENDOSCOPICO DE LOS POLIPOS POLIPECTOMIA Y MUCOSECTOMIA',
              content: [
                'El tratamiento de los pólipos es su resección completa para impedir su crecimiento y degeneración EN CANCER.',
                'Polipos pequeños pueden resecarse con pinza de biopsia.',
                'Polipos medianos pueden resecarse a través de un asa de polipectomia.',
                'Polipos grandes deben ser sometidos a MUCOSECTOMIA ENDOSCOPICA por el riesgo de ya presentar cáncer en algun segmento.',
              ],
            }
          ],
        },
        SeccaTerapia: {
          title: 'Terapia Secca: Solución Minimamente Invasiva para la Incontinencia Fecal',
          description: 'La terapia Secca es una opción eficaz y mínimamente invasiva para tratar la incontinencia fecal, mejorando la calidad de vida sin necesidad de cirugía.',
          keywords: 'terapia Secca, incontinencia fecal, tratamiento con radiofrecuencia, remodelación del esfínter anal, mejora de síntomas IF',
          canonical: 'https://unidad-gastroenterologia.com/terapia-secca',
          link: '/terapia-secca',
          className: 'tratamientos-especializados-page',
          image: 'https://unidad-gastroenterologia.com/images/tratamiento-con-radiofrecuencia-secca-para-incontinencia-fecal.jpg',
          sections: [
            {
              titleSection: '¿Qué es la Terapia Secca?',
              content: [
                'La terapia Secca es un procedimiento mínimamente invasivo que utiliza energía de radiofrecuencia (RF) para tratar la incontinencia fecal (IF).',
                'Es una alternativa segura y eficaz que se sitúa entre los tratamientos conservadores y las opciones quirúrgicas más invasivas.',
                'Secca mejora significativamente la calidad de vida de los pacientes al restaurar la función del esfínter anal.'
              ],
            },
            {
              titleSection: '¿Quiénes son Candidatos para Secca?',
              content: [
                'La terapia Secca está indicada para pacientes que:',
                '- Experimentan incontinencia fecal al menos una vez por semana.',
                '- Han fracasado con terapias conservadoras como fibra, medicamentos y biofeedback.',
                '- Desean una solución menos invasiva que los implantes o no son candidatos óptimos para cirugía.',
                'Estudios muestran que el 84% de los pacientes experimentan una mejora significativa en sus síntomas.'
              ],
            },
            {
              titleSection: '¿Cómo Funciona la Terapia Secca?',
              content: [
                'Secca administra energía de radiofrecuencia concentrada a los músculos del canal anal, logrando:',
                '- Remodelación interna del esfínter anal.',
                '- Mejora de la presión de reposo anal.',
                '- Reducción de la distensibilidad del tejido.',
                '- Incremento en la sensación rectal.',
                'Este procedimiento ambulatorio dura aproximadamente 45 minutos. Los pacientes suelen reanudar sus actividades normales en pocos días.'
              ],
            },
            {
              titleSection: 'Resultados de la Terapia Secca',
              content: [
                '- **84%** de los pacientes experimentan una mejoría significativa en sus síntomas.',
                '- Resultados sostenidos durante **5 años** según estudios clínicos.',
                '- Tasa de complicaciones inferior al **1%**.',
                '- Más de **3,000 procedimientos realizados** en todo el mundo.'
              ],
            },
            {
              titleSection: 'Evidencia Científica del Mecanismo de Acción',
              content: [
                'La terapia Secca induce cambios estructurales en el músculo liso del esfínter anal, incluyendo:',
                '- Incremento del grosor de la pared interior del esfínter anal interno (EAI).',
                '- Aumento de la presión de reposo anal y alargamiento de la zona de alta presión.',
                '- Disminución de la distensibilidad del tejido rectal.',
                '- Mejora en la proporción de fibras musculares frente al tejido conectivo.',
                '- Incremento de colágeno tipo 1 y reducción de colágeno tipo 3.'
              ],
            },
            {
              titleSection: 'Beneficios Clave de la Terapia Secca',
              content: [
                '- Procedimiento ambulatorio con recuperación rápida.',
                '- Sin necesidad de cirugía invasiva ni implantes.',
                '- Aumento en la sensibilidad rectal, permitiendo mayor control intestinal.',
                '- Mejora gradual y sostenida en la calidad de vida.'
              ],
            },
            {
              titleSection: 'Estudios Clínicos y Resultados',
              content: [
                'Numerosos estudios respaldan la eficacia y seguridad de la terapia Secca, incluyendo:',
                '- Incremento significativo de la presión anal y normalización de reflejos rectoanales a los 6 meses.',
                '- Mejoras en la sensación rectal, aumentando el tiempo de respuesta a la urgencia defecatoria.',
                '- Cambios estructurales documentados en músculo liso mediante ultrasonido endoanal.'
              ],
            },
            {
              titleSection: 'Conclusión',
              content: [
                'La terapia Secca ofrece una solución innovadora para pacientes con incontinencia fecal que buscan una alternativa menos invasiva a la cirugía.',
                'Con un bajo riesgo de complicaciones y resultados clínicos comprobados, Secca es una opción efectiva para restaurar la funcionalidad y mejorar la calidad de vida de los pacientes.'
              ],
            },
          ],
        },
        LigaduraHemorroides: {
          title: 'Ligadura de Hemorroides: Tratamiento Efectivo para Hemorroides Internas',
          description: 'La ligadura de hemorroides es un procedimiento eficaz para tratar hemorroides internas, con mínimas complicaciones y rápida recuperación. Descubre cómo se realiza, sus beneficios y las posibles complicaciones.',
          keywords: 'ligadura de hemorroides, tratamiento hemorroides internas, hemorroides, procedimiento de ligadura, tratamiento quirúrgico hemorroides, ligadura de hemorroides ambulatoria, tratamiento de hemorroides sangrantes',
          canonical: 'https://unidad-gastroenterologia.com/ligadura-de-hemorroides',
          link: '/ligadura-de-hemorroides',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/images/ligadura-de-hemorroides.jpg',
          sections: [
            {
              titleSection: '¿Qué es la Ligadura de Hemorroides?',
              content: [
                'La ligadura de hemorroides es un procedimiento médico mínimamente invasivo utilizado para tratar las hemorroides internas. Consiste en colocar una banda elástica alrededor de la base de la hemorroide, lo que corta su suministro sanguíneo y provoca que la hemorroide se encoja y eventualmente se caiga.',
                'Este tratamiento se realiza en consultorio bajo anestesia local, con la ventaja de que los pacientes pueden regresar a sus actividades diarias en poco tiempo. Es un procedimiento ambulatorio que tiene una tasa de éxito elevada y es recomendado para hemorroides de grado II y III.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/ligadura-de-hemorroides.jpg',
            },
            {
              titleSection: 'Indicaciones para la Ligadura de Hemorroides',
              content: [
                'La ligadura de hemorroides está indicada en pacientes con hemorroides internas de grado II y III, especialmente aquellos que no han respondido a tratamientos conservadores como cambios en la dieta o medicación.',
                'Es un tratamiento recomendado para hemorroides que causan sangrado persistente, prolapso o que no pueden ser tratadas eficazmente con otras opciones, como la escleroterapia o la coagulación.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/indicaciones-ligadura-hemorroides.jpg',
            },
            {
              titleSection: 'Ventajas de la Ligadura de Hemorroides',
              content: [
                'Entre las principales ventajas de la ligadura de hemorroides se encuentran:',
                '- **Procedimiento rápido y ambulatorio**: No requiere hospitalización y puede realizarse en consultorio.',
                '- **Mínimo dolor postoperatorio**: Los pacientes experimentan poco o ningún dolor después del procedimiento.',
                '- **Baja tasa de complicaciones**: Es un procedimiento seguro con pocas complicaciones.',
                '- **Recuperación rápida**: La mayoría de los pacientes pueden retomar sus actividades diarias en pocos días.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/ventajas-ligadura-hemorroides.jpg',
            },
            {
              titleSection: 'Posibles Complicaciones de la Ligadura de Hemorroides',
              content: [
                'Aunque la ligadura de hemorroides es generalmente segura, pueden presentarse algunas complicaciones, tales como:',
                '- **Dolor o molestias postoperatorias**: En casos raros, los pacientes pueden experimentar incomodidad.',
                '- **Sangrado leve**: Puede ocurrir un ligero sangrado durante las primeras 24 horas.',
                '- **Infección en el área tratada**: Aunque rara, la infección es una posible complicación.',
                'Es importante destacar que las complicaciones graves son poco frecuentes y la mayoría de los pacientes experimentan una recuperación sin problemas.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/complicaciones-ligadura-hemorroides.jpg',
            },
            {
              titleSection: 'Recuperación y Seguimiento',
              content: [
                'La recuperación después de la ligadura de hemorroides es rápida, y los pacientes suelen experimentar una mejora significativa en sus síntomas en pocos días. Es importante seguir las recomendaciones del médico, como evitar el esfuerzo durante las evacuaciones y mantener una dieta rica en fibra para facilitar el paso de las heces.',
                'En algunos casos, los pacientes pueden necesitar un seguimiento para asegurarse de que las hemorroides se hayan resuelto completamente. La ligadura de hemorroides tiene una alta tasa de éxito y se puede repetir si es necesario en caso de recurrencia.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/recuperacion-ligadura-hemorroides.jpg',
            },
          ],
        },
        CirugiaTDHYLaserHemorroides: {
          title: 'Cirugía TDH y Láser de Hemorroides: Tratamientos Avanzados para la Enfermedad Hemorroidal',
          description: 'La cirugía TDH y el láser de hemorroides son opciones avanzadas y mínimamente invasivas para tratar la enfermedad hemorroidal, ofreciendo menos dolor y una recuperación más rápida. Conoce sus beneficios y cómo se realizan.',
          keywords: 'cirugía TDH, láser de hemorroides, tratamiento mínimamente invasivo, enfermedad hemorroidal, tratamiento quirúrgico hemorroides, hemorroides grado III y IV, cirugía láser hemorroidal',
          canonical: 'https://unidad-gastroenterologia.com/cirugia-tdh-y-laser-de-hemorroides',
          link: '/cirugia-tdh-y-laser-de-hemorroides',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/images/cirugia-tdh-y-laser-de-hemorroides.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es la Cirugía TDH y el Láser de Hemorroides?',
              content: [
                'La Cirugía TDH (Transanal de Hemorroides) y el uso de láser para hemorroides son procedimientos quirúrgicos mínimamente invasivos utilizados para tratar las hemorroides internas, especialmente en grados más avanzados (III y IV).',
                'Ambos tratamientos se realizan de forma ambulatoria, con anestesia local, y son opciones eficaces para pacientes que no responden a tratamientos conservadores o que sufren de hemorroides graves.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/cirugia-tdh-y-laser-de-hemorroides.jpg',
            },
            {
              titleSection: 'Beneficios de la Cirugía TDH y Láser para Hemorroides',
              content: [
                'Las principales ventajas de la cirugía TDH y el láser de hemorroides incluyen:',
                '- **Mínimo dolor postoperatorio**: Los procedimientos son menos invasivos, lo que reduce el dolor comparado con las técnicas tradicionales.',
                '- **Recuperación rápida**: Los pacientes suelen reanudar sus actividades diarias mucho antes en comparación con otros tipos de cirugía.',
                '- **Menor riesgo de complicaciones**: Al ser mínimamente invasivos, estos tratamientos tienen una tasa de complicaciones significativamente baja.',
                '- **Procedimiento ambulatorio**: No requiere hospitalización y se realiza generalmente en un solo día.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/beneficios-cirugia-tdh-laser.jpg',
            },
            {
              titleSection: '¿Cómo Funciona la Cirugía TDH y el Láser de Hemorroides?',
              content: [
                'En la **Cirugía TDH**, se utiliza un dispositivo especializado para suturar y cortar el tejido hemorroidal prolapsado, lo que lo fija de manera más eficiente y sin grandes incisiones, promoviendo una rápida recuperación.',
                'En el tratamiento con **láser de hemorroides**, se utiliza un láser para vaporizar las hemorroides o coagular los vasos sanguíneos que las alimentan, lo que también reduce el sangrado y acelera el proceso de curación.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/como-funciona-cirugia-tdh-laser.jpg',
            },
            {
              titleSection: 'Indicaciones para la Cirugía TDH y el Láser de Hemorroides',
              content: [
                'Estos tratamientos son recomendados para pacientes con hemorroides internas de grado III y IV que no han respondido a tratamientos conservadores como la dieta rica en fibra, el uso de cremas tópicas, o la ligadura de hemorroides.',
                'También son adecuados para pacientes que sufren de hemorroides crónicas o recurrentes, especialmente cuando hay complicaciones como sangrado, prolapso o trombosis hemorroidal.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/indicaciones-cirugia-tdh-laser.jpg',
            },
            {
              titleSection: 'Recuperación y Cuidados Postoperatorios',
              content: [
                'Después de la cirugía TDH o el tratamiento con láser, la mayoría de los pacientes pueden regresar a sus actividades diarias en un par de días, con recomendaciones específicas de cuidado postoperatorio.',
                'Es fundamental seguir una dieta rica en fibra, mantenerse hidratado y evitar el esfuerzo durante las evacuaciones. En algunos casos, se pueden prescribir analgésicos o antiinflamatorios para controlar cualquier molestia.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/recuperacion-cirugia-tdh-laser.jpg',
            },
            {
              titleSection: 'Posibles Complicaciones y Riesgos',
              content: [
                'Aunque la cirugía TDH y el láser de hemorroides son procedimientos generalmente seguros, existen algunas posibles complicaciones, como:',
                '- **Infección en la zona tratada** (raro).',
                '- **Sangrado leve** durante los primeros días.',
                '- **Dolor o incomodidad temporal** en la zona anal.',
                'Sin embargo, la tasa de complicaciones graves es baja, y la mayoría de los pacientes se recuperan sin problemas importantes.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/complicaciones-cirugia-tdh-laser.jpg',
            },
          ],
        },
        CirugiaLaparoscopiaColon: {
          title: 'Cirugía Laparoscópica de Colon: Beneficios, Procedimiento y Recuperación',
          description: 'La cirugía laparoscópica de colon es una técnica mínimamente invasiva para tratar diversas afecciones del colon, como el cáncer y la enfermedad diverticular. Descubre los beneficios, el procedimiento, y el tiempo de recuperación.',
          keywords: 'cirugía laparoscópica de colon, cirugía mínimamente invasiva, tratamiento cáncer de colon, enfermedad diverticular, laparoscopia colon, cirugía de colon sin incisiones grandes, beneficios cirugía laparoscópica',
          canonical: 'https://unidad-gastroenterologia.com/cirugia-laparoscopia-colon',
          link: '/cirugia-laparoscopia-colon',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/images/cirugia-laparoscopica-de-colon.jpg',
          sections: [
            {
              titleSection: '¿Qué es la Cirugía Laparoscópica de Colon?',
              content: [
                'La cirugía laparoscópica de colon es un procedimiento quirúrgico mínimamente invasivo utilizado para tratar diversas afecciones del colon, como el cáncer, la enfermedad diverticular y la colitis ulcerosa.',
                'A diferencia de la cirugía abierta tradicional, en la cirugía laparoscópica se realizan pequeñas incisiones, a través de las cuales se insertan una cámara y los instrumentos quirúrgicos, permitiendo que el cirujano opere de forma precisa sin necesidad de realizar grandes cortes.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/cirugia-laparoscopica-de-colon.jpg',
            },
            {
              titleSection: 'Beneficios de la Cirugía Laparoscópica de Colon',
              content: [
                'La cirugía laparoscópica ofrece varios beneficios importantes en comparación con los métodos tradicionales, entre ellos:',
                '- **Menos dolor postoperatorio**: Al ser un procedimiento mínimamente invasivo, los pacientes experimentan menos dolor.',
                '- **Recuperación más rápida**: La hospitalización es más corta, y la recuperación es más rápida en comparación con la cirugía abierta.',
                '- **Menos riesgo de infección**: Al realizar incisiones más pequeñas, el riesgo de infecciones postquirúrgicas es significativamente menor.',
                '- **Menores cicatrices**: Las pequeñas incisiones dejan cicatrices mucho menos visibles y menos extensas.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/beneficios-laparoscopia-colon.jpg',
            },
            {
              titleSection: 'Procedimiento de la Cirugía Laparoscópica de Colon',
              content: [
                'Durante la cirugía laparoscópica de colon, el cirujano realiza varias pequeñas incisiones en el abdomen. A través de estas incisiones se inserta un tubo delgado con una cámara (laparoscopio) que permite al cirujano ver el área tratada en un monitor.',
                'Se utilizan otros instrumentos especializados para remover el tejido enfermo o realizar las reparaciones necesarias en el colon. En algunos casos, se puede realizar una resección parcial del colon afectado.',
                'Este tipo de cirugía generalmente se realiza bajo anestesia general, y el paciente está dormido durante el procedimiento.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/procedimiento-laparoscopia-colon.jpg',
            },
            {
              titleSection: 'Indicaciones para la Cirugía Laparoscópica de Colon',
              content: [
                'La cirugía laparoscópica de colon es indicada en diversas condiciones del colon, incluyendo:',
                '- **Cáncer de colon**: Para extirpar el tejido canceroso en etapas tempranas.',
                '- **Enfermedad diverticular**: Para tratar los divertículos inflamados o infeccionados.',
                '- **Colitis ulcerosa y enfermedad inflamatoria intestinal**: En casos severos que no responden a tratamientos médicos.',
                '- **Obstrucción intestinal**: Cuando el colon se bloquea debido a cicatrices, torsión o inflamación.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/indicaciones-laparoscopia-colon.jpg',
            },
            {
              titleSection: 'Recuperación Después de la Cirugía Laparoscópica de Colon',
              content: [
                'La recuperación de la cirugía laparoscópica de colon es generalmente más rápida que la de la cirugía abierta. Los pacientes pueden ser dados de alta del hospital en 1 o 2 días, dependiendo de su condición.',
                'Es común que los pacientes sientan algo de dolor y molestias en las primeras semanas, pero este suele ser manejado eficazmente con analgésicos.',
                'El tiempo para retomar las actividades normales varía según la cirugía y la condición del paciente, pero por lo general, se puede regresar a las actividades cotidianas en unas 2 a 4 semanas.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/recuperacion-laparoscopia-colon.jpg',
            },
            {
              titleSection: 'Posibles Complicaciones de la Cirugía Laparoscópica de Colon',
              content: [
                'Aunque la cirugía laparoscópica de colon tiene una tasa de éxito alta y es generalmente segura, algunas complicaciones pueden ocurrir, tales como:',
                '- **Infección en las incisiones**: Aunque es rara, puede haber una infección en los puntos de entrada.',
                '- **Sangrado**: Pequeños sangrados pueden ocurrir durante o después del procedimiento.',
                '- **Lesiones en órganos cercanos**: Aunque las lesiones son raras, pueden ocurrir daños en los órganos cercanos al colon.',
                'Sin embargo, las complicaciones graves son inusuales y la mayoría de los pacientes se recuperan sin problemas importantes.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/complicaciones-laparoscopia-colon.jpg',
            },
          ],
        },
        VAAFT: {
          title: "VAAFT: Cirugía para Fístula Anal",
          description: "La cirugía VAAFT (Transanal Endoscopic Microsurgery) es un tratamiento avanzado y mínimamente invasivo para la fístula anal, proporcionando una alta tasa de éxito y una recuperación rápida.",
          keywords: "cirugía VAAFT, tratamiento fístula anal, fístula perianal, cirugía mínimamente invasiva, procedimiento de fístula, fístula anal tratamiento",
          canonical: "https://unidad-coloproctologia.com/vaaft-cirugia-fistula-anal",
          link: "/vaaft-cirugia-fistula-anal",
          className: "coloproctologia-page",
          image: "https://unidad-gastroenterologia.com/images/cirugia-vaaft-fistula-anal.jpg",
          sections: [
            {
              titleSection: "¿Qué es la Cirugía VAAFT?",
              content: [
                "La cirugía VAAFT (Transanal Endoscopic Microsurgery) es una técnica mínimamente invasiva que se utiliza para tratar las fístulas anales complejas, proporcionando una solución eficaz con menos dolor y una recuperación más rápida.",
                "Se realiza a través del ano utilizando un endoscopio y microscopio, permitiendo la visualización directa de la fístula y su tratamiento sin necesidad de realizar grandes incisiones."
              ],
              image: "https://unidad-gastroenterologia.com/images/cirugia-vaaft-fistula-anal.jpg"
            },
            {
              titleSection: "Indicaciones para la Cirugía VAAFT",
              content: [
                "La cirugía VAAFT está indicada para pacientes con fístulas anales complejas que no han respondido a tratamientos convencionales o que presentan complicaciones como infecciones recurrentes.",
                "Es especialmente efectiva en fístulas que atraviesan el esfínter anal o aquellas que están cerca de la zona rectal."
              ],
              image: "https://unidad-coloproctologia.com/images/vaaft-indicaciones.jpg"
            },
            {
              titleSection: "Beneficios de la Cirugía VAAFT",
              content: [
                "- **Minimización del dolor postoperatorio**: Al ser un procedimiento mínimamente invasivo, el dolor después de la cirugía es considerablemente menor que en las técnicas tradicionales.",
                "- **Recuperación rápida**: Los pacientes pueden retomar sus actividades diarias rápidamente, generalmente en pocos días.",
                "- **Alta tasa de éxito**: La cirugía VAAFT tiene una alta tasa de curación, especialmente en fístulas complejas."
              ],
              image: "https://unidad-coloproctologia.com/images/beneficios-vaaft.jpg"
            },
            {
              titleSection: "¿Cómo Funciona la Cirugía VAAFT?",
              content: [
                "Durante el procedimiento VAAFT, el cirujano utiliza un endoscopio para localizar y examinar la fístula. A través de un microscopio, el cirujano puede realizar una resección precisa del tracto de la fístula y reparar la zona afectada sin necesidad de realizar grandes incisiones.",
                "En muchos casos, se puede colocar un sistema de drenaje temporal para facilitar la curación."
              ],
              image: "https://unidad-coloproctologia.com/images/como-funciona-vaaft.jpg"
            },
            {
              titleSection: "Recuperación y Cuidados Postoperatorios",
              content: [
                "La recuperación después de la cirugía VAAFT es generalmente rápida, con una mínima molestia postoperatoria.",
                "Se recomienda evitar el esfuerzo físico intenso durante las primeras semanas y seguir una dieta rica en fibra para facilitar las evacuaciones.",
                "Los pacientes deben asistir a consultas de seguimiento para asegurarse de que la fístula se haya cerrado completamente."
              ],
              image: "https://unidad-coloproctologia.com/images/recuperacion-vaaft.jpg"
            },
            {
              titleSection: "Posibles Complicaciones y Riesgos",
              content: [
                "Aunque la cirugía VAAFT es segura, como cualquier procedimiento quirúrgico, existen algunos riesgos, tales como:",
                "- **Infección en el área tratada**: Aunque es poco frecuente, la infección es un riesgo potencial.",
                "- **Recurrencia de la fístula**: En algunos casos raros, la fístula puede no cerrarse completamente.",
                "- **Sangrado**: Puede presentarse un sangrado leve después de la cirugía."
              ],
              image: "https://unidad-coloproctologia.com/images/complicaciones-vaaft.jpg"
            }
          ]
        }

    },
    Endoscopia: {
        StrettaGastroplastia: {
            title: 'Stretta o Gastroplastia Esofago Gástrica Con Radiofrecuencia',
            description: 'El tratamiento Stretta es una opción mínimamente invasiva para pacientes con reflujo gastroesofágico crónico, utilizando radiofrecuencia para mejorar el tono del esfínter esofágico inferior.',
            keywords: 'Stretta, gastroplastia, radiofrecuencia, reflujo gastroesofágico, tratamiento sin cirugía, RGE, esófago gástrico',
            canonical: 'https://unidad-gastroenterologia.com/stretta-gastroplastia-radiofrecuencia',
            link: '/stretta-gastroplastia-radiofrecuencia',
            className: 'endoscopia-page',
            image: 'https://unidad-gastroenterologia.com/images/stretta-gastroplastia.jpg',
            sections: [
              {
                titleSection: '¿Qué es el Tratamiento Stretta?',
                content: [
                  'El tratamiento Stretta es un procedimiento no quirúrgico que utiliza energía de radiofrecuencia para mejorar el funcionamiento del esfínter esofágico inferior, aliviando los síntomas del reflujo gastroesofágico (RGE).',
                  'La radiofrecuencia aplicada estimula la cicatrización de las fibras musculares, lo que ayuda a fortalecer el esfínter y reducir el flujo retrógrado del ácido estomacal.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/stretta-procedimiento.jpg',
              },
              {
                titleSection: 'Indicaciones para Stretta',
                content: [
                  'El tratamiento Stretta está indicado para pacientes con reflujo gastroesofágico crónico que no responden a los tratamientos médicos convencionales o que prefieren evitar la cirugía.',
                  'Es ideal para personas con síntomas de RGE persistentes, tales como acidez estomacal, regurgitación, y dolor torácico, y que desean una solución menos invasiva.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/indicaciones-stretta.jpg',
              },
              {
                titleSection: '¿Cómo Funciona el Procedimiento?',
                content: [
                  'El procedimiento Stretta se realiza de manera ambulatoria, utilizando un endoscopio para acceder al área del esfínter esofágico inferior.',
                  'Mediante la aplicación de energía de radiofrecuencia, se estimula el tejido del esfínter para mejorar su función y evitar el reflujo del contenido gástrico al esófago.',
                  'El tratamiento dura entre 30 a 45 minutos y se realiza con sedación ligera, permitiendo que el paciente regrese a su hogar el mismo día.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/procedimiento-stretta.jpg',
              },
              {
                titleSection: 'Beneficios del Tratamiento Stretta',
                content: [
                  '- Alivio efectivo de los síntomas de reflujo gastroesofágico.',
                  '- Evita la necesidad de cirugía invasiva.',
                  '- Mejora la calidad de vida al reducir la dependencia de medicamentos para el reflujo.',
                  '- Procedimiento mínimamente invasivo, con un tiempo de recuperación rápido.',
                  '- Es una alternativa segura para pacientes con contraindicaciones para cirugía.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/beneficios-stretta.jpg',
              },
              {
                titleSection: 'Riesgos y Consideraciones',
                content: [
                  'Aunque el tratamiento Stretta es seguro, existen algunos riesgos mínimos que los pacientes deben considerar:',
                  '- Dolor o incomodidad en el área del pecho, que es temporal.',
                  '- Riesgo bajo de estrechamiento del esófago (estenosis).',
                  '- Posibles reacciones alérgicas a la sedación o anestesia local.',
                  'Es importante que los pacientes sigan las recomendaciones postoperatorias para evitar complicaciones.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/riesgos-stretta.jpg',
              },
              {
                titleSection: 'Resultados y Seguimiento',
                content: [
                  'La mayoría de los pacientes experimentan una mejora significativa en los síntomas de RGE dentro de las primeras semanas después del tratamiento.',
                  'Es posible que se necesiten sesiones de seguimiento para evaluar la efectividad del tratamiento y realizar ajustes si es necesario.',
                  'Los pacientes deben seguir un estilo de vida saludable y evitar los factores que desencadenan el reflujo para maximizar los beneficios del procedimiento.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/resultados-stretta.jpg',
              },
            ],
          },
        DilatacionesEndoscopicas: {
            title: 'Dilataciones Endoscópicas',
            description: 'Abordaje terapéutico para patologías con estenosis del tubo digestivo, mejorando los síntomas y evitando cirugía en muchos casos.',
            keywords: 'dilataciones endoscópicas, estenosis esofágica, dilatación pilórica, colon, complicaciones',
            canonical: 'https://unidad-gastroenterologia.com/dilatacion-endoscopica',
            link: '/dilatacion-endoscopica',
            className: 'endoscopia-page',
            image: 'https://unidad-gastroenterologia.com/static/images/gastro_5.jpg',
            sections: [
              {
                titleSection: 'Dilataciones Endoscópicas',
                content: [
                  'La dilatación endoscópica permite desarrollar un abordaje terapéutico de una serie de patologías que conllevan una estenosis del tubo digestivo, en las que la alternativa es el tratamiento quirúrgico. Su objetivo es conseguir un aumento suficiente del calibre de la luz del órgano para mejorar los síntomas.',
                  'La dilatación de la estenosis es un método de larga tradición histórica. Inició con Fabricius de Acquapendente y sus dilataciones con objetos de cera denominados bujías. Actualmente, existen diversos tipos de dilatadores, como bujías de caucho con peso de mercurio, olivas de metal, dilatadores de polivinilo, y balones para dilatación hidrostática y neumática.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/dilataciones-endoscopicas.jpg',
              },
              {
                titleSection: 'Indicaciones de las Dilataciones Endoscópicas',
                content: [
                  'Los sitios más frecuentes de dilatación son el esófago, el píloro y la unión recto-cólica.',
                ],
              },
              {
                titleSection: 'Dilataciones Esofágicas',
                content: [
                  'La dilatación está indicada en todos aquellos procesos que cursan con una estenosis sintomática del esófago, como estenosis pépticas o por ERGE, acalasia y estenosis por cáusticos.',
                  'Otros procesos que pueden requerir dilatación incluyen anastomosis post quirúrgicas, estenosis secundarias a escleroterapia, radioterapia, y anillos o membranas esofágicas.',
                  'En estenosis neoplásicas, la dilatación puede ser útil cuando el calibre de la luz no permite el paso del endoscopio para la toma de biopsias. Sin embargo, el riesgo de perforación desaconseja su uso en estos casos.',
                ],
              },
              {
                titleSection: 'Dilatación Pilórica',
                content: [
                  'Indicada en pacientes con dificultad para el vaciamiento gástrico, lo cual ocurre en casos con estenosis pilórica péptica, retracción de anastomosis quirúrgicas, secuelas de cáusticos y lesiones píloroduodenales por enfermedad de Crohn.',
                ],
              },
              {
                titleSection: 'Dilatación Recto-Cólica',
                content: [
                  'El tratamiento dilatador por vía endoscópica está indicado en pacientes con estenosis benigna de recto o colon que presentan suboclusión u oclusión intestinal.',
                  'Las principales indicaciones son estenosis de anastomosis quirúrgica y estenosis secundarias a enfermedad de Crohn.',
                  'En estenosis malignas recto-cólicas, cuando el tratamiento quirúrgico no es viable, las dilataciones son poco efectivas, y se prefiere la inserción de endoprótesis para repermeabilizar la luz.',
                ],
              },
              {
                titleSection: 'Contraindicaciones',
                content: [
                  'No existen contraindicaciones absolutas para las dilataciones endoscópicas, excepto la presencia de una perforación.',
                ],
              },
              {
                titleSection: 'Complicaciones',
                content: [
                  'La dilatación es un procedimiento seguro con buenos resultados, evitando cirugía en muchos casos, pero no está exenta de complicaciones.',
                  'La perforación es la complicación más grave. Otras menos frecuentes incluyen bacteriemias y hemorragias, aunque una hemorragia leve es considerada normal.',
                ],
              },
            ],
          },
        LigaduraDeVaricesEsofagicas: {
            title: 'Ligadura de Varices Esofágicas',
            description: 'Tratamiento endoscópico para prevenir y manejar hemorragias causadas por varices esofágicas, una complicación grave de la cirrosis hepática.',
            keywords: 'varices esofágicas, ligadura endoscópica, hemorragia digestiva, tratamiento cirrosis, escleroterapia',
            canonical: 'https://unidad-gastroenterologia.com/ligadura-de-varices-esofagicas',
            link: '/ligadura-de-varices-esofagicas',
            className: 'endoscopia-page',
            image: 'https://unidad-gastroenterologia.com/images/ligadura-varices-esofagicas.jpg',
            sections: [
              {
                titleSection: '¿Qué son las Varices Esofágicas?',
                content: [
                  'Las varices esofágicas son venas dilatadas en la parte final del esófago, causadas por la dificultad que genera la cirrosis hepática en el paso de la sangre a través del hígado.',
                  'La complicación más grave es la ruptura de las varices, que ocurre cuando la presión interna supera la resistencia de la pared venosa. Esto puede provocar hemorragias intensas y abundantes, con un alto riesgo de muerte.',
                  'No todos los pacientes con varices esofágicas sangran. El riesgo depende del tamaño de las varices; a mayor tamaño, mayor probabilidad de sangrado. Cerca de la mitad de los pacientes con cirrosis tienen varices al momento del diagnóstico.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/varices-esofagicas.jpg',
              },
              {
                titleSection: 'Síntomas de las Varices Esofágicas',
                content: [
                  'En general, las varices esofágicas no producen síntomas y su presencia solo puede detectarse mediante una endoscopia.',
                  'El sangrado por varices es muy evidente y se manifiesta con evacuaciones negras (melenas), vómitos de sangre fresca o digerida con aspecto de posos de café.',
                  'Otros síntomas generales incluyen mareo, sudoración y pérdida de consciencia. Ante cualquier hemorragia, es fundamental acudir inmediatamente al hospital.',
                ],
              },
              {
                titleSection: 'Tratamiento de las Varices Esofágicas',
                content: [
                  'El tratamiento varía según la situación clínica del paciente. Se distinguen tres escenarios:',
                ],
              },
              {
                titleSection: '1. Pacientes sin hemorragias previas',
                content: [
                  'Para varices grandes detectadas por endoscopia, el tratamiento incluye medicamentos betabloqueantes (propranolol, nadolol) para reducir la presión venosa.',
                  'También se emplea la ligadura endoscópica con bandas elásticas, que consiste en aplicar ligas de goma para estrangular las varices. Este procedimiento puede requerir varias sesiones para reducir el tamaño de las varices o eliminarlas.',
                ],
              },
              {
                titleSection: '2. Hemorragia por Varices',
                content: [
                  'El sangrado por varices es una emergencia médica que debe ser tratada en centros especializados.',
                  'El manejo incluye medicamentos para reducir la presión de las varices y procedimientos endoscópicos como la ligadura endoscópica o la escleroterapia.',
                  'La escleroterapia endoscópica implica inyectar una sustancia esclerosante dentro de las varices para detener el sangrado.',
                ],
              },
              {
                titleSection: '3. Prevención de Nuevas Hemorragias',
                content: [
                  'Los pacientes que ya han sangrado tienen un alto riesgo (70% anual) de recurrencia. Por ello, es esencial establecer un tratamiento preventivo.',
                  'La combinación de fármacos puede reducir significativamente el riesgo de nuevas hemorragias y complicaciones asociadas a la cirrosis.',
                  'La ligadura endoscópica es una alternativa en caso de no poder usar medicamentos o como complemento del tratamiento farmacológico.',
                ],
              },
            ],
          },
        TratamientoEndoscopicoConArgonPlasma: {
            title: 'Tratamiento Endoscópico con Argón Plasma',
            description: 'Conoce el uso del argón plasma en endoscopía, su aplicación, beneficios, indicaciones y posibles riesgos.',
            keywords: 'argón plasma, tratamiento endoscópico, gastroenterología, coagulación endoscópica, control de hemorragias',
            canonical: 'https://unidad-gastroenterologia.com/tratamiento-endoscopico-argon-plasma',
            link: '/tratamiento-endoscopico-argon-plasma',
            className: 'endoscopia-page',
            image: 'https://unidad-gastroenterologia.com/images/argon-plasma.jpg',
            sections: [
              {
                titleSection: '¿Qué es el Tratamiento con Argón Plasma?',
                content: [
                  'El tratamiento con argón plasma es un procedimiento endoscópico avanzado que utiliza gas argón y una corriente eléctrica de alta frecuencia para coagular tejidos de manera controlada.',
                  'Este método es mínimamente invasivo y se emplea principalmente para detener hemorragias digestivas, tratar lesiones vasculares o eliminar tejido anómalo en el tracto gastrointestinal.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/que-es-argon-plasma.jpg',
              },
              {
                titleSection: '¿Cómo Funciona?',
                content: [
                  'Durante el procedimiento, el gas argón es dirigido hacia el área afectada mediante un catéter especial introducido a través del endoscopio.',
                  'Al activarse, la corriente eléctrica ioniza el gas, generando un plasma que coagula los tejidos sin necesidad de contacto directo.',
                  'Esta técnica permite un control preciso y reduce el riesgo de perforaciones o daños a tejidos cercanos.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/funcionamiento-argon-plasma.jpg',
              },
              {
                titleSection: 'Indicaciones Principales',
                content: [
                  'El argón plasma es utilizado en una variedad de condiciones, tales como:',
                  '- Control de hemorragias digestivas no varicosas (úlceras, ectasias vasculares, telangiectasias).',
                  '- Tratamiento de angiomas o lesiones vasculares en el tracto gastrointestinal.',
                  '- Eliminación de tejido residual tras resecciones de pólipos.',
                  '- Control de sangrado en pacientes con tumores gastrointestinales no resecables.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/indicaciones-argon-plasma.jpg',
              },
              {
                titleSection: 'Beneficios del Tratamiento',
                content: [
                  '- Procedimiento rápido y efectivo para detener hemorragias.',
                  '- Mínimamente invasivo, reduciendo el tiempo de recuperación del paciente.',
                  '- Baja incidencia de complicaciones graves.',
                  '- Permite tratar áreas de difícil acceso en el tracto gastrointestinal.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/beneficios-argon-plasma.jpg',
              },
              {
                titleSection: 'Posibles Riesgos y Complicaciones',
                content: [
                  'Aunque es un procedimiento seguro, pueden ocurrir complicaciones como:',
                  '- Dolor abdominal leve.',
                  '- Formación de gases que pueden causar distensión temporal.',
                  '- Riesgo bajo de perforación o sangrado.',
                  'Es fundamental que el procedimiento sea realizado por personal médico especializado para minimizar riesgos.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/riesgos-argon-plasma.jpg',
              },
            ],
          },
        AblacionEsfagoBarrett: {
            title: 'Ablación de Esófago de Barrett con Radiofrecuencia',
            description: 'Conoce cómo la ablación con radiofrecuencia trata el esófago de Barrett, reduciendo el riesgo de progresión a cáncer de esófago de manera segura y eficaz.',
            keywords: 'esófago de Barrett, ablación con radiofrecuencia, tratamiento esófago, gastroenterología, prevención cáncer de esófago',
            canonical: 'https://unidad-gastroenterologia.com/ablacion-esofago-barrett',
            link: '/ablacion-esofago-barrett',
            className: 'endoscopia-page',
            image: 'https://unidad-gastroenterologia.com/images/ablacion-esofago-barrett.jpg',
            sections: [
              {
                titleSection: '¿Qué es el Esófago de Barrett?',
                content: [
                  'El esófago de Barrett es una condición en la que el revestimiento del esófago sufre cambios anormales debido a la exposición crónica al ácido gástrico, como ocurre en el reflujo gastroesofágico (ERGE).',
                  'Estos cambios pueden aumentar el riesgo de desarrollar cáncer de esófago, especialmente si hay displasia o cambios celulares precancerosos.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/esofago-barrett.jpg',
              },
              {
                titleSection: '¿En Qué Consiste la Ablación con Radiofrecuencia?',
                content: [
                  'La ablación con radiofrecuencia es un tratamiento mínimamente invasivo que utiliza energía térmica controlada para eliminar el tejido anormal en el esófago.',
                  'Un dispositivo especial, que se introduce mediante un endoscopio, emite ondas de radiofrecuencia para destruir de forma precisa el tejido afectado, permitiendo la regeneración de tejido sano.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/ablacion-radiofrecuencia.jpg',
              },
              {
                titleSection: 'Indicaciones del Procedimiento',
                content: [
                  'El procedimiento está indicado para pacientes con esófago de Barrett con displasia de bajo o alto grado.',
                  'Es una opción para reducir el riesgo de progresión a cáncer de esófago en personas con antecedentes de ERGE crónico o esófago de Barrett diagnosticado mediante endoscopia.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/indicaciones-ablacion.jpg',
              },
              {
                titleSection: 'Beneficios de la Ablación con Radiofrecuencia',
                content: [
                  '- Procedimiento seguro y eficaz para reducir el riesgo de cáncer.',
                  '- Minimiza la necesidad de cirugías invasivas.',
                  '- Realizado de manera ambulatoria con un tiempo de recuperación rápido.',
                  '- Mejora significativa en la calidad de vida del paciente.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/beneficios-ablacion.jpg',
              },
              {
                titleSection: '¿Cómo se Realiza el Procedimiento?',
                content: [
                  '1. El paciente recibe sedación o anestesia local para mayor comodidad.',
                  '2. Se introduce un endoscopio equipado con un dispositivo de radiofrecuencia en el esófago.',
                  '3. La energía térmica se aplica de manera controlada en las áreas afectadas por el Barrett.',
                  '4. El procedimiento dura aproximadamente 30-45 minutos y el paciente puede regresar a casa el mismo día.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/procedimiento-ablacion.jpg',
              },
              {
                titleSection: 'Riesgos y Consideraciones',
                content: [
                  'Aunque es un procedimiento seguro, existen riesgos mínimos como:',
                  '- Dolor o molestias temporales en el pecho.',
                  '- Estenosis esofágica (estrechamiento del esófago), tratable con dilatación endoscópica.',
                  '- Riesgo bajo de sangrado o infección.',
                  'Es fundamental realizar un seguimiento endoscópico regular para garantizar la efectividad del tratamiento y detectar cualquier posible complicación.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/riesgos-ablacion.jpg',
              },
            ],
          },
    },
    EnfermedadesHigadoYViasBiliares: {
        ColelitiasisYColecistitis: {
          title: 'Colelitiasis y Colecistitis',
          description: 'Información sobre la colelitiasis y colecistitis, sus tipos, síntomas, diagnóstico y tratamiento.',
          keywords: 'colelitiasis, colecistitis, cálculos biliares, cólico biliar, colecistectomía, enfermedades biliares',
          canonical: 'https://unidad-gastroenterologia.com/colecistitis',
          link: '/colecistitis',
          className: 'enfermedades-higado',
          image: 'https://unidad-gastroenterologia.com/images/colelitiasis-colecistitis.jpg',
          sections: [
            {
              titleSection: 'Clasificación de la Colelitiasis y Colecistitis',
              content: [
                'La colelitiasis y la colecistitis se dividen en tres tipos según su presentación clínica:',
                '- **Cálculos biliares asintomáticos:** Detectados de manera casual, sin síntomas aparentes.',
                '- **Enfermedad litiásica sintomática:** Caracterizada por dolor y molestias abdominales.',
                '- **Síntomas abdominales secundarios:** Derivados de enfermedades asociadas, como úlcera péptica.',
              ],
              // image: 'https://unidad-gastroenterologia.com/images/clasificacion-colelitiasis.jpg',
            },
            {
              titleSection: 'Colelitiasis Asintomática',
              content: [
                'La forma asintomática de colelitiasis es la más común entre las enfermedades de la vesícula biliar.',
                'Aunque la mayoría de los pacientes no presentan síntomas incluso después de 20 años, un 20% desarrollará síntomas en un plazo de 15 años.',
                'Se recomienda la colecistectomía preventiva en pacientes asintomáticos, especialmente en personas mayores, ya que ha demostrado reducir complicaciones graves como la colecistitis aguda.',
              ],
              image: 'https://unidad-gastroenterologia.com/images/enfermedades/colelitiasis.gif',
            },
            {
              titleSection: 'Colecistitis Crónica Litiásica',
              content: [
                'Los pacientes con cálculos biliares suelen experimentar dolor en la parte superior del abdomen, que puede irradiarse hacia la espalda. Este dolor, conocido como cólico biliar, puede variar de leve a severo e incapacitante.',
                'Otros síntomas incluyen distensión abdominal, flatulencia y reflujo biliar. Si se acompaña de fiebre y rigidez abdominal, puede tratarse de una colecistitis aguda.',
              ],
              // image: 'https://unidad-gastroenterologia.com/images/colecistitis-cronica.jpg',
            },
            {
              titleSection: 'Diagnóstico',
              content: [
                'El ultrasonido abdominal es el método de elección para diagnosticar cálculos biliares.',
                'Las radiografías simples son menos comunes debido a que solo el 15-25% de los cálculos son visibles.',
                'Aunque no se usan para el diagnóstico primario, la tomografía computarizada y la resonancia magnética son útiles para identificar complicaciones como fístulas entre la vesícula e intestino o íleo biliar.',
              ],
              image: 'https://unidad-gastroenterologia.com/images/enfermedades/colelitiasis-1.gif',
            },
            {
              titleSection: 'Tratamiento',
              content: [
                'El tratamiento definitivo es la colecistectomía, que implica la extracción de la vesícula biliar junto con los cálculos.',
                'No se recomienda el uso de medicamentos para disolver cálculos ni litotripsia, ya que los cálculos tienden a formarse nuevamente.',
              ],
            },
          ],
        },
        ColecistitisAguda: {
          title: 'Colecistitis Aguda: Síntomas, Diagnóstico y Tratamiento',
          description: 'La colecistitis aguda es una inflamación de la vesícula biliar causada por la obstrucción de su salida. Aprende sobre sus síntomas, diagnóstico y tratamiento.',
          keywords: 'colecistitis aguda, vesícula biliar, inflamación vesícula, síntomas colecistitis, tratamiento colecistectomía, diagnóstico ultrasonido',
          canonical: 'https://unidad-gastroenterologia.com/colecistitis-aguda',
          link: '/colecistitis-aguda',
          className: 'enfermedades-higado-vias-biliares-page',
          image: 'https://unidad-gastroenterologia.com/images/colecistitis-aguda.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es la Colecistitis Aguda?',
              content: [
                'La colecistitis aguda es una inflamación de la vesícula biliar causada por una obstrucción persistente del tracto de salida debido a un cálculo impactado.',
                'Es una condición dolorosa y potencialmente grave que requiere atención médica inmediata.'
              ],
            },
            {
              titleSection: 'Síntomas de la Colecistitis Aguda',
              content: [
                'Los síntomas típicos incluyen:',
                '- Dolor abdominal fijo e intenso en la parte superior derecha del abdomen, que se irradia hacia la espalda.',
                '- Fiebre, náuseas, anorexia y vómitos.',
                '- Sensibilidad al presionar sobre la región de la vesícula biliar.',
                'El **Signo de Murphy** es característico de la colecistitis aguda: al presionar el abdomen en la región de la vesícula mientras el paciente inspira, el dolor provoca una interrupción en la inspiración.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/sintomas-colecistitis.jpg',
            },
            {
              titleSection: 'Complicaciones de la Colecistitis Aguda',
              content: [
                'La colecistitis aguda puede llevar a complicaciones graves, como:',
                '- Empiema o piocolecisto (infección dentro de la vesícula).',
                '- Gangrena con perforación.',
                '- Absceso intraabdominal.',
                '- Peritonitis difusa.'
              ],
            },
            {
              titleSection: 'Pruebas de Laboratorio',
              content: [
                'Los hallazgos típicos en laboratorio incluyen:',
                '- Aumento en la cantidad de glóbulos blancos en la biometría hemática.',
                '- Elevación leve de bilirrubina y fosfatasa alcalina.',
                '- Valores normales o discretamente elevados de amilasa y lipasa.'
              ],
            },
            {
              titleSection: 'Diagnóstico Diferencial',
              content: [
                'El diagnóstico diferencial de la colecistitis aguda incluye:',
                '- Pancreatitis aguda.',
                '- Apendicitis aguda.',
                '- Hepatitis.',
                '- Enfermedad ulcerosa péptica.',
                '- Pielonefritis del riñón derecho.',
                '- Absceso hepático.',
                '- Neumonía basal derecha.',
                '- Síndrome de Fitz-Hugh-Curtis.'
              ],
            },
            {
              titleSection: 'Diagnóstico por Imagen',
              content: [
                'El diagnóstico se confirma mediante estudios de imagen, que también ayudan a identificar complicaciones:',
                '- **Ultrasonido abdominal**: Identifica cálculos biliares, distensión de la vesícula, edema de la pared, líquido pericolecístico y el Signo de Murphy ecográfico.',
                '- **Tomografía computarizada**: Indicada cuando el diagnóstico es incierto o se sospecha un absceso intraabdominal.',
                '- **Colangiografía por resonancia magnética o gammagrafía hepática**: Utilizadas para evaluar cálculos en la vía biliar.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/diagnostico-colecistitis.jpg',
            },
            {
              titleSection: 'Tratamiento y Manejo de la Colecistitis Aguda',
              content: [
                'El tratamiento inicial incluye medidas generales:',
                '- Reposo intestinal (ayuno).',
                '- Analgésicos y antiespasmódicos.',
                '- Antiinflamatorios y antibióticos intravenosos.',
                'El tratamiento definitivo es la colecistectomía, es decir, la extirpación de la vesícula biliar.',
                '- La colecistectomía laparoscópica es el método preferido debido a su recuperación más rápida y menor riesgo de complicaciones.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/tratamiento-colecistitis.jpg',
            },
          ],
        },
        
      },
    EnfermedadesDeEsofago: {
        HerniaHiatal: {
            title: 'Hernia Hiatal',
            description: 'Se calcula que en Estados Unidos el 10 a 15% de la población desarrolla hernia hiatal con una edad promedio de 48 años.',
            keywords: 'hernia hiatal, esófago, enfermedades digestivas, cirugía, tratamiento, diagnóstico',
            canonical: 'https://unidad-gastroenterologia.com/hernia-hiatal',
            link: '/hernia-hiatal',
            className: 'enfermedadesEsofago-page',
            image: 'https://unidad-gastroenterologia.com/images/hernia-hiatal.jpg',
            sections: [
              {
                titleSection: 'HERNIA HIATAL',
                content: [
                  'Se calcula que en Estados Unidos el 10 a 15% de la población desarrolla hernia hiatal con una edad promedio de 48 años.',
                ],
              },
              {
                titleSection: 'Tipos de hernia hiatal',
                content: [
                  'Existen 4 tipos de hernias de hiato:',
                  'La hernia hiatal tipo I representa el 85% de las hernias y se desarrolla cuando la unión esófago gástrica que normalmente está en el abdomen se desplaza por encima del diafragma quedando en la cavidad torácica.',
                  'La hernia hiatal tipo II o paraesofágica representa el 14% de las hernias y son paraesofágicas puras, es decir, que la unión esófago gástrica queda en su sitio normal, lo que se desplaza es otra parte del estómago generalmente el fondo gástrico hacia el tórax.',
                  'La hernia hiatal tipo III o mixta, representa el 0.8% de las hernias, y es una hernia que suma la tipo I y II, es decir, que tanto el fondo gástrico como la unión esófago gástrica se encuentra en el tórax.',
                  'La hernia hiatal tipo IV o paraesofágica grande, es de mayor tamaño y no solo compromete al estómago en la cavidad torácica, sino además otro órgano como el epiplón, el colon o el bazo.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/hernia-hiatal.gif',
                // file:///C:/Users/pakit/Downloads/public_html/public_html/images/enfermedades/hernia-hiatal.gif
              },
              {
                titleSection: 'Causa de hernia hiatal',
                content: [
                  'La hernia de hiato se forma a medida que las estructuras que fijan al estómago en posición normal en abdomen se van debilitando. A este factor se puede agregar la obesidad que aumenta la presión intraabdominal.',
                ],
              },
              {
                titleSection: 'Síntomas de la hernia hiatal',
                content: [
                  'Los síntomas más frecuentes son la regurgitación, la pirosis o agruras, la disfagia o dificultad para pasar alimentos, el dolor torácico, náuseas y vómitos, vómito de sangre y saciedad temprana.',
                  'Sin embargo, el 11% de los pacientes con hernia son asintomáticos. Hasta en el 30% de los casos se lleva a cabo una cirugía de urgencia para tratar la hemorragia, la estrangulación del estómago, el vólvulo gástrico o la obstrucción total.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/hernia-hiatal-a.gif',
                // file:///C:/Users/pakit/Downloads/public_html/public_html/images/enfermedades/hernia-hiatal-a.gif
              },
              {
                titleSection: 'Diagnóstico',
                content: [
                  'La radiografía de tórax muestra evidencia de estómago en tórax, el estudio puede mejorar con la aplicación de una sonda nasogástrica.',
                  'La Tomografía Computarizada revela con rapidez la hernia.',
                  'El tránsito gastrointestinal alto establece el diagnóstico y revela el tipo de hernia que presenta el paciente.',
                ],
              },
              {
                titleSection: 'Tratamiento y manejo de la hernia hiatal',
                content: [
                  'El paciente con evidencia de hernia hiatal paraesofágica debe ser sometido a cirugía para evitar complicaciones como la incarceración, la estrangulación, la perforación, la hemorragia de vasos esplénicos y la dilatación aguda del estómago herniado.',
                ],
              },
            ],
          },
        Esofagitis: {
            title: 'Esofagitis',
            description: 'La esofagitis es la inflamación del esófago, que puede ser aguda o crónica, y está frecuentemente relacionada con la enfermedad por reflujo gastroesofágico (ERGE).',
            keywords: 'esofagitis, enfermedad por reflujo, ERGE, tratamiento esofagitis, diagnóstico esofagitis, esófago inflamación, esófago de Barret, cirugía anti-reflujo',
            canonical: 'https://unidad-gastroenterologia.com/esofagitis',
            link: '/esofagitis',
            className: 'enfermedadesEsofago-page',
            image: 'https://unidad-gastroenterologia.com/images/esofagitis.jpg',
            sections: [
            {
                titleSection: '¿Qué es la Esofagitis?',
                content: [
                'La esofagitis es la inflamación del esófago, que puede ser aguda o crónica. La forma más común de esofagitis es la provocada por reflujo gastroesofágico (ERGE), que se caracteriza por el paso del ácido estomacal hacia el esófago, irritando su mucosa.',
                'La esofagitis crónica es el resultado de múltiples episodios de inflamación aguda, lo que puede llevar a complicaciones a largo plazo, como el esófago de Barret.',
                ],
                // image: 'https://unidad-gastroenterologia.com/images/que-es-esofagitis.jpg',
            },
            {
                titleSection: 'Síntomas de la Esofagitis',
                content: [
                'Aunque la pirosis (agruras) es el síntoma más común en pacientes con reflujo gastroesofágico, solo un 28% de los pacientes con evidencia endoscópica de esofagitis experimentan este síntoma.',
                'Otros síntomas incluyen dificultad para tragar (disfagia), regurgitación de ácido, dolor al tragar (odinofagia), náuseas, vómitos y eructos.',
                'Sorprendentemente, menos del 30% de los pacientes con esofagitis grave refieren síntomas evidentes.',
                ],
                // image: 'https://unidad-gastroenterologia.com/images/sintomas-esofagitis.jpg',
            },
            {
                titleSection: 'Diagnóstico de la Esofagitis',
                content: [
                'El diagnóstico de la esofagitis se realiza principalmente mediante endoscopia, que permite observar congestión, eritema, edema de la mucosa y hemorragia puntiforme en el esófago.',
                'Otras pruebas diagnósticas, como el esofagograma con bario, también pueden ser útiles, pero la biopsia endoscópica es la forma más precisa de confirmar el diagnóstico.',
                'La esofagitis suele localizarse en la unión gastroesofágica y hasta 10 cm por encima de esta.',
                ],
                // image: 'https://unidad-gastroenterologia.com/images/diagnostico-esofagitis.jpg',
            },
            {
                titleSection: 'Tratamiento y Manejo de la Esofagitis',
                content: [
                'El tratamiento de la esofagitis erosiva busca curar las lesiones, aliviar los síntomas y prevenir recaídas. El tratamiento a corto plazo con Inhibidores de la Bomba de Protones (IBP) como el omeprazol es efectivo para curar la esofagitis erosiva.',
                'Los IBP son más efectivos que los antagonistas H2 (como la ranitidina) y son recomendados como tratamiento a largo plazo para prevenir recaídas.',
                'En casos graves de esofagitis o cuando los tratamientos médicos no son efectivos, la cirugía anti-reflujo laparoscópica puede ser una opción para paliar los síntomas y curar la esofagitis.',
                ],
                // image: 'https://unidad-gastroenterologia.com/images/tratamiento-esofagitis.jpg',
            },
            {
                titleSection: 'Evolución y Pronóstico',
                content: [
                'La cirugía anti-reflujo laparoscópica ha demostrado ser efectiva para controlar los síntomas persistentes de ERGE y mantener la remisión, incluso cuando el aspecto endoscópico de la mucosa del esófago sigue mostrando alteraciones.',
                'Las complicaciones de la esofagitis incluyen ulceraciones superficiales, ulceraciones planas más grandes y la formación de tejido fibroso que puede provocar estenosis esofágica.',
                ],
                // image: 'https://unidad-gastroenterologia.com/images/evolucion-esofagitis.jpg',
            },
            ],
        },
        EstenosisEsofagica: {
            title: 'Estenosis Esofágica',
            description: 'La estenosis esofágica aparece en el 10% a 15% de los pacientes con ERGE y en el 13% de los pacientes con úlceras esofágicas.',
            keywords: 'estenosis esofágica, ERGE, disfagia, diagnóstico, tratamiento, esofagograma, endoscopia',
            canonical: 'https://unidad-gastroenterologia.com/estenosis-esofagica',
            link: '/estenosis-esofagica',
            className: 'enfermedadesEsofago-page',
            image: 'https://unidad-gastroenterologia.com/images/estenosis-esofagica.jpg',
            sections: [
              {
                titleSection: 'Estenosis Esofágica',
                content: [
                  'Aparecen en el 10% a 15% de los pacientes con ERGE y en el 13% de los pacientes con úlceras esofágicas.',
                  'El ERGE representa casi el 70% de las estenosis esofágicas. Entre las causas menos comunes se engloban la ingestión de sustancias cáusticas, el esófago de Barrett, la irradiación del mediastino, la ingestión de fármacos, el cáncer, las anastomosis quirúrgicas, la estenosis esofágica congénita, las enfermedades cutáneas y la pseudodiverticulosis.',
                  'Las secreciones de ácido y pepsina erosionan la mucosa del esófago, lo que genera una sustitución por tejido fibroso que se contrae, reduciendo la luz esofágica a tan solo 2 a 3 mm de diámetro.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/gastro_4.jpg'
                // file:///C:/Users/pakit/Downloads/public_html/public_html/images/gastro_4.jpg 
                ,
              },
              {
                titleSection: 'Cuadro Clínico',
                content: [
                  'Los pacientes refieren síntomas variables de disfagia, odinofagia, regurgitación y dolor torácico.',
                  'La disfagia es primero para los alimentos sólidos y luego para los líquidos. Aparece deglución dolorosa (odinofagia) cuando la mucosa del área estenosada se erosiona.',
                  'La incapacidad del paciente para ingerir cantidades apropiadas de alimento conlleva una disminución del peso y un estado nutricional deficiente.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/estenosis-esofagica.gif',
                //file:///C:/Users/pakit/Downloads/public_html/public_html/images/enfermedades/estenosis-esofagica.gif
              },
              {
                titleSection: 'Diagnóstico',
                content: [
                  'Se confirma el diagnóstico por endoscopia y esofagograma con bario. Este último muestra un segmento variable de esófago estenosado con márgenes gradualmente disminuidos.',
                  'La endoscopia permite una visualización directa y la biopsia confirma la estenosis benigna. En casos graves, puede utilizarse un endoscopio pediátrico.',
                  'En la ERGE, se puede observar reflujo activo de ácido por encima de la lesión. El 45% de los pacientes requiere pH-metría de 24 horas para distinguir entre estenosis secundarias a ERGE y las inducidas por fármacos.',
                  'Es importante diferenciar la estenosis péptica de un anillo de Schatzki o una estenosis en forma de membrana.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/estenosis-esofagica-1.gif',
                //file:///C:/Users/pakit/Downloads/public_html/public_html/images/enfermedades/estenosis-esofagica-1.gif
              },
              {
                titleSection: 'Tratamiento y Manejo',
                content: [
                  'El tratamiento de elección es la dilatación repetida con bujías mediante dilatadores rígidos o balones.',
                  'Debe tratarse crónicamente la causa subyacente del reflujo con inhibidores de bomba de protones (omeprazol, esomeprazol, lanzoprazol, etc.).',
                  'La cirugía está indicada cuando las estenosis recurrentes precisan dilataciones frecuentes o cuando el tratamiento médico fracasa.',
                  'Se recomienda realizar una funduplicatura quirúrgica a los dos años del diagnóstico para tratar la causa subyacente del reflujo.',
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/estenosis-esofagica-2.gif',
              },
              {
                titleSection: 'Evolución y Pronóstico',
                content: [
                  'La dilatación con bujías en estenosis por ERGE produce remisión de síntomas en el 75% de los pacientes, aunque del 30% al 40% presentan recurrencias en un año.',
                  'Las estenosis no pépticas y las más graves tienen tasas más elevadas de recurrencia.',
                  'En estenosis secundarias a ERGE, la pirosis continua y la hernia de hiato son factores que predicen el fracaso del tratamiento con inhibidores de bomba de protones.',
                ],
              },
            ],
          },
          EnfermedadesEsófagoBarrett: {
            title: 'Esófago de Barrett: Diagnóstico, Correlaciones y Tratamientos',
            description: 'El Esófago de Barrett es una condición precancerosa asociada al reflujo gastroesofágico y al adenocarcinoma de esófago. Conoce sus causas, diagnóstico y opciones de tratamiento.',
            keywords: 'esófago de Barrett, metaplasia intestinal, adenocarcinoma de esófago, ERGE, diagnóstico endoscópico, tratamiento esófago de Barrett',
            canonical: 'https://unidad-gastroenterologia.com/esofago-barret',
            link: '/esofago-barret',
            className: 'enfermedades-esofago-page',
            image: 'https://unidad-gastroenterologia.com/images/esofago-de-barrett.jpg',
            sections: [
              {
                titleSection: '¿Qué es el Esófago de Barrett?',
                content: [
                  'El Esófago de Barrett es una condición caracterizada por una metaplasia, o cambio, en la mucosa normal del esófago. Este cambio ocurre en la zona por encima de la unión gastroesofágica.',
                  'Se diagnostica mediante endoscopía y se confirma con biopsias que revelan metaplasia intestinal.'
                ],
                // image: 'https://unidad-gastroenterologia.com/images/diagnostico-esofago-de-barrett.jpg',
              },
              {
                titleSection: 'Correlación entre Esófago de Barrett y Reflujo Gastroesofágico (ERGE)',
                content: [
                  'Existe una fuerte relación entre el Esófago de Barrett y la Enfermedad por Reflujo Gastroesofágico (ERGE).',
                  'Estudios de pH-metría muestran reflujo ácido significativo en pacientes con esta condición.',
                  '- El Esófago de Barrett es más común en hombres que en mujeres.',
                  '- Entre el 10% y 20% de los pacientes que se someten a endoscopías por síntomas de reflujo tienen Esófago de Barrett.'
                ],
              },
              {
                titleSection: 'Correlación entre Esófago de Barrett y Cáncer de Esófago',
                content: [
                  'El Esófago de Barrett tiene un riesgo significativo de progresar a adenocarcinoma de esófago.',
                  '- Los pacientes con Esófago de Barrett tienen un riesgo 30 a 60 veces mayor de desarrollar adenocarcinoma.',
                  '- Este riesgo aumenta con la edad y la gravedad de los síntomas de reflujo.',
                  'La metaplasia intestinal, donde el epitelio esofágico se transforma en un epitelio similar al intestinal, es el mayor factor de riesgo para displasia y cáncer.'
                ],
                // image: 'https://unidad-gastroenterologia.com/images/cancer-esofago-barrett.jpg',
              },
              {
                titleSection: 'Tipo de Reflujo que Contribuye al Esófago de Barrett',
                content: [
                  'El reflujo mixto, que incluye ácido y contenido duodenal (bilis), es un factor clave para el desarrollo del Esófago de Barrett.',
                  '- La bilis, el ácido y la pepsina dañan la barrera protectora del esófago, provocando esofagitis.',
                  '- Los síntomas del Esófago de Barrett son similares a los del reflujo gastroesofágico.'
                ],
              },
              {
                titleSection: 'Diagnóstico del Esófago de Barrett',
                content: [
                  'El diagnóstico se realiza principalmente en pacientes con:',
                  '- Reflujo crónico, especialmente de contenido biliar.',
                  '- Hernia hiatal mayor a 4 cm.',
                  '- Esfínter Esofágico Inferior defectuoso.',
                  '- Motilidad esofágica disminuida.',
                  '- Síntomas de ERGE por más de 5 años.',
                  'La confirmación del diagnóstico requiere una biopsia obtenida durante una endoscopía.'
                ],
              },
              {
                titleSection: 'Tratamiento del Esófago de Barrett',
                content: [
                  'El tratamiento tiene como objetivos:',
                  '- Controlar el reflujo.',
                  '- Curar la esofagitis.',
                  '- Detectar y tratar tempranamente la displasia.',
                  'Las opciones de tratamiento incluyen:',
                  '- Endoscopia para vigilancia y ablación de tejido.',
                  '- Tratamiento médico para controlar los síntomas.',
                  '- Cirugía antirreflujo, que es superior al tratamiento médico en la prevención del cáncer.',
                  '- Terapias ablativas en pacientes con displasia confirmada.',
                  'La displasia es el indicador más importante para evaluar el riesgo de adenocarcinoma, y su detección temprana es esencial para un tratamiento efectivo.'
                ],
                // image: 'https://unidad-gastroenterologia.com/images/tratamiento-esofago-barrett.jpg',
              },
            ],
          },
          EnfermedadReflujoGastroEsofagico: {
            title: 'Enfermedad por Reflujo Gastroesofágico (ERGE): Causas, Diagnóstico y Tratamiento',
            description: 'Conoce las causas, síntomas, diagnóstico y opciones de tratamiento para la Enfermedad por Reflujo Gastroesofágico (ERGE), una condición crónica que afecta al esófago.',
            keywords: 'ERGE, reflujo gastroesofágico, esófago de Barrett, funduplicatura, tratamiento de reflujo, síntomas de ERGE',
            canonical: 'https://unidad-gastroenterologia.com/reflujo',
            link: '/reflujo',
            className: 'enfermedades-esofago-page',
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/reflujo-estomacal.jpg',
            sections: [
              {
                titleSection: '¿Qué es el Reflujo Gastroesofágico?',
                content: [
                  'La Enfermedad por Reflujo Gastroesofágico (ERGE) es una condición crónica que afecta al 75% de los pacientes con patologías esofágicas.',
                  'Se caracteriza por el regreso del contenido gástrico hacia el esófago, y en ocasiones, hasta la faringe y la boca, provocando lesiones en el tejido esofágico.'
                ],
              },
              {
                titleSection: 'Causas del Reflujo Gastroesofágico',
                content: [
                  'El reflujo ocurre principalmente debido a:',
                  '- Disminución de la presión en el Esfínter Esofágico Inferior (EEI), que normalmente evita el reflujo.',
                  '- Distensión gástrica que aumenta la presión en el estómago y supera la capacidad del EEI.',
                  '- Deterioro de los movimientos peristálticos del esófago, encargados de limpiar el contenido ácido que refluye.'
                ],
              },
              {
                titleSection: 'Complicaciones del Reflujo Gastroesofágico',
                content: [
                  'La ERGE puede llevar a complicaciones severas como:',
                  '- Erosiones esofágicas pépticas.',
                  '- Úlceras esofágicas.',
                  '- Esófago de Barrett, un precursor del adenocarcinoma de esófago.',
                  '- Adenocarcinoma de esófago, cuyo riesgo aumenta en pacientes con Esófago de Barrett.'
                ],
              },
              {
                titleSection: 'Síntomas del Reflujo Gastroesofágico',
                content: [
                  'Los síntomas típicos incluyen:',
                  '- Pirosis (agruras): Sensación de ardor en el pecho o abdomen superior.',
                  '- Reflujo ácido: Sensación de boca amarga.',
                  '- Regurgitación de alimentos.',
                  '- Dolor abdominal superior y dificultad para tragar (disfagia).',
                  '- Náuseas, flatulencias y eructos.',
                  'Síntomas atípicos pueden incluir:',
                  '- Dolor torácico, sensación de ahogo nocturno, laringitis y tos.',
                  '- Dificultad para respirar, dolor de garganta, voz ronca, asma y erosiones dentales.'
                ],
              },
              {
                titleSection: 'Diagnóstico del Reflujo Gastroesofágico',
                content: [
                  'Para un diagnóstico preciso y detección de complicaciones se recomiendan:',
                  '- **Endoscopia**: Identifica hernia hiatal, clasifica la esofagitis y obtiene biopsias para descartar Esófago de Barrett o cáncer.',
                  '- **pHmetría de 24 horas**: Evalúa la exposición del esófago al ácido gástrico.',
                  '- **Manometría esofágica**: Mide la presión del EEI y la motilidad del esófago, útil para planificar el tratamiento quirúrgico.'
                ],
              },
              {
                titleSection: 'Tratamiento del Reflujo Gastroesofágico',
                content: [
                  'El tratamiento tiene como objetivos:',
                  '- Aliviar los síntomas.',
                  '- Revertir el daño esofágico.',
                  '- Prevenir complicaciones.',
                  'Las opciones incluyen:',
                  '- Cambios en el estilo de vida y dieta:',
                  '  - Elevar el cabezal de la cama.',
                  '  - Reducir la cantidad y grasa en las comidas.',
                  '  - Evitar irritantes como café, alcohol, chocolate y tomate.',
                  '- Medicamentos:',
                  '  - Inhibidores de la bomba de protones (omeprazol, lanzoprazol, esomeprazol).',
                  '  - Procinéticos para mejorar la motilidad.',
                  '- Cirugía antirreflujo (funduplicatura):',
                  '  - Método más eficaz para prevenir complicaciones como Esófago de Barrett y adenocarcinoma.'
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/reflujo-estomacal.jpg',
              },
            ],
          },  
    },
    EnfermedadesDeColon: {
      Colitis: {
        title: 'Colitis: Síntomas, Diagnóstico y Tratamiento del Colon Irritable',
        description: 'La colitis es un trastorno del sistema nervioso entérico que afecta el movimiento intestinal y la sensibilidad al dolor. Descubre sus causas, síntomas y manejo.',
        keywords: 'colitis, colon irritable, síntomas colitis, diagnóstico colon irritable, tratamiento colitis, dieta para colitis',
        canonical: 'https://unidad-gastroenterologia.com/colitis',
        link: '/colitis',
        className: 'enfermedades-colon-page',
        image: 'https://unidad-gastroenterologia.com/images/colitis.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Colitis?',
            content: [
              'La colitis, o síndrome de intestino irritable (SII), es un trastorno del sistema nervioso entérico del intestino que afecta el eje cerebro-intestinal.',
              'Se caracteriza por alteraciones en el movimiento intestinal y una mayor sensibilidad del intestino al dolor.'
            ],
          },
          {
            titleSection: 'Manifestaciones del Colon Irritable',
            content: [
              'Existen tres síntomas principales:',
              '- **Dolor abdominal**: Generalmente mejora o desaparece con la evacuación.',
              '- **Diarrea**: Puede presentarse en episodios intermitentes con estreñimiento.',
              '- **Estreñimiento**: Persistente y frecuentemente asociado a esfuerzo durante la evacuación.',
              'Casos comunes:',
              '- Mujeres con dolor abdominal y dificultad para evacuar, con alivio del dolor tras las evacuaciones.',
              '- Varones con diarrea acompañada de retortijones y heces que pasan de compactas a líquidas.',
              '- Pacientes con dolor abdominal recurrente y evacuaciones diarreicas seguidas de días sin evacuaciones o con evacuaciones incompletas (tenesmo).'
            ],
            image: 'https://unidad-gastroenterologia.com/images/sintomas-colitis.jpg',
          },
          {
            titleSection: 'Diagnóstico de Colon Irritable',
            content: [
              'Los criterios diagnósticos incluyen:',
              '- Molestias o dolor abdominal durante al menos 12 semanas en los últimos 12 meses.',
              '- Alivio del dolor con la defecación.',
              '- Cambios en la frecuencia, forma o aspecto de las evacuaciones.',
              'Otros síntomas incluyen:',
              '- Estreñimiento o diarrea recurrentes.',
              '- Presencia de moco en las evacuaciones.',
              '- Sensación de inflamación o gases abdominales.',
              'Se recomienda una colonoscopia completa y estudios de laboratorio para descartar otras condiciones como tumores, pólipos o divertículos.'
            ],
          },
          {
            titleSection: 'Tratamiento de la Colitis',
            content: [
              'El tratamiento depende de los síntomas predominantes:',
              '- En pacientes con diarrea, se utilizan antidiarreicos y dietas específicas.',
              '- En pacientes con estreñimiento, se emplean laxantes suaves y dieta rica en fibra.',
              'Recomendaciones generales:',
              '- Dieta rica en fibra, libre de irritantes y alimentos que fermenten en el colon.',
              '- Evitar alimentos que produzcan gases para reducir molestias abdominales.',
              '- En casos severos, se pueden usar medicamentos antidepresivos a dosis bajas para reducir la sensibilidad intestinal.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/tratamiento-colitis.jpg',
          },
          {
            titleSection: '¿La Colitis se Cura?',
            content: [
              'El síndrome de intestino irritable (SII) es una condición crónica, pero puede manejarse eficazmente.',
              'Con una combinación de educación, dieta adecuada y tratamiento farmacológico, los pacientes pueden lograr una calidad de vida significativamente mejorada.'
            ],
          },
        ],
      },
      ApendicitisAguda: {
        title: 'Apendicitis Aguda: Síntomas, Diagnóstico y Tratamiento',
        description: 'La apendicitis aguda es la causa más común de cirugía abdominal, caracterizada por inflamación del apéndice cecal. Aprende sobre sus síntomas, diagnóstico y manejo quirúrgico.',
        keywords: 'apendicitis aguda, cirugía apéndice, síntomas apendicitis, tratamiento apendicitis, peritonitis, laparoscopia',
        canonical: 'https://unidad-gastroenterologia.com/apendicitis-aguda',
        link: '/apendicitis-aguda',
        className: 'enfermedades-colon-page',
        image: 'https://unidad-gastroenterologia.com/images/apendicitis-aguda.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Apendicitis Aguda?',
            content: [
              'La apendicitis aguda es una inflamación del apéndice cecal, localizada en la parte inferior derecha del abdomen. Es la causa más común de cirugía abdominal.',
              'Se origina por la obstrucción de la luz del apéndice, lo que provoca inflamación e infección. Este proceso puede evolucionar hacia gangrena y perforación, causando complicaciones graves como peritonitis o abscesos periapendiculares.'
            ],
          },
          {
            titleSection: '¿Cómo se manifiesta la Apendicitis Aguda?',
            content: [
              'El cuadro clásico incluye:',
              '- Dolor peri umbilical que migra al cuadrante inferior derecho del abdomen.',
              '- Náuseas y vómitos.',
              '- Fiebre de hasta 38°C.',
              '- Dolor abdominal persistente que empeora con el tiempo.',
              'Factores que pueden alterar la presentación clásica:',
              '- Edad avanzada (>50 años).',
              '- Diabetes.',
              '- Uso de medicamentos sin prescripción médica, lo que puede retrasar el diagnóstico y agravar la condición.'
            ],
            image: 'https://unidad-gastroenterologia.com/static/images/enfermedades/apendicitis.gif',
          },
          {
            titleSection: 'Diagnóstico de Apendicitis Aguda',
            content: [
              'El diagnóstico generalmente se realiza mediante:',
              '- Un interrogatorio detallado y una exploración física completa del abdomen.',
              '- Estudios complementarios en casos atípicos o cuando se ha usado analgésicos, como:',
              '  - Radiografías de abdomen.',
              '  - Ultrasonido abdominal.',
              '  - Tomografía computarizada.',
              'Es preferible realizar una cirugía temprana incluso si hay dudas diagnósticas, ya que operar un apéndice sano es menos riesgoso que dejar evolucionar a una peritonitis.'
            ],
          },
          {
            titleSection: 'Tratamiento de la Apendicitis Aguda',
            content: [
              'El tratamiento definitivo es la extirpación quirúrgica del apéndice (apendicectomía).',
              'Tipos de cirugía:',
              '- **Cirugía convencional**: Mediante una incisión en el cuadrante inferior derecho.',
              '- **Cirugía laparoscópica**: Realizada a través de pequeñas incisiones, lo que reduce el tiempo de recuperación.',
              'En casos de perforación, se debe drenar cualquier absceso presente y manejar las complicaciones adecuadamente.'
            ],
            image: 'https://unidad-gastroenterologia.com/static/images/enfermedades/apendicitis-a.gif',
          },
          {
            titleSection: 'Pronóstico de la Apendicitis Aguda',
            content: [
              'El pronóstico depende de si el apéndice se ha perforado:',
              '- **Apendicectomía simple sin perforación**: Recuperación rápida, con alta hospitalaria en menos de 48 horas.',
              '- **Con perforación**: La evolución depende de:',
              '  - Estado nutricional del paciente.',
              '  - Presencia de enfermedades comórbidas como diabetes y obesidad.',
              '  - Extensión de la peritonitis (localizada o diseminada).'
            ],
          },
        ],
      },
      ColitisUlcerativaCronicaInespecifica: {
        title: 'Colitis Ulcerativa Crónica Inespecífica (CUCI): Síntomas, Diagnóstico y Tratamiento',
        description: 'La Colitis Ulcerativa Crónica Inespecífica (CUCI) es una inflamación crónica del colon y recto. Aprende sobre sus causas, síntomas y manejo médico.',
        keywords: 'colitis ulcerativa crónica, CUCI, inflamación crónica colon, diagnóstico colitis ulcerativa, tratamiento CUCI',
        canonical: 'https://unidad-gastroenterologia.com/colitis-ulcerativa',
        link: '/colitis-ulcerativa',
        className: 'enfermedades-colon-page',
        image: 'https://unidad-gastroenterologia.com/images/colitis-ulcerativa.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Colitis Ulcerativa Crónica Inespecífica?',
            content: [
              'La Colitis Ulcerativa Crónica Inespecífica (CUCI) es una enfermedad inflamatoria intestinal caracterizada por:',
              '- Inflamación crónica y recurrente de la mucosa del colon y recto.',
              '- Inicio en la mucosa rectal, con extensión variable hacia el colon.',
              'Es una enfermedad subdiagnosticada debido a su espectro clínico variable y la similitud con otras colitis, como las infecciosas.'
            ],
          },
          {
            titleSection: 'Frecuencia e Importancia de la CUCI',
            content: [
              'La incidencia de CUCI en España y Latinoamérica es de 1.2 a 2.5 por 100,000 habitantes, con mayor prevalencia en países industrializados.',
              '- Afecta por igual a hombres y mujeres.',
              '- Mayor incidencia entre los 15-30 años, con un segundo pico entre los 55-80 años.',
              'Su diagnóstico temprano es crucial para prevenir complicaciones graves, incluida la progresión a cáncer de colon.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/frecuencia-colitis-ulcerativa.jpg',
          },
          {
            titleSection: 'Factores que Contribuyen a la CUCI',
            content: [
              'La CUCI resulta de la interacción de factores ambientales, genéticos e inmunitarios.',
              '- **Factores Ambientales**:',
              '  - Tabaquismo (efecto protector).',
              '  - Consumo excesivo de grasas y vitamina B6.',
              '  - Desequilibrios en la flora intestinal.',
              '  - Infecciones perinatales e infantiles.',
              '  - Estrés y uso de anticonceptivos orales.',
              '- **Factores Genéticos**:',
              '  - Concordancia elevada entre gemelos.',
              '  - Riesgo incrementado en familiares directos.',
              '- **Mecanismos Inmunitarios**:',
              '  - Respuesta alterada a antígenos intestinales o moléculas propias del colon.'
            ],
          },
          {
            titleSection: 'Síntomas de la Colitis Ulcerativa',
            content: [
              'La CUCI se caracteriza por episodios intermitentes de inflamación, que incluyen:',
              '- Diarrea con moco y sangre.',
              '- Urgencia para evacuar y sensación de evacuación incompleta.',
              '- Tenesmo rectal (esfuerzo sin resultado).',
              'La gravedad depende de la extensión de la inflamación:',
              '- **Leve**: Menos de 4 evacuaciones diarias, con poca sangre.',
              '- **Moderada**: Hasta 6 evacuaciones diarias, anemia leve, febrícula y dolor abdominal.',
              '- **Grave**: Múltiples evacuaciones con diarrea sanguinolenta, dolor intenso, fiebre, taquicardia, y pérdida de peso significativa.'
            ],
          },
          {
            titleSection: 'Diagnóstico de la CUCI',
            content: [
              'El diagnóstico se realiza mediante:',
              '- **Colonoscopia**: Observa directamente la mucosa colónica e identifica:',
              '  - Eritema difuso.',
              '  - Apariencia granular y friable de la mucosa.',
              '  - Úlceras, seudopólipos y estenosis.',
              '- **Histopatología**: Confirma la inflamación y permite descartar otras condiciones.',
              'La colonoscopia está contraindicada en casos graves, megacolon tóxico o si no es posible preparar el intestino.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/diagnostico-colitis-ulcerativa.jpg',
          },
          {
            titleSection: 'Relación con el Cáncer de Colon',
            content: [
              'El riesgo de desarrollar cáncer colorectal aumenta significativamente con la duración y extensión de la CUCI.',
              '- El riesgo se incrementa a partir de los 8-10 años desde el inicio de la enfermedad.',
              '- La vigilancia regular mediante colonoscopia anual es esencial para detectar displasias tempranas.'
            ],
          },
          {
            titleSection: 'Tratamiento de la Colitis Ulcerativa',
            content: [
              'El tratamiento incluye:',
              '- **Medicamentos**: Corticoides, inmunomoduladores y agentes biológicos según la gravedad.',
              '- **Cambios en el estilo de vida**:',
              '  - Dieta equilibrada, evitando irritantes y alimentos difíciles de digerir.',
              '  - Reducción del estrés y manejo emocional.',
              '- **Cirugía**: Indicado en casos graves o refractarios, como colectomía total para evitar complicaciones.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/tratamiento-colitis-ulcerativa.jpg',
          },
        ],
      },
      EnfermedadDeCrohn: {
        title: 'Enfermedad de Crohn: Síntomas, Diagnóstico y Tratamiento',
        description: 'La Enfermedad de Crohn es una inflamación crónica del tracto gastrointestinal que puede afectar cualquier parte del sistema digestivo. Conoce sus síntomas, diagnóstico y manejo.',
        keywords: 'enfermedad de Crohn, inflamación intestinal, síntomas Crohn, diagnóstico Crohn, tratamiento enfermedad Crohn',
        canonical: 'https://unidad-gastroenterologia.com/enfermedad-crohn',
        link: '/enfermedad-crohn',
        className: 'enfermedades-colon-page',
        image: 'https://unidad-gastroenterologia.com/images/enfermedades/enfermedad-crohn.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Enfermedad de Crohn?',
            content: [
              'La Enfermedad de Crohn es una inflamación transmural crónica del tracto gastrointestinal caracterizada por:',
              '- Presencia de granulomas.',
              '- Afectación predominantemente del colon y el íleon, aunque puede afectar cualquier parte del sistema digestivo.',
              'Se caracteriza por un patrón inflamatorio en "parches", donde áreas sanas alternan con zonas inflamadas. Puede llevar a complicaciones como fistulización y estenosis intestinal.'
            ],
          },
          {
            titleSection: 'Epidemiología y Factores de Riesgo',
            content: [
              'Aproximadamente el 40% de los pacientes presenta afectación simultánea del intestino delgado y grueso, mientras que:',
              '- El 30% afecta solo al intestino delgado.',
              '- El 25% afecta únicamente al colon.',
              'Es más común en mujeres y su prevalencia es de 130 por cada 100,000 habitantes en países occidentales.',
              'Factores de riesgo:',
              '- Susceptibilidad genética (25% tienen antecedentes familiares).',
              '- Factores infecciosos, ambientales o farmacológicos desencadenan la enfermedad en pacientes con una respuesta inmunitaria alterada.'
            ],
          },
          {
            titleSection: 'Síntomas de la Enfermedad de Crohn',
            content: [
              'Los síntomas principales incluyen:',
              '- Dolor abdominal, diarrea y pérdida de peso.',
              '- Obstrucción intestinal y estreñimiento.',
              '- Drenaje rectal perianal debido a fistulización.',
              '- Anemia por pérdida de sangre o mala absorción.',
              'Los síntomas dependen de la localización de la enfermedad y pueden variar desde molestias leves hasta complicaciones graves, como distensión abdominal y fiebre.'
            ],
          },
          {
            titleSection: 'Diagnóstico de la Enfermedad de Crohn',
            content: [
              'El diagnóstico incluye:',
              '- **Pruebas de sangre**: Anemia, leucocitosis, trombocitosis e hipoalbuminemia.',
              '- **Endoscopia**: Colonoscopia con biopsias para identificar ulceraciones aftosas y granulomas.',
              '- **Estudios de imagen**: Radiografías, TC y estudios con contraste de bario que revelan estenosis, inflamación o fistulas.',
              '- **Marcadores serológicos**:',
              '  - Elevación de ASCA (anti-Saccharomyces cerevisiae) en 60-70% de los casos.',
              '  - PANCA negativo confirma casi con certeza el diagnóstico.',
              'La enteroscopia de propulsión y la cápsula endoscópica son útiles para localizar áreas afectadas en el intestino delgado.'
            ],
          },
          {
            titleSection: 'Complicaciones y Manifestaciones Extraintestinales',
            content: [
              'Las complicaciones incluyen:',
              '- Fístulas internas o externas.',
              '- Obstrucción intestinal y estenosis.',
              '- Abscesos y perforación intestinal.',
              'Manifestaciones extraintestinales en el 25% de los pacientes:',
              '- **Piel**: Pioderma gangrenoso, colgajos cutáneos perianales.',
              '- **Ojos**: Uveítis, iriditis y conjuntivitis.',
              '- **Hígado**: Colangitis esclerosante primaria.',
              '- **Sistema musculoesquelético**: Artritis periférica, sacroileitis y osteoporosis.'
            ],
          },
          {
            titleSection: 'Diagnóstico Diferencial',
            content: [
              'El diagnóstico diferencial incluye:',
              '- Colitis Ulcerativa Crónica Inespecífica (CUCI).',
              '- Linfomas intestinales y otras neoplasias.',
              '- Tuberculosis intestinal e infecciones crónicas por Yersinia.'
            ],
          },
          {
            titleSection: 'Tratamiento de la Enfermedad de Crohn',
            content: [
              'El manejo depende de la gravedad y extensión de la enfermedad:',
              '- **Tratamiento médico**:',
              '  - Corticoides, inmunomoduladores y agentes biológicos para reducir la inflamación.',
              '- **Intervención quirúrgica**:',
              '  - Indicado en casos de complicaciones como estenosis, abscesos o fistulas.',
              '- **Cambios en el estilo de vida**:',
              '  - Dieta adecuada y manejo del estrés para minimizar exacerbaciones.',
              'El 70% de los pacientes requiere algún tipo de intervención quirúrgica durante su vida, pero con un manejo adecuado, la calidad de vida puede mejorar significativamente.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/tratamiento-crohn.jpg',
          },
          {
            titleSection: 'Pronóstico',
            content: [
              'Aunque la Enfermedad de Crohn no reduce significativamente la esperanza de vida, su impacto en la calidad de vida puede ser notable debido a las exacerbaciones y complicaciones.',
              'El tratamiento temprano y un seguimiento médico riguroso son esenciales para controlar los síntomas y prevenir complicaciones graves.'
            ],
          },
        ],
      },
      CancerDeColon: {
        title: 'Cáncer de Colon: Factores de Riesgo, Diagnóstico y Tratamiento',
        description: 'El cáncer de colon es una de las principales causas de mortalidad. Conoce sus factores de riesgo, síntomas, métodos de diagnóstico y opciones de tratamiento.',
        keywords: 'cáncer de colon, cáncer colorrectal, adenocarcinoma, diagnóstico de cáncer de colon, tratamiento cáncer de colon',
        canonical: 'https://unidad-gastroenterologia.com/cancer-de-colon',
        link: '/cancer-de-colon',
        className: 'enfermedades-colon-page',
        image: 'https://unidad-gastroenterologia.com/images/enfermedades/cancer-de-colon.png',
        sections: [
          {
            titleSection: '¿Qué es el Cáncer de Colon?',
            content: [
              'El cáncer de colon es el tercer tipo de cáncer más común en hombres y mujeres. Se desarrolla principalmente a partir de pólipos adenomatosos en la mucosa colónica sana que evolucionan hacia adenocarcinomas.',
              'Factores de riesgo principales:',
              '- Edad avanzada (más de 40 años).',
              '- Dieta rica en grasas y colesterol.',
              '- Enfermedades inflamatorias intestinales, como colitis ulcerosa.',
              '- Predisposición genética, incluyendo poliposis hereditaria y cáncer de colon hereditario no polipósico.',
              'El cáncer de colon, si se detecta a tiempo, puede ser curado mediante cirugía.'
            ],
          },
          {
            titleSection: 'Herencia y Factores Genéticos',
            content: [
              'La mayoría de los tumores colorrectales son adenocarcinomas que se desarrollan a través de la secuencia adenoma-carcinoma, asociada con alteraciones genéticas moleculares específicas.',
              'El cáncer de colon hereditario no polipósico (HNPCC) implica una mutación genética hereditaria que aumenta significativamente el riesgo de desarrollar cáncer colorrectal.',
              'Nuevos métodos diagnósticos podrían incluir la detección de mutaciones genéticas en heces.'
            ],
          },
          {
            titleSection: 'Frecuencia y Supervivencia',
            content: [
              'En Estados Unidos, se reportaron 104,950 nuevos casos de cáncer de colon en 2005, con una tasa global de supervivencia a 5 años del 60%.',
              'Tasa de supervivencia por estadio:',
              '- Estadio A (limitado a la mucosa): >90%.',
              '- Estadio B (invasión limitada): >70%.',
              '- Estadio C (afectación de ganglios linfáticos): <60%.',
              '- Estadio metastásico: ~5%.'
            ],
          },
          {
            titleSection: 'Síntomas del Cáncer de Colon',
            content: [
              'Los síntomas dependen de la localización del tumor:',
              '- **Colon Derecho**: Anemia crónica, diarrea.',
              '- **Colon Izquierdo**: Sangrado con heces, inflamación abdominal, síntomas de oclusión intestinal.',
              '- **Recto**: Sangrado, cambios en la frecuencia y volumen de evacuaciones, detectables en un 90% de los casos con un tacto rectal.'
            ],
          },
          {
            titleSection: 'Métodos de Diagnóstico',
            content: [
              '- **Colonoscopia**: Método estándar para detectar pólipos y obtener biopsias.',
              '- **Antígenos tumorales**:',
              '  - **CEA (Antígeno carcinoembrionario)**: Útil para monitorear la respuesta al tratamiento.',
              '  - **CA 19-9**: Puede correlacionarse con la incidencia de recidivas.',
              '- **Estudios de imagen**:',
              '  - Radiografía de tórax: Para evaluar metástasis pulmonares.',
              '  - TC abdominal/pélvica: Diagnóstico de metástasis en hígado o ganglios linfáticos.',
              '  - Enema baritado con doble contraste: Alternativa menos sensible a la colonoscopia.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/detectar-cancer-colon.jpeg',
          },
          {
            titleSection: 'Hallazgos Histológicos',
            content: [
              'Los adenocarcinomas colónicos muestran estructuras glandulares que pueden ser bien o mal diferenciadas. Se observa pérdida de la estructura topológica normal del epitelio colónico.'
            ],
          },
          {
            titleSection: 'Tratamiento del Cáncer de Colon',
            content: [
              '- **Quirúrgico**: Resección completa del tumor y los ganglios linfáticos involucrados.',
              '- **Quimioterapia adyuvante**: Indicada en caso de afectación ganglionar para prolongar la supervivencia.',
              '- **Radioterapia**: Utilizada en cáncer rectal para reducir el riesgo de recidiva local.',
              '- En casos metastásicos avanzados, el tratamiento es paliativo y busca mejorar la calidad de vida del paciente.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/tratamiento-cancer-colon.jpg',
          },
          {
            titleSection: 'Pronóstico',
            content: [
              'El pronóstico depende del estadio en el momento del diagnóstico. La detección temprana mediante colonoscopias regulares es clave para mejorar la supervivencia.',
              'Factores asociados con mejor pronóstico:',
              '- Diagnóstico en estadios iniciales.',
              '- Ausencia de metástasis en ganglios linfáticos y órganos distantes.',
              '- Tratamiento quirúrgico oportuno combinado con terapias complementarias.'
            ],
          },
        ],
      },
      DiverticulosDelColon: {
        title: 'Divertículos del Colon: Prevención, Diagnóstico y Tratamiento',
        description: 'Conoce todo sobre los divertículos del colon: qué son, sus causas, síntomas, complicaciones y opciones de tratamiento, incluyendo la prevención mediante dieta rica en fibra.',
        keywords: 'divertículos, diverticulosis, diverticulitis, complicaciones del colon, tratamiento diverticulitis',
        canonical: 'https://unidad-gastroenterologia.com/diverticulos-del-colon',
        link: '/diverticulos-del-colon',
        className: 'enfermedades-colon-page',
        image: 'https://unidad-gastroenterologia.com/images/enfermedades/colon/diverticulos.jpg',
        sections: [
          {
            titleSection: '¿Qué son los Divertículos del Colon?',
            content: [
              'Los divertículos son saculaciones o herniaciones que se forman en la pared del colon. Estas protrusiones carecen de todas las capas de la pared colónica, lo que las hace más susceptibles a perforaciones.',
              'La presencia de múltiples divertículos en el colon se denomina enfermedad diverticular o diverticulosis.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/colon/diverticulos.gif',
          },
          {
            titleSection: '¿Quiénes son más propensos?',
            content: [
              'El 15-20% de la población general desarrolla divertículos en el colon.',
              'En mayores de 60 años, la prevalencia aumenta al 50%, y en mayores de 70 años, alcanza hasta el 95%.'
            ],
          },
          {
            titleSection: 'Prevención de Divertículos del Colon',
            content: [
              'Una dieta rica en fibra basada en cereales, frutas y verduras es clave para prevenir la enfermedad diverticular.',
              'Reducir el consumo de azúcares refinados también disminuye significativamente el riesgo.'
            ],
          },
          {
            titleSection: 'Manifestaciones de la Enfermedad Diverticular',
            content: [
              'La mayoría de los divertículos son asintomáticos. Sin embargo, algunos pacientes experimentan:',
              '- Dolor cólico en el cuadrante inferior izquierdo del abdomen, que mejora tras evacuar.',
              '- Síntomas similares al colon irritable.',
              'El tratamiento inicial incluye medidas dietéticas como aumentar la ingesta de fibra, agua y actividad física. La cirugía generalmente no es necesaria.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/colon/diverticulos-1.gif',
          },
          {
            titleSection: 'Complicaciones de la Enfermedad Diverticular',
            content: [
              '- **Diverticulitis Aguda**: Ocurre cuando un divertículo se perfora, causando inflamación e infección. Se manifiesta con dolor abdominal, fiebre, náuseas, vómitos y alteraciones en los hábitos intestinales.',
              '- **Fístulas de Colon**: Cerca del 5% de los pacientes desarrollan fístulas colo-vesicales, colo-vaginales, colo-cutáneas o colo-entéricas.',
              '- **Hemorragias**: Entre el 10-15% de los pacientes con divertículos experimentan episodios de sangrado, que puede ser masivo y poner en riesgo la vida.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/colon/diverticulos-2.gif',
          },
          {
            titleSection: 'Hemorragias por Diverticulitis',
            content: [
              'El 10-15% de los pacientes con divertículos de colon experimenta episodios de sangrado durante la enfermedad. Estas hemorragias pueden ser masivas y poner en riesgo la vida del paciente de forma súbita.',
              'El sangrado ocurre por traumatismo mecánico en los vasos submucosos dentro del divertículo afectado. El diagnóstico inicial incluye una colonoscopia para identificar el sitio de sangrado, lo que se logra en el 70% de los casos.',
              'Si la colonoscopia no identifica la fuente, se realizan estudios como arteriografía o gammagrama con eritrocitos marcados.',
              'Aunque la mayoría de los sangrados se resuelven espontáneamente, si son persistentes o recurrentes, el tratamiento incluye la resección quirúrgica del segmento intestinal afectado.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/colon/diverticulos-4.gif',
          },
          {
            titleSection: 'Diagnóstico de las Complicaciones',
            content: [
              '- **Diverticulitis Aguda**: La tomografía computarizada es el estudio de elección para determinar el grado de inflamación y presencia de abscesos o líquido libre.',
              '- **Fístulas Colo-Vesicales**: Se diagnostican mediante colonoscopia, colon por enema y cistografía.',
              '- **Hemorragias**: La colonoscopia es el primer estudio a realizar, seguido de arteriografía o gammagrama con eritrocitos marcados si el sangrado persiste.'
            ],
          },
          {
            titleSection: 'Tratamiento de las Complicaciones',
            content: [
              '- **Diverticulitis Aguda**: En casos severos, se requiere cirugía para resecar el segmento afectado. Dependiendo de la contaminación abdominal, puede realizarse una colostomía temporal.',
              '- **Fístulas Colo-Vesicales**: Requieren cirugía electiva con resección del segmento afectado y cierre del defecto vesical.',
              '- **Hemorragias**: Generalmente cesan espontáneamente. Si persisten o son recurrentes, es necesaria la resección del segmento afectado.'
            ],
          },
          {
            titleSection: 'Pronóstico',
            content: [
              'La mayoría de los pacientes con enfermedad diverticular asintomática pueden controlar su afección mediante cambios en el estilo de vida.',
              'Las complicaciones como diverticulitis aguda o hemorragias severas requieren intervención médica o quirúrgica, con un pronóstico generalmente favorable si se tratan oportunamente.'
            ],
          },
        ],
      },
    },
    EnfermedadesAnoRectales: {
      Hemorroides: {
        title: 'Hemorroides',
        description: 'Las hemorroides son dilataciones varicosas en el conducto anal, presentes en el 75% de la población. Aprende sobre sus causas, síntomas y tratamientos.',
        keywords: 'hemorroides, trombosis hemorroidal, hemorroides internas, hemorroides externas, tratamiento de hemorroides',
        canonical: 'https://unidad-gastroenterologia.com/hemorroides',
        link: '/hemorroides',
        className: 'enfermedades-anorrectales-page',
        image: 'https://unidad-gastroenterologia.com/images/hemorroides.jpg',
        sections: [
          {
            titleSection: '¿Qué son las Hemorroides?',
            content: [
              'Las hemorroides son dilataciones varicosas de los plexos hemorroidales ubicados en el conducto anal.',
              'Se presentan en el 75% de la población y suelen aparecer a partir de los 25 años de edad.'
            ],
          },
          {
            titleSection: 'Causas de las Hemorroides',
            content: [
              'Entre las causas más comunes se encuentran:',
              '- Factores genéticos.',
              '- Ausencia de válvulas en el sistema venoso del ano.',
              '- Posición erecta del ser humano.',
              '- Condiciones que aumentan la presión en los plexos venosos, como:',
              '  - Estreñimiento.',
              '  - Esfuerzo en la defecación.',
              '  - Diarreas.',
              '  - Tumores abdominales.',
              '  - Embarazo.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/hemorroides/hemorroide.gif',
          },
          {
            titleSection: 'Tipos de Hemorroides',
            content: [
              '- **Hemorroides externas**: Localizadas en la piel del borde anal. Pueden desarrollar trombosis hemorroidal, causando dolor intenso.',
              '- **Hemorroides internas**: Localizadas dentro del conducto anal. Dependiendo de su crecimiento, pueden sobresalir a través del orificio anal.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/hemorroides/hemorroides-1.gif',
          },
          {
            titleSection: 'Manifestaciones de las Hemorroides',
            content: [
              'Las hemorroides externas suelen manifestarse como masas dolorosas y sensibles.',
              'Las hemorroides internas causan hemorragia rectal, que puede manchar el papel higiénico, el inodoro o provocar anemia en casos severos.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/hemorroides/hemorroides.gif',
          },
          {
            titleSection: 'Diagnóstico',
            content: [
              'El diagnóstico incluye inspección y exploración digital para hemorroides externas.',
              'Para hemorroides internas se utilizan herramientas como:',
              '- Rectoscopio o rectosigmoidoscopio.',
              '- Estudios de laboratorio para detectar anemia.',
              '- Colonoscopia para descartar otras causas de sangrado.'
            ],
            
          },
          { 
            titleSection: 'Tratamiento de las Hemorroides',
            content: [
              '- **Trombosis Hemorroidal**: Extracción del coágulo para aliviar el dolor.',
              '- **Hemorroides internas**:',
              '  - Estadios tempranos: Ablandadores de materia fecal, dieta rica en fibra, medicamentos flavonoides.',
              '  - Estadios intermedios: Escleroterapia, ligadura con bandas elásticas, rayos infrarrojos.',
              '  - Estadios avanzados: Tratamiento quirúrgico, moderno y ambulatorio.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/hemorroides/trombosis-hemorroidal.gif',
          },
        ],
      },
      FisuraAnal: {
        title: 'Fisura Anal',
        description: 'La fisura anal es un desgarro o herida en el ano que causa dolor y sangrado. Aprende sobre sus causas, síntomas y tratamientos disponibles.',
        keywords: 'fisura anal, dolor rectal, sangrado, tratamiento médico, cirugía fisura anal',
        canonical: 'https://unidad-gastroenterologia.com/fisura-anal',
        link: '/fisura-anal',
        className: 'enfermedades-anorrectales-page',
        image: 'https://unidad-gastroenterologia.com/images/fisura-anal.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Fisura Anal?',
            content: [
              'La fisura anal es un desgarro o herida ubicada en el ano, muy cerca de la piel. Es una condición común que puede causar molestias significativas.'
            ],
          },
          {
            titleSection: 'Causas de la Fisura Anal',
            content: [
              'La principal causa es el traumatismo provocado por el paso de materia fecal dura con esfuerzo. Sin embargo, también puede ser desencadenada por evacuaciones diarreicas severas.',
              'Es importante investigar posibles enfermedades subyacentes asociadas, tales como:',
              '- Colitis ulcerativa.',
              '- Sífilis.',
              '- Herpes.',
              '- VIH.',
              '- Leucemia.',
              '- Neoplasias.'
            ],
          },
          {
            titleSection: 'Síntomas de la Fisura Anal',
            content: [
              'El síntoma principal es el dolor intenso, que usualmente se desencadena después de:',
              '- Una evacuación dura.',
              '- Un episodio de diarrea severa.',
              '- Consumo excesivo de irritantes.',
              'El dolor puede llevar a evitar evacuaciones, agravando el estreñimiento y empeorando la fisura.',
              'El dolor generalmente se acompaña de sangrado rectal leve, que aparece como manchas en el papel higiénico o estrías de sangre en el bolo fecal.'
            ],
          },
          {
            titleSection: 'Tratamiento Médico',
            content: [
              'El tratamiento inicial se enfoca en:',
              '- Reducir el dolor.',
              '- Relajar el esfínter anal para prevenir traumatismos.',
              '- Favorecer la cicatrización mediante medicamentos tópicos en forma de pomadas.',
              '- Mantener evacuaciones de consistencia pastosa con:',
              '  - Ablandadores de materia fecal.',
              '  - Dieta rica en fibra.'
            ],
          },
          {
            titleSection: 'Tratamiento Quirúrgico',
            content: [
              'La cirugía está indicada solo para fisuras crónicas.',
              'Opciones no quirúrgicas incluyen:',
              '- Antagonistas de calcio.',
              '- Nitroglicerina.',
              '- Toxina botulínica (botox).',
              'Sin embargo, estas opciones tienen alta recurrencia en casos de fisuras crónicas.',
              'El tratamiento quirúrgico más aceptado consiste en la sección parcial del esfínter anal, lo que favorece:',
              '- La curación de la fisura.',
              '- La prevención de su recurrencia.'
            ],
          },
        ],
      },
      AbscesoAnal: {
        title: 'Absceso Anal',
        description: 'El absceso anal es una urgencia quirúrgica frecuente en coloproctología, con síntomas como dolor anal intenso. Aprende sobre sus causas, diagnóstico y tratamiento.',
        keywords: 'absceso anal, fistula anal, coloproctología, tratamiento quirúrgico, dolor anal',
        canonical: 'https://unidad-gastroenterologia.com/absceso-anal',
        link: '/absceso-anal',
        className: 'enfermedades-anorrectales-page',
        image: 'https://unidad-gastroenterologia.com/images/absceso-anal.jpg',
        sections: [
          {
            titleSection: '¿Qué es el Absceso Anal?',
            content: [
              'El absceso anal es la forma aguda de una enfermedad en la que su forma crónica se manifiesta como fístula anal.',
              'Es una urgencia quirúrgica frecuente en coloproctología, que afecta principalmente a hombres entre los 20 y 50 años de edad.'
            ],
          },
          {
            titleSection: '¿Por qué se Forma?',
            content: [
              'En el conducto anal hay pliegues llamados glándulas de Morgagni, que forman pequeñas cavidades conocidas como criptas anales.',
              'La infección de estas criptas causa el absceso, frecuentemente debido a:',
              '- Obstrucción por materia fecal, ya sea por evacuaciones duras o diarrea.',
              '- Traumatismos.',
              '- Cuerpos extraños.'
            ],
          },
          {
            titleSection: '¿Dónde se Localiza?',
            content: [
              'El pus se acumula alrededor del ano en diferentes espacios anatómicos delimitados por fascias, músculos y ligamentos.',
              'El proceso infeccioso puede romper estas barreras y extenderse dependiendo de la inmunidad del paciente y la virulencia del germen causante.'
            ],
          },
          {
            titleSection: '¿Cómo se Manifiesta?',
            content: [
              'El síntoma más común es el dolor anal intenso y punzante, que empeora al:',
              '- Sentarse.',
              '- Toser o estornudar.',
              '- Defecar.',
              'Si el absceso es superficial, puede presentarse como una masa dura, caliente y en crecimiento. En casos más profundos, el único síntoma puede ser el dolor.',
              'Si la infección persiste, los pacientes pueden presentar fiebre, sudoración y malestar general.'
            ],
          },
          {
            titleSection: '¿Cómo se Diagnostica?',
            content: [
              'En la mayoría de los casos, un examen proctológico es suficiente para detectar el absceso.',
              'En casos recurrentes o complejos, se pueden utilizar:',
              '- Tomografía.',
              '- Ultrasonido endoanal.',
              'Estos estudios también ayudan a identificar posibles asociaciones con enfermedades inflamatorias intestinales.'
            ],
          },
          {
            titleSection: 'Diagnóstico Diferencial',
            content: [
              'El diagnóstico diferencial incluye:',
              '- Hidradenitis Supurativa.',
              '- Enfermedad pilonidal.',
              '- Quiste de Bartholini con absceso.',
              '- Fisura anal.',
              '- Hemorroides trombosadas.'
            ],
          },
          {
            titleSection: 'Tratamiento o Drenaje del Absceso Anal',
            content: [
              'El tratamiento principal es quirúrgico y consiste en el drenaje del absceso.',
              'El uso de antibióticos es un complemento, pero no reemplaza al drenaje, ya que esto podría predisponer al paciente a complicaciones graves.'
            ],
          },
          {
            titleSection: 'Gangrena de Fournier',
            content: [
              'Es una complicación grave del absceso anal que ocurre cuando la infección se extiende al periné.',
              'Se trata de una infección necrosante de la región perineal que conlleva un alto riesgo de mortalidad.',
              'El manejo incluye:',
              '- Cirugía con lavados y resección de tejidos necróticos.',
              '- Uso de antibióticos de amplio espectro.',
              '- En casos graves, colostomía para evitar contaminación fecal en la zona afectada.'
            ],
          },
        ],
      },
      FistulaAnal: {
        title: 'Fístula Anal',
        description: 'La fístula anal es una comunicación anormal entre el conducto anal y la piel perianal, consecuencia de un proceso infeccioso crónico. Conoce sus causas, síntomas y tratamiento.',
        keywords: 'fístula anal, absceso anal, tratamiento quirúrgico, secreción anal, diagnóstico de fístula anal',
        canonical: 'https://unidad-gastroenterologia.com/fistula-anal',
        link: '/fistula-anal',
        className: 'enfermedades-anorrectales-page',
        image: 'https://unidad-gastroenterologia.com/images/fistula-anal.jpg',
        sections: [
          {
            titleSection: '¿Qué es la Fístula Anal?',
            content: [
              'La fístula anal es una comunicación anormal entre el conducto anal y la piel perianal.',
              'Es un proceso infeccioso crónico que, en su etapa aguda, se presentó como un absceso anal.'
            ],
          },
          {
            titleSection: 'Clasificación de las Fístulas Anales',
            content: [
              '- **Fístulas Simples**: Se identifican fácilmente el orificio de entrada y salida.',
              '- **Fístulas Complejas**: Tienen mayor dificultad terapéutica debido a riesgos de recurrencia o desarrollo de incontinencia.'
            ],
          },
          {
            titleSection: '¿Cómo se Forman las Fístulas Ano-Rectales?',
            content: [
              'El desarrollo de la fístula anal comienza con un absceso anal, que surge por la obstrucción de una glándula anal.',
              'El drenaje espontáneo o quirúrgico del absceso puede aliviar las molestias, pero no siempre elimina la infección.',
              'Cuando la infección persiste de forma crónica, se forma un trayecto fibroso hacia la piel, dando lugar a la fístula anal.'
            ],
          },
          {
            titleSection: '¿Quiénes Pueden Tener Fístula Anal?',
            content: [
              'Cualquier persona puede desarrollar una fístula anal, aunque es más frecuente en:',
              '- Varones entre los 30 y 40 años.',
              '- Con una incidencia de 12.3 por cada 100,000 habitantes.'
            ],
          },
          {
            titleSection: '¿Qué Molestias Ocasiona?',
            content: [
              'Los síntomas comunes incluyen:',
              '- Secreción intermitente de material blanco o amarillo en el ano.',
              '- Dolor.',
              '- Comezón anal.',
              '- Sangrado escaso y oscuro.',
              'En ocasiones, los pacientes no perciben el antecedente de un absceso anal, ya que este puede haber drenado espontáneamente.'
            ],
          },
          {
            titleSection: 'Diagnóstico',
            content: [
              'La exploración física es clave para observar el orificio externo de la fístula anal y determinar su trayecto.',
              'Cuando no se puede identificar el trayecto, se recurre a estudios de imagen, siendo el ultrasonido endoanal el más preciso.'
            ],
          },
          {
            titleSection: 'Tratamiento',
            content: [
              'El tratamiento es exclusivamente quirúrgico en todos los casos.',
              'No existen medicamentos que puedan curar esta enfermedad.',
              'El uso de antibióticos no elimina la fístula, solo enmascara los síntomas temporalmente, lo que puede retrasar el tratamiento adecuado.'
            ],
          },
        ],
      },
      CondilomasAnales: {
        title: 'Condilomas Anales: Causas, Diagnóstico y Tratamientos',
        description: 'Los condilomas anales son lesiones causadas por el virus del papiloma humano (VPH). Descubre cómo se contagian, sus síntomas, diagnóstico y tratamientos disponibles.',
        keywords: 'condilomas anales, VPH, papiloma humano, cáncer anal, tratamiento condilomas, crioterapia, imiquimod',
        canonical: 'https://unidad-gastroenterologia.com/condilomas-anales',
        link: '/condilomas-anales',
        className: 'enfermedades-anorrectales-page',
        image: 'https://unidad-gastroenterologia.com/images/condilomas-anales.jpg',
        sections: [
          {
            titleSection: '¿Qué Son los Condilomas Anales?',
            content: [
              'Los condilomas anales son infecciones causadas por el virus del papiloma humano (VPH), afectando la piel y el conducto anal.',
              'Aunque estas lesiones suelen ser benignas, pueden evolucionar y, en casos severos, conducir al desarrollo de cáncer anal.'
            ],
          },
          {
            titleSection: '¿Cómo se Contagian los Condilomas Anales?',
            content: [
              'Los condilomas anales son una enfermedad de transmisión sexual (ETS).',
              'El principal medio de contagio es el contacto sexual anal, representando el 95% de los casos.',
              'Sin embargo, el VPH también puede transmitirse a través de contacto directo con lesiones infectadas.'
            ],
          },
          {
            titleSection: '¿Cómo se Presentan los Condilomas?',
            content: [
              'Las lesiones suelen aparecer como pequeñas protuberancias con forma de coliflor, rugosas, y de tonalidades rosadas o blanquecinas.',
              'Suelen desarrollarse en áreas húmedas como el recto o la vagina, generalmente de manera simétrica en ambos lados del ano.',
              'Aunque suelen ser asintomáticas, pueden causar molestias leves como:',
              '- Comezón.',
              '- Ligero sangrado que mancha el papel higiénico.',
              '- Sensación de cuerpo extraño en el ano.',
              'La evolución de las lesiones depende del tipo de VPH involucrado, variando desde extensiones rápidas y progresivas hasta lesiones únicas que persisten por largos periodos.'
            ],
          },
          {
            titleSection: 'Diagnóstico de los Condilomas Anales',
            content: [
              'En muchos casos, la exploración visual es suficiente para diagnosticar los condilomas.',
              'En casos donde las lesiones son más planas o no visibles a simple vista, se puede emplear ácido acético para hacerlas evidentes.',
              'Es fundamental realizar una rectoscopia para evaluar si hay lesiones internas en el conducto anal, ya que esto influye en la elección del tratamiento.'
            ],
          },
          {
            titleSection: 'Tratamientos para los Condilomas Anales',
            content: [
              'El tratamiento varía según la extensión y el grado de lesión, incluyendo opciones como:',
              '- Crioterapia con nitrógeno líquido.',
              '- Podofilina al 25%.',
              '- Resección quirúrgica y electrocoagulación.',
              '- Aplicación tópica de Imiquimod.',
              'Es importante tratar los condilomas de manera oportuna, ya que las infecciones prolongadas pueden aumentar el riesgo de desarrollar cáncer anal.',
              'En pacientes con SIDA, las lesiones tienden a ser más extensas, recurrentes y de mayor tamaño.'
            ],
          },
        ],
      },
      Rectocele: {
        title: 'Rectocele: Causas, Síntomas y Tratamientos',
        description: 'El rectocele es una herniación del recto hacia la vagina debido a una debilidad en el tabique recto vaginal. Aprende sobre sus causas, diagnóstico y opciones de tratamiento.',
        keywords: 'rectocele, hernia recto vaginal, prolapso pélvico, tratamiento de rectocele, diagnóstico de rectocele',
        canonical: 'https://unidad-gastroenterologia.com/rectocele',
        link: '/rectocele',
        className: 'enfermedades-pelvicas-page',
        image: 'https://unidad-gastroenterologia.com/images/rectocele.jpg',
        sections: [
          {
            titleSection: '¿Qué es el Rectocele?',
            content: [
              'El rectocele es una herniación del recto hacia la vagina causada por una debilidad o defecto en el tabique recto vaginal.',
              'Es una condición común que afecta principalmente a mujeres, especialmente aquellas con antecedentes de partos complicados.'
            ],
          },
          {
            titleSection: 'Causas del Rectocele',
            content: [
              'El rectocele puede dividirse en dos tipos principales:',
              '- **Rectoceles Anteriores**: Frecuentes en la parte inferior y media de la vagina, generalmente causados por:',
              '  - Traumatismos obstétricos.',
              '  - Defectos superiores asociados a prolapso uterino.',
              '- **Rectoceles Posteriores**: Causados por:',
              '  - Esfuerzo excesivo y prolongado durante la defecación.',
              '  - Separación de los músculos elevadores del ano, lo que conduce a una hernia perineal posterior.'
            ],
          },
          {
            titleSection: 'Clasificación del Rectocele',
            content: [
              '- **Tipo 1**: Dificultad crónica para evacuar sin prolapso pélvico orgánico.',
              '- **Tipo 2**: Presencia de prolapso pélvico orgánico, generalmente más severo.'
            ],
          },
          {
            titleSection: 'Factores Predisponentes',
            content: [
              'Algunos factores que aumentan el riesgo de rectocele incluyen:',
              '- Trabajos de parto prolongados.',
              '- Productos grandes (bebés de peso elevado).',
              '- Uso de fórceps durante el parto.',
              '- Multiparidad (múltiples partos).',
              '- Involución climatérica.',
              '- Trabajo físico intenso.',
              '- Bronquitis crónica.',
              '- Sobrepeso u obesidad.'
            ],
          },
          {
            titleSection: 'Síntomas del Rectocele',
            content: [
              'Los síntomas principales incluyen:',
              '- Dificultad para evacuar, causada por la proyección de la materia fecal hacia la pared vaginal.',
              '- Necesidad de introducir los dedos en la vagina o región perineal para facilitar la evacuación.',
              '- Sensación constante de ocupación en el recto.',
              '- Esfuerzo excesivo para evacuar.',
              '- Sensación de evacuación incompleta.',
              '- Dolor y presión vaginal.',
              '- Hemorragia.',
              '- Incontinencia urinaria.',
              '- Dolor durante las relaciones sexuales (dispareunia).'
            ],
          },
          {
            titleSection: 'Diagnóstico del Rectocele',
            content: [
              'El diagnóstico incluye:',
              '- Colocar al paciente en posición ginecológica y solicitar que puje, lo que revela el rectocele.',
              '- Realización de una defecografía para evaluar la longitud y tamaño del rectocele, esencial para planificar el tratamiento quirúrgico.'
            ],
          },
          {
            titleSection: 'Tratamientos para el Rectocele',
            content: [
              'El tratamiento varía según la severidad de los síntomas y el tamaño del defecto:',
              '- **Defectos pequeños y sintomáticos**:',
              '  - Medidas higiénico-dietéticas.',
              '  - Ejercicios perineales.',
              '  - Retroalimentación biológica (biofeedback).',
              '  - Toxina botulínica en casos seleccionados.',
              '- **Defectos mayores a 3 cm o síntomas significativos**:',
              '  - Reparación quirúrgica del defecto.',
              '  - Reforzamiento del tabique recto vaginal.',
              '  - Plastia anal con levatoroplastia para mejores resultados.'
            ],
          },
        ],
      },
      ProlapsoRectal: {
        title: 'Prolapso Rectal: Causas, Síntomas y Tratamientos',
        description: 'El prolapso rectal es la exteriorización del recto a través del ano, que afecta significativamente la calidad de vida. Aprende sobre sus causas, síntomas, clasificación y opciones de tratamiento.',
        keywords: 'prolapso rectal, incontinencia fecal, cirugía prolapso rectal, síntomas prolapso rectal, tratamiento prolapso rectal',
        canonical: 'https://unidad-gastroenterologia.com/prolapso-rectal',
        link: '/prolapso-rectal',
        className: 'enfermedades-anorrectales-page',
        image: 'https://unidad-gastroenterologia.com/images/prolapso-rectal.jpg',
        sections: [
          {
            titleSection: '¿Qué es el Prolapso Rectal?',
            content: [
              'El prolapso rectal es la salida o exteriorización del recto a través del ano, exponiendo la mucosa rectal al medio ambiente.',
              'Aunque es fácil de diagnosticar a simple vista, puede causar serias complicaciones funcionales que disminuyen significativamente la calidad de vida de los pacientes.'
            ],
          },
          {
            titleSection: 'Síntomas del Prolapso Rectal',
            content: [
              'El prolapso rectal puede manifestarse con los siguientes síntomas:',
              '- Incontinencia fecal progresiva.',
              '- Dolor pélvico.',
              '- Tenesmo rectal continuo.',
              '- Evacuaciones en moronas.',
              '- Sangrado rectal fresco.',
              '- Secreción mucosa constante.'
            ],
          },
          {
            titleSection: 'Clasificación del Prolapso Rectal',
            content: [
              '- **Prolapso Rectal Incompleto**: Solo afecta la mucosa rectal.',
              '- **Prolapso Rectal Completo**: Involucra todas las capas de la pared rectal:',
              '  - **B.I. Prolapso Oculto o Intususcepción**.',
              '  - **B.II. Protrusión del Recto al Pujar**.',
              '  - **B.III. Prolapso Rectal Externo Visible**.'
            ],
          },
          {
            titleSection: 'Epidemiología',
            content: [
              'El prolapso rectal es más común en mujeres de edad avanzada, especialmente mayores de 65 años, y suele estar asociado a multiparidad.'
            ],
          },
          {
            titleSection: 'Factores Predisponentes',
            content: [
              'Los factores de riesgo incluyen:',
              '- Demencia senil.',
              '- Esfuerzo excesivo al defecar.',
              '- Infecciones parasitarias (esquistosomiasis, tricocefalosis, amebiasis).',
              '- Enfermedades neurológicas como espina bífida.',
              '- Multiparidad.',
              '- Histerectomía.',
              '- Estreñimiento crónico.',
              '- Hipermotilidad del colon sigmoideo.'
            ],
          },
          {
            titleSection: 'Manifestaciones Clínicas',
            content: [
              'Los pacientes con prolapso rectal pueden experimentar:',
              '- Sensación de una masa que sobresale por el ano al defecar.',
              '- Mayor esfuerzo para prolapsar el recto, incluso al toser o estornudar.',
              '- Ulceraciones en la mucosa expuesta, con sangrado, inflamación y secreción de moco.',
              '- Maceración y prurito anal debido a irritación en la piel alrededor del prolapso.',
              '- Sensación de evacuación incompleta.',
              '- Incontinencia fecal, aunque en menor porcentaje puede presentarse estreñimiento.'
            ],
          },
          {
            titleSection: 'Diagnóstico del Prolapso Rectal',
            content: [
              'El diagnóstico se realiza mediante:',
              '- Exploración física, pidiendo al paciente que puje para evidenciar el prolapso.',
              '- Rectosigmoidoscopia para buscar úlceras rectales.',
              '- Colonoscopia y colon por enema para descartar cáncer de colon y planificar el tratamiento.'
            ],
          },
          {
            titleSection: 'Tratamiento del Prolapso Rectal',
            content: [
              'El tratamiento es quirúrgico y depende de:',
              '- Edad y estado general del paciente.',
              '- Presencia de estreñimiento.',
              '- Diámetro del colon sigmoideo.',
              '- Expectativas del paciente.',
              'Existen dos tipos principales de cirugía:',
              '- **Procedimientos Abdominales**.',
              '- **Procedimientos Perineales**.',
              'La elección del enfoque quirúrgico se basa en la gravedad de la condición y las características individuales del paciente.'
            ],
          },
        ],
      },
      QuistePilonidal: {
        title: 'Quiste Pilonidal: Causas, Síntomas y Tratamientos',
        description: 'El quiste pilonidal es una afección de la región sacrocoxígea asociada a inflamación e infección recurrente. Aprende sobre sus causas, síntomas y tratamientos.',
        keywords: 'quiste pilonidal, enfermedad pilonidal, tratamiento quirúrgico, síntomas quiste pilonidal, fase aguda, fase crónica',
        canonical: 'https://unidad-gastroenterologia.com/quiste-pilonidal',
        link: '/quiste-pilonidal',
        className: 'enfermedades-pilosebaceas-page',
        image: 'https://unidad-gastroenterologia.com/images/quiste-pilonidal.jpg',
        sections: [
          {
            titleSection: '¿Qué es el Quiste Pilonidal?',
            content: [
              'El quiste pilonidal, también conocido como enfermedad pilonidal, es la formación de una cavidad o seno en la región sacrocoxígea.',
              'Este quiste a menudo contiene uno o varios pelos y se asocia con una reacción inflamatoria que puede derivar en infección.'
            ],
          },
          {
            titleSection: '¿Cuándo se Presenta?',
            content: [
              'El quiste pilonidal puede aparecer en cualquier momento, pero es más común entre los 15 y 30 años de edad.',
              'Es más frecuente en varones debido a su mayor densidad de vello corporal, aunque también puede ocurrir en mujeres.'
            ],
          },
          {
            titleSection: '¿Cómo se Origina?',
            content: [
              'Aunque la causa exacta no se conoce, existen dos teorías principales:',
              '- **Teoría de la infección primaria**: Una infección en la piel del pliegue interglúteo genera un seno que es penetrado por pelo.',
              '- **Teoría de los folículos pilosos**: Los folículos se distienden con queratina, son colonizados por bacterias, y desarrollan foliculitis y abscesos que se extienden al tejido subcutáneo.'
            ],
          },
          {
            titleSection: 'Degeneración Maligna del Quiste Pilonidal',
            content: [
              'La degeneración maligna del quiste pilonidal es extremadamente rara y puede evolucionar a carcinoma escamoso bien diferenciado.',
              'El tratamiento consiste en resección quirúrgica amplia y radioterapia coadyuvante.'
            ],
          },
          {
            titleSection: 'Signos y Síntomas',
            content: [
              'El quiste pilonidal puede presentarse en dos fases:',
              '- **Fase Aguda**: Tumoración dolorosa en la región sacrocoxígea, con o sin drenaje purulento. Puede estar asociada a:',
              '  - Dolor intenso.',
              '  - Hipersensibilidad local.',
              '  - Aumento de temperatura.',
              '  - Escalofríos e hipertermia.',
              '- **Fase Crónica**: Drenaje espontáneo de material seroso o seropurulento, recurrente y continuo, que mancha la ropa interior. En esta fase se pueden observar:',
              '  - Orificios secundarios con mechones de pelo visibles.',
              '  - Drenaje de material purulento.',
              '  - Aumento de volumen y temperaturas locales.'
            ],
          },
          {
            titleSection: 'Tratamiento del Quiste Pilonidal',
            content: [
              '- **Fase Aguda**: Drenaje quirúrgico con anestesia local. Se realizan lavados frecuentes para favorecer el drenaje del absceso.',
              '- **Fase Crónica**: Tratamiento quirúrgico definitivo. Existen múltiples técnicas quirúrgicas que se adaptan a cada paciente.',
              'El uso de antibióticos no es rutinario y solo se recomienda en casos específicos.'
            ],
          },
          {
            titleSection: 'Cuidados Posoperatorios',
            content: [
              'El cuidado posoperatorio es fundamental para prevenir complicaciones y asegurar una buena cicatrización:',
              '- Irrigación y limpieza constante de la herida.',
              '- Eliminación de cualquier pelo próximo a la herida.',
              '- Rasurado regular de la piel circundante.',
              'Las visitas regulares al médico son esenciales para el seguimiento adecuado.'
            ],
          },
          {
            titleSection: 'Complicaciones',
            content: [
              'Las complicaciones más comunes incluyen:',
              '- Infecciones secundarias.',
              '- Formación de hematomas.',
              '- Dehiscencia de la herida, lo que puede retrasar la cicatrización.',
              'La complicación más temida es la recidiva de la enfermedad, que es más frecuente en técnicas quirúrgicas cerradas.'
            ],
          },
        ],
      },
      
      

    },
    EnfermedadesDePancreas: {
      PancreatitisAguda: {
        title: 'Pancreatitis Aguda: Causas, Diagnóstico y Tratamiento',
        description: 'La pancreatitis aguda es una inflamación del páncreas caracterizada por dolor abdominal severo y niveles elevados de enzimas pancreáticas. Aprende sobre sus causas, síntomas y manejo.',
        keywords: 'pancreatitis aguda, inflamación páncreas, tratamiento pancreatitis, criterios de Ranson, dolor abdominal, diagnóstico pancreatitis',
        canonical: 'https://unidad-gastroenterologia.com/pancreatitis',
        link: '/pancreatitis',
        className: 'enfermedades-pancreas-page',
        image: 'https://unidad-gastroenterologia.com/images/enfermedades/pancreatitis.webp',
        author: 'Dr. Juan Antonio Sánchez Valle',
        sections: [
          {
            titleSection: '¿Qué es la Pancreatitis Aguda?',
            content: [
              'La pancreatitis aguda es un trastorno inflamatorio del páncreas caracterizado por:',
              '- Dolor abdominal severo.',
              '- Niveles elevados de enzimas pancreáticas como amilasa y lipasa.',
              'La enfermedad puede presentarse de forma leve y autolimitada o progresar a una forma fulminante con riesgo de muerte.'
            ],
          },
          {
            titleSection: 'Causas de la Pancreatitis Aguda',
            content: [
              'Más del 80% de los casos se deben a:',
              '- **Alcoholismo**: Efectos tóxicos directos en las glándulas pancreáticas y formación de tapones proteicos.',
              '- **Litiasis vesicular**: Migración de cálculos o lodo biliar que obstruyen parcialmente el conducto pancreático principal.',
              'Otras causas incluyen:',
              '- Fármacos, infecciones, traumatismos, hiperlipemia, hipoparatiroidismo, cirugías y colangiografía retrógrada endoscópica.',
              '- Picaduras de escorpión y trastornos alimenticios como anorexia y bulimia.'
            ],
          },
          {
            titleSection: 'Síntomas de la Pancreatitis Aguda',
            content: [
              'La pancreatitis aguda se manifiesta principalmente por:',
              '- Dolor abdominal súbito e intenso en la parte alta del abdomen, que puede irradiarse hacia la espalda.',
              '- Dolor en forma de cinturón alrededor del abdomen.',
              '- Náuseas, vómitos, fiebre y taquicardia.'
            ],
          },
          {
            titleSection: 'Diagnóstico Diferencial',
            content: [
              'La pancreatitis aguda puede confundirse con otras condiciones como:',
              '- Colecistitis no complicada.',
              '- Enfermedad ulcerosa péptica exacerbada.',
              '- Infarto esplénico o intestinal.',
              '- Embarazo ectópico.',
              'Un aumento de tres a cuatro veces los valores normales de amilasa y lipasa es altamente indicativo de pancreatitis aguda.'
            ],
          },
          {
            titleSection: 'Estudios de Imagen para Pancreatitis',
            content: [
              'Los estudios recomendados incluyen:',
              '- **Placa de tórax y abdomen**: Pueden revelar elevación del diafragma, derrame pleural y atelectasias.',
              '- **Ecografía abdominal**: Procedimiento inicial para detectar cálculos biliares y evaluar el páncreas.',
              '- **Tomografía computarizada**: Considerada el estándar de oro, muestra el grado de afectación pancreática, necrosis y colecciones líquidas peripancreáticas.'
            ],
            image: 'https://unidad-gastroenterologia.com/images/enfermedades/estudios-pancreatitis.jpeg',
          },
          {
            titleSection: 'Tratamiento de la Pancreatitis Aguda',
            content: [
              'El manejo incluye:',
              '- Control del dolor con analgésicos.',
              '- Reposo funcional del páncreas (ayuno).',
              '- Corrección de desequilibrios hidroelectrolíticos.',
              '- Uso de antibióticos en casos graves o con complicaciones infecciosas.',
              'En casos graves, se puede requerir:',
              '- Nutrición parenteral o alimentación por sonda nasoyeyunal.',
              '- Colecistectomía electiva en pacientes con pancreatitis litiásica no complicada.'
            ],
            // image: 'https://unidad-gastroenterologia.com/images/enfermedades/tratamiento-pancreatitis.jpeg',
          },
          {
            titleSection: 'Criterios de Ranson para el Pronóstico',
            content: [
              'Los criterios de Ranson ayudan a evaluar el pronóstico de la pancreatitis aguda:',
              '- **Al ingreso hospitalario:**',
              '  - Edad >55 años.',
              '  - Leucocitos >16,000 cel./mm³.',
              '  - LDH >350 UI/L.',
              '  - AST >250 UI/L.',
              '- **Durante las primeras 48 horas:**',
              '  - Disminución del hematocrito >10%.',
              '  - Aumento de nitrógeno ureico en sangre >5 mg/dL.',
              '  - Calcio <8 mg/dL.',
              '  - PO2 <60 mmHg.',
              '  - Secuestro de líquidos >6 L.'
            ],
          },
          {
            titleSection: 'Evolución de la Pancreatitis Aguda',
            content: [
              'En el 20% de los casos, la pancreatitis aguda es grave desde el inicio. En el resto, la recuperación suele ocurrir en 4 a 7 días.',
              'La tasa de mortalidad oscila entre el 5% y 10%, dependiendo de la gravedad y complicaciones asociadas.'
            ],
          },
        ],
      },
      
    },
    EnfermedadesReflujoHernia: {
      TratamientoRGE: {
        title: 'Tratamiento del Reflujo Gastroesofágico (RGE)',
        description: 'Información sobre las principales opciones de tratamiento para el Reflujo Gastroesofágico (RGE), incluyendo cambios en el estilo de vida, tratamientos farmacológicos y procedimientos endoscópicos o quirúrgicos.',
        keywords: 'tratamiento de reflujo gastroesofágico, cambios en el estilo de vida, medicamentos para RGE, funduplicatura laparoscópica, radiofrecuencia de Stretta, antiácidos, inhibidores de la bomba de protones',
        canonical: 'https://unidad-gastroenterologia.com/tratamiento-reflujo-gastroesofagico',
        link: '/tratamiento-reflujo-gastroesofagico',
        className: 'publicaciones-page',
        image: 'https://unidad-gastroenterologia.com/static/images/gastro_11.jpg',
        author: 'Dr. Juan Antonio Sánchez Valle',
        sections: [
            {
                titleSection: 'Cambios en el Estilo de Vida para el Tratamiento del RGE',
                content: [
                    'Los cambios en el estilo de vida son fundamentales para el manejo del RGE e incluyen:',
                    'Modificación de la dieta: Evitar alimentos que desencadenen síntomas, como comidas picantes, grasas, chocolate, cafeína, alcohol y cítricos.',
                    'Comidas pequeñas y frecuentes: Optar por porciones menores para reducir la presión en el estómago.',
                    'Evitar acostarse después de comer: Esperar al menos 2-3 horas antes de acostarse para permitir la digestión.',
                    'Elevar la cabecera de la cama: Dormir con la cabecera elevada puede ayudar a reducir el reflujo nocturno.',
                    'Control del peso: La pérdida de peso en personas con sobrepeso puede ayudar a mantener el reflujo bajo control.'
                ],
                image: 'https://unidad-gastroenterologia.com/static/images/gastro_11.jpg',
            },
            {
                titleSection: 'Tratamientos Farmacológicos para el RGE',
                content: [
                    'Existen varios tipos de medicamentos para tratar el RGE, que incluyen:',
                    'Antiacidos: Neutralizan el ácido del estómago y proporcionan alivio inmediato de los síntomas. Ejemplos incluyen hidróxido de magnesio y hidróxido de aluminio.',
                    'Inhibidores de la bomba de protones (IBP): Reducen la producción de ácido en el estómago. Los ejemplos incluyen omeprazol, esomeprazol y lansoprazol. Son efectivos para el tratamiento a largo plazo del RGE.',
                    'Procinéticos: Estos medicamentos ayudan a que el estómago se vacíe más rápido y reducen el reflujo.'
                ],
            },
            {
                titleSection: 'Procedimientos Endoscópicos y Quirúrgicos',
                content: [
                    'En pacientes con RGE persistente, pueden considerarse procedimientos endoscópicos o quirúrgicos:',
                    'Radiofrecuencia de Stretta: Un procedimiento endoscópico que utiliza energía de radiofrecuencia para tratar el reflujo.',
                    'Funduplicatura Laparoscópica: Procedimiento quirúrgico para reforzar la válvula esofágica y prevenir el reflujo.'
                ],
            }
        ],
      },
      FunduplicaturaLaparoscopica: {
        title: 'Funduplicatura Laparoscópica para el Tratamiento del Reflujo Gastroesofágico',
        description: 'La funduplicatura laparoscópica es un procedimiento quirúrgico utilizado para tratar el reflujo gastroesofágico (RGE) en pacientes que no responden a tratamientos farmacológicos y cambios en el estilo de vida.',
        keywords: 'funduplicatura laparoscópica, tratamiento quirúrgico RGE, cirugía de reflujo gastroesofágico, funduplicatura, esófago de Barrett, hernia hiatal',
        canonical: 'https://unidad-gastroenterologia.com/funduplicatura-laparoscopica',
        link: '/funduplicatura-laparoscopica',
        className: 'publicaciones-page',
        image: 'https://unidad-gastroenterologia.com/static/images/gastro_12.jpg',
        author: 'Dr. Juan Antonio Sánchez Valle',
        sections: [
            {
                titleSection: '¿Qué es la Funduplicatura Laparoscópica?',
                content: [
                    'La funduplicatura laparoscópica es un procedimiento quirúrgico realizado para tratar el reflujo gastroesofágico (RGE) severo, especialmente en pacientes cuya condición no mejora con medicamentos o cambios en el estilo de vida.',
                    'Durante la cirugía, el extremo superior del estómago (fundus) se envuelve alrededor del esófago inferior para reforzar el esfínter esofágico inferior, evitando así el reflujo del contenido gástrico hacia el esófago.'
                ],
            },
            {
                titleSection: 'Indicaciones para la Funduplicatura Laparoscópica',
                content: [
                    'La funduplicatura laparoscópica se recomienda en los siguientes casos:',
                    'RGE persistente a pesar de tratamiento farmacológico adecuado.',
                    'Complicaciones asociadas al RGE, como el esófago de Barrett o la esofagitis severa.',
                    'Pacientes con hernia hiatal que no responden a otros tratamientos.',
                    'Falta de respuesta a cambios en el estilo de vida.'
                ],
            },
            {
                titleSection: 'Procedimiento Quirúrgico',
                content: [
                    'La funduplicatura laparoscópica se realiza bajo anestesia general y requiere de pequeñas incisiones en el abdomen para introducir los instrumentos quirúrgicos.',
                    'Durante el procedimiento, el cirujano envuelve el fundus del estómago alrededor del esófago inferior y lo sutura para crear una válvula que impida el paso del ácido gástrico hacia el esófago.',
                    'El procedimiento se realiza de forma mínimamente invasiva, lo que permite una recuperación más rápida y menos dolor postoperatorio en comparación con la cirugía abierta.'
                ],
            },
            {
                titleSection: 'Beneficios de la Funduplicatura Laparoscópica',
                content: [
                    'Los principales beneficios de la funduplicatura laparoscópica incluyen:',
                    'Reducción o eliminación de los síntomas de reflujo gastroesofágico, como la acidez y la regurgitación.',
                    'Mejora en la calidad de vida, al evitar la necesidad de tomar medicamentos a largo plazo.',
                    'Recuperación más rápida debido a su naturaleza mínimamente invasiva.',
                    'Menor riesgo de complicaciones en comparación con la cirugía abierta.'
                ],
            },
            {
                titleSection: 'Riesgos y Consideraciones',
                content: [
                    'Aunque la funduplicatura laparoscópica tiene una tasa de éxito alta, existen riesgos y posibles complicaciones, como:',
                    'Dificultades para tragar (disfagia), especialmente en los primeros meses después de la cirugía.',
                    'Lesión de órganos cercanos, como el bazo o los vasos sanguíneos.',
                    'Síntomas persistentes de reflujo o regreso del RGE.',
                    'Es necesario un seguimiento postoperatorio adecuado para monitorear la recuperación y la efectividad del tratamiento.'
                ],
            },
            {
                titleSection: 'Recuperación y Cuidados Postoperatorios',
                content: [
                    'La mayoría de los pacientes puede regresar a casa en uno o dos días después de la cirugía.',
                    'Se recomienda seguir una dieta líquida o blanda durante las primeras semanas postoperatorias, evitando alimentos que puedan irritar el esófago.',
                    'Los pacientes deben evitar actividades físicas intensas durante al menos 4-6 semanas para permitir una correcta cicatrización.',
                    'En general, la cirugía mejora significativamente la calidad de vida de los pacientes, pero es importante adherirse a las recomendaciones médicas para garantizar una recuperación exitosa.'
                ],
            }
          ],
      },
      RadiofrecuenciaStretta: {
        title: 'Tratamiento con Radiofrecuencia de Stretta para el Reflujo Gastroesofágico (RGE)',
        description: 'La Radiofrecuencia de Stretta es un tratamiento innovador y efectivo para pacientes con reflujo gastroesofágico (RGE) persistente. En la Unidad de Gastroenterología, ofrecemos este procedimiento mínimamente invasivo para mejorar la calidad de vida de nuestros pacientes.',
        keywords: 'radiofrecuencia de Stretta, tratamiento de reflujo gastroesofágico, RGE, esófago de Barrett, tratamiento mínimamente invasivo, Unidad de Gastroenterología',
        canonical: 'https://unidad-gastroenterologia.com/radiofrecuencia-stretta',
        link: '/radiofrecuencia-stretta',
        className: 'publicaciones-page',
        image: 'https://unidad-gastroenterologia.com/static/images/gastro_13.jpg',
        author: 'Dr. Juan Antonio Sánchez Valle',
        sections: [
            {
                titleSection: '¿Qué es la Radiofrecuencia de Stretta?',
                content: [
                    'La radiofrecuencia de Stretta es un procedimiento mínimamente invasivo utilizado para tratar el reflujo gastroesofágico (RGE) persistente, especialmente en pacientes que no responden a los tratamientos farmacológicos convencionales.',
                    'Este tratamiento consiste en aplicar energía de radiofrecuencia en el esfínter esofágico inferior y en las partes del esófago afectadas por el ácido gástrico, lo que ayuda a fortalecer el músculo del esfínter y a reducir la frecuencia y gravedad de los episodios de reflujo.'
                ],
            },
            {
                titleSection: 'Beneficios de la Radiofrecuencia de Stretta',
                content: [
                    'Los beneficios de la radiofrecuencia de Stretta incluyen:',
                    'Mejora significativa en los síntomas de reflujo gastroesofágico, como la acidez estomacal y la regurgitación.',
                    'Reducción de la dependencia de los medicamentos, evitando los efectos secundarios a largo plazo.',
                    'Procedimiento ambulatorio, que no requiere hospitalización.',
                    'Recuperación rápida, con mínimas molestias postoperatorias.',
                ],
            },
            {
                titleSection: '¿Cuándo Considerar la Radiofrecuencia de Stretta?',
                content: [
                    'La radiofrecuencia de Stretta puede ser considerada en los siguientes casos:',
                    'Pacientes con reflujo gastroesofágico crónico que no han tenido una respuesta adecuada a medicamentos o cambios en el estilo de vida.',
                    'Pacientes con esófago de Barrett o esofagitis que requieren un tratamiento adicional.',
                    'Pacientes que desean evitar procedimientos quirúrgicos invasivos como la funduplicatura laparoscópica.',
                    'En la Unidad de Gastroenterología, evaluamos a cada paciente de manera integral para determinar si este tratamiento es adecuado para su caso.'
                ],
            },
            {
                titleSection: 'El Procedimiento de Radiofrecuencia de Stretta',
                content: [
                    'El tratamiento con radiofrecuencia de Stretta se realiza bajo anestesia local y sedación leve. A través de una endoscopia, se introducen pequeños electrodos en el esófago, donde se aplica la energía de radiofrecuencia para fortalecer el esfínter esofágico inferior.',
                    'El procedimiento generalmente dura menos de una hora y no requiere incisiones, lo que lo convierte en una opción mínimamente invasiva y cómoda para los pacientes.',
                ],
            },
            {
                titleSection: 'Recuperación y Cuidados Postoperatorios',
                content: [
                    'La mayoría de los pacientes pueden reanudar sus actividades diarias en un corto período de tiempo, usualmente dentro de 1 a 2 días.',
                    'Se recomienda seguir una dieta blanda durante los primeros días y evitar alimentos irritantes para asegurar una recuperación óptima.',
                    'En nuestra Unidad de Gastroenterología, brindamos un seguimiento continuo postoperatorio para asegurarnos de que el tratamiento esté teniendo los efectos deseados y para resolver cualquier inquietud que el paciente pueda tener.'
                ],
            },
            {
                titleSection: 'Consulta y Evaluación',
                content: [
                    'Si sufres de reflujo gastroesofágico persistente y estás buscando una solución eficaz y menos invasiva, la radiofrecuencia de Stretta puede ser la opción adecuada para ti.',
                    'En la Unidad de Gastroenterología, te ofrecemos una evaluación personalizada para determinar si este tratamiento es adecuado para tu caso. Nuestro equipo de especialistas está comprometido en brindarte la mejor atención y ofrecerte un tratamiento que se ajuste a tus necesidades.',
                ],
            }
        ],
    },
    
  

    
    },
    Publicaciones: {
        DiagnosticoClasificacionERGE: {
            title: 'Diagnóstico y Clasificación de la Enfermedad por Reflujo Gastro-Esofágico (ERGE)',
            description: 'Información detallada sobre el diagnóstico y las clasificaciones de la Enfermedad por Reflujo Gastro-Esofágico, incluyendo los sistemas Los Ángeles y Savary-Miller.',
            keywords: 'ERGE, reflujo gastroesofágico, clasificación de Los Ángeles, Savary-Miller, esofagitis',
            canonical: 'https://unidad-gastroenterologia.com/grado-esofagitis',
            link: '/grado-esofagitis',
            className: 'publicaciones-page',
            image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            author: 'Dr. Juan Antonio Sánchez Valle',
            sections: [
                {
                    titleSection: 'Diagnóstico y Clasificación de la Enfermedad por Reflujo Gastro-Esofágico (ERGE)',
                    content: [
                        'Los pacientes con esofagitis por reflujo presentan cambios endoscópicos e histopatológicos por lesión e inflamación de la mucosa del esófago.',
                        'Los hallazgos típicos de la esofagitis por reflujo en la endoscopia determinan un diagnóstico de ERGE con una especificidad del 90% a 95%.',
                        'Al menos el 50% de los pacientes con síntomas de reflujo tienen resultados normales en la endoscopia, lo que se denomina ERNE o Enfermedad por Reflujo No Erosiva.',
                        'Se recomienda iniciar tratamiento empírico antiácido en los pacientes con síntomas típicos de ERGE.'
                    ],
                    image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
                },
                {
                    titleSection: 'Clasificación de Los Ángeles para ERGE',
                    content: [
                        'Grado A. Una o más lesiones de la mucosa menores a 5 Mm. que no se extiende a la parte superior de dos pliegues de la mucosa.',
                        'Grado B. Una o más lesiones de la mucosa mayores a 5 Mm. que no presentan continuidad entre la parte superior de dos pliegues de la mucosa.',
                        'Grado C. Uno o más lesiones de la mucosa que presenta continuidad entre la parte superior de dos pliegues de la mucosa, pero que implica menos del 75% de la circunferencia.',
                        'Grado D. Una o más lesiones de la mucosa que involucra al menos el 75% de la circunferencia del esófago.'
                    ],
                },
                {
                    titleSection: 'Clasificación modificada Savary-Miller para ERGE',
                    content: [
                        'Grado I. Lesión erosiva individual o aislada, oval o lineal, que afecta un solo pliegue longitudinal.',
                        'Grado II. Múltiples lesiones erosivas, no circunferenciales que afectan a más de un pliegue longitudinal, con o sin confluencia.',
                        'Grado III. Lesión circunferencial erosiva.',
                        'Grado IV. Lesiones crónicas incluyendo úlcera, estenosis, esófago corto, solas o asociadas con lesiones de grados I a III.',
                        'Grado V. Epitelio columnar en continuidad con la línea Z, no circulares, en forma de estrella, o circunferenciales, solas o asociadas con lesiones grados I a IV.'
                    ],
                },
                {
                    titleSection: 'Histopatología y Seguimiento',
                    content: [
                        'Se recomienda a los endoscopistas describir el grado de alteraciones endoscópicas mediante un sistema de clasificación aceptado o una descripción detallada.',
                        'Es importante obtener muestras de biopsia en pacientes con características sospechosas, como ulceraciones irregulares, masas, o sospecha de esófago de Barrett.',
                        'El seguimiento endoscópico está reservado para pacientes con síntomas persistentes, esofagitis grave, úlceras o para excluir el esófago de Barrett tras tratamiento médico.'
                    ],
                }
            ],
        },
        EstreñimientoColonIrritableAdultoMayor: {
            title: 'Estreñimiento y Colon Irritable en el Adulto Mayor',
            description: 'El estreñimiento y el síndrome de colon irritable son condiciones comunes en los adultos mayores, impactando su calidad de vida y causando múltiples complicaciones.',
            keywords: 'estreñimiento, colon irritable, adulto mayor, laxantes, síndrome de intestino irritable, tratamiento estreñimiento, colon irritable ancianos',
            canonical: 'https://unidad-gastroenterologia.com/estreñimiento-colon-irritable-adulto-mayor',
            link: '/estrenimiento',
            className: 'estreñimiento-colon-irritable-adulto-mayor',
            image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            author: 'Dr. Juan Antonio Sánchez Valle',
            sections: [
              {
                titleSection: 'ESTREÑIMIENTO Y COLON IRRITABLE EN EL ADULTO MAYOR',
                content: [
                  'El estreñimiento ha sido un problema de salud de larga data. El primer reporte sobre su tratamiento data de un faraón egipcio que ordenó a su médico curarlo o ser condenado a prisión. Tras observar una estatua, el médico ideó el primer enema, lo que resultó exitoso y el faraón le dio el título de "Guardián de la Evacuación Real".',
                ],
                image: 'https://unidad-gastroenterologia.com/images/enfermedades/laxantes/colon_irritable.jpg',
              },
              {
                titleSection: 'Criterios Diagnósticos de Estreñimiento',
                content: [
                  'El diagnóstico de estreñimiento se basa en los Criterios de Roma, que incluyen evacuaciones forzadas, evacuaciones duras, sensación de evacuación incompleta y dos o menos evacuaciones por semana.',
                ],
              },
              {
                titleSection: 'Epidemiología y Causas del Estreñimiento en Adultos Mayores',
                content: [
                  'Al menos una de cada cuatro personas mayores experimenta estreñimiento según su percepción. Este número disminuye a un 17% cuando se usan los Criterios de Roma. Los factores contribuyentes incluyen inmovilidad, deshidratación, anorexia, uso frecuente de laxantes y enfermedades como la Diabetes y la Enfermedad de Parkinson.',
                ],
              },
              {
                titleSection: 'Impacto del Estreñimiento en la Calidad de Vida',
                content: [
                  'El estreñimiento en los adultos mayores está asociado con una disminución de la calidad de vida, mayor dolor, disuria y en muchos casos, incontinencia fecal.',
                ],
              },
              {
                titleSection: 'Manejo del Estreñimiento',
                content: [
                  'El tratamiento básico incluye una adecuada ingesta de líquidos y fibra, ejercicio regular y la recomendación de ir al baño 15 a 30 minutos después de las comidas. El uso de laxantes debe ser limitado debido a sus efectos secundarios. Los laxantes osmóticos, enemas y medicamentos como tegaserot y lubiprostone son opciones a considerar según el paciente.',
                ],
              },
              {
                titleSection: 'Síndrome de Colon Irritable en Adultos Mayores',
                content: [
                  'El síndrome de intestino irritable afecta a un 10% de la población adulta. En los adultos mayores, se asocia con una disminución de la calidad de vida y más visitas médicas. Los síntomas comunes incluyen estreñimiento, diarrea, dolor y distensión abdominal.',
                ],
              },
              {
                titleSection: 'Criterios de Diagnóstico para el Síndrome de Colon Irritable',
                content: [
                  'Los Criterios de Roma II para el síndrome de intestino irritable incluyen dolor abdominal, cambios en la frecuencia y consistencia de las evacuaciones, y distensión abdominal. Los datos de alarma incluyen anemia, fiebre persistente, y antecedentes familiares de cáncer de colon.',
                ],
              },
              {
                titleSection: 'Tratamiento del Síndrome de Colon Irritable',
                content: [
                  'El tratamiento incluye modificaciones dietéticas, uso de medicamentos específicos y manejo del estrés. En adultos mayores, el tratamiento debe ser personalizado para mejorar la calidad de vida sin causar efectos secundarios adicionales.',
                ],
              },
            ],
        },
        
        HelicobacterPyloriDiagnosticoManejo: {
          title: 'Helicobacter Pylori: Diagnóstico y Manejo',
          description: 'Explora el diagnóstico, prevalencia y manejo de la infección por Helicobacter pylori, una de las infecciones más comunes en humanos.',
          keywords: 'Helicobacter pylori, úlcera gástrica, diagnóstico, tratamiento, gastritis, cáncer gástrico',
          canonical: 'https://unidad-gastroenterologia.com/helicobacter-pylori-diagnostico-manejo',
          link: '/helicobacter',
          className: 'gastroenterologia-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'HELICOBACTER PYLORI: DIAGNÓSTICO Y MANEJO',
              content: [
                '',
              ],
              image: 'https://unidad-gastroenterologia.com/images/enfermedades/helicobacter-pylori.webp',
              },
            {
              titleSection: 'Historia de la Enfermedad Ulcerosa Péptica',
              content: [
                'En los inicios del siglo XX, la enfermedad ulcerosa péptica se creía causada por estrés y factores dietéticos. El tratamiento consistía en hospitalización, reposo en cama y dieta blanda.',
                'Más tarde, se identificó que el ácido gástrico desempeñaba un rol importante en la formación de úlceras, llevando al uso de antiácidos y bloqueadores de ácido como terapia estándar.',
                'El descubrimiento del Helicobacter pylori en 1982 revolucionó el tratamiento y comprensión de esta enfermedad.'
              ],
            },
            {
              titleSection: 'Prevalencia de la Infección por Helicobacter pylori',
              content: [
                'Helicobacter pylori es una de las infecciones bacterianas crónicas más comunes en humanos.',
                'La prevalencia depende de factores como edad, estado socioeconómico y grupo étnico.',
                'En países en desarrollo, la mayoría de los niños están infectados antes de los 10 años, alcanzando entre un 50% y 90% a los 20 años.'
              ],
            },
            {
              titleSection: 'Enfermedades Asociadas a Helicobacter pylori',
              content: [
                'Esta bacteria está asociada a úlceras gástricas, duodenales, cáncer gástrico y linfoma MALT.',
                'La infección crónica puede llevar a inflamación progresiva y atrofia gástrica, aumentando el riesgo de cáncer.',
                'Factores del huésped y del medio ambiente también influyen en la progresión de la enfermedad.'
              ],
            },
            {
              titleSection: 'Diagnóstico de Helicobacter pylori',
              content: [
                'Las pruebas se dividen en dos grupos: con endoscopia y sin endoscopia.',
                'Pruebas no invasivas incluyen exámenes serológicos, prueba de aliento con urea y detección de antígenos en heces.',
                'La endoscopia es el método más completo, permitiendo biopsias y la identificación de patologías gástricas asociadas.'
              ],
            },
            {
              titleSection: '¿A Quiénes se Debe Investigar?',
              content: [
                'Pacientes con antecedentes de úlcera gástrica o duodenal.',
                'Personas con dispepsia no investigada o uso crónico de antiinflamatorios.',
                'Familiares de pacientes con cáncer gástrico o úlcera duodenal.',
                'Todos los pacientes con prueba positiva de Helicobacter pylori deben ser tratados.'
              ],
            }
          ],
        },
        AbusoCronicoLaxantes: {
          title: 'Abuso Crónico de Laxantes',
          description: 'El abuso crónico de laxantes puede causar estreñimiento severo y trastornos metabólicos. Afecta principalmente a pacientes con trastornos alimenticios o que usan laxantes sin prescripción.',
          keywords: 'abuso de laxantes, colon catártico, bulimia, diagnóstico, tratamiento, melanosis coli',
          canonical: 'https://unidad-gastroenterologia.com/abuso-laxantes',
          link: '/abuso-laxantes',
          className: 'gastroenterologia-page',
          image: 'https://unidad-gastroenterologia.com/images/enfermedades/laxantes/laxantes.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Abuso Crónico de Laxantes',
              image: 'https://unidad-gastroenterologia.com/images/enfermedades/laxantes/laxantes.jpg',
              content: [
                'El abuso crónico de laxantes ocasiona estreñimiento crónico y se presenta en tres formas principales:',
                '1. Pacientes que usan laxantes sin prescripción médica y en exceso.',
                '2. Pacientes bulímicos que utilizan laxantes para bajar de peso.',
                '3. Abusadores subrepticios (a escondidas) de laxantes.',
              ],
            },
            {
              titleSection: 'Colon Catártico Secundario al Uso Crónico de Laxantes',
              content: [
                'El colon catártico es una consecuencia del uso prolongado de laxantes irritantes, generalmente durante 15 o más años.',
                'El diagnóstico es radiológico, realizado mediante un enema de bario, y muestra atrofia y acortamiento del ciego y la válvula ileocecal.',
                'El colon presenta un aspecto tubular por la ausencia de austraciones. Es importante diferenciarlo de la enfermedad inflamatoria intestinal y la amebiasis.',
                'Los efectos sistémicos incluyen desequilibrio hidroelectrolítico, debilidad muscular y alcalosis metabólica debido a la pérdida de potasio.',
              ],
            },
            {
              titleSection: 'Uso de Laxantes en la Bulimia',
              content: [
                'El segundo grupo de usuarios crónicos de laxantes son pacientes con bulimia nerviosa, que puede afectar hasta al 19% de las mujeres universitarias.',
                'La bulimia se caracteriza por episodios de atracones seguidos de purgas, incluyendo el uso de laxantes y diuréticos.',
                'Estos pacientes pueden desarrollar trastornos metabólicos graves y taquiarritmias ventriculares, lo que puede conducir a muerte súbita.',
              ],
            },
            {
              titleSection: 'Abuso de Laxantes Subrepticio',
              content: [
                'Este grupo incluye a quienes usan laxantes a escondidas, negando su uso y presentando diarrea crónica inexplicada.',
                'Puede relacionarse con el síndrome de Munchausen, donde los pacientes incluso administran laxantes a sus hijos.',
                'El diagnóstico implica descartar otras causas mediante estudios médicos normales y la observación del paciente.',
              ],
            },
            {
              titleSection: 'Diagnóstico del Abuso de Laxantes',
              content: [
                'El diagnóstico incluye análisis de sangre y orina para detectar sustancias activas como bisacodilo.',
                'La rectosigmoidoscopía puede mostrar melanosis coli, una pigmentación de la mucosa causada por acumulación de melanina.',
                'En casos dudosos, se recomienda la hospitalización y observación del paciente, analizando el volumen y composición de las heces.',
              ],
            },
          ],
        },
        SeleccionPacienteCirugiaAntireflujo: {
          title: 'Selección de Paciente para Cirugía Antirreflujo',
          description: 'Criterios para seleccionar pacientes para cirugía antirreflujo, diagnóstico de ERGE, y evaluación preoperatoria para un tratamiento exitoso.',
          keywords: 'cirugía antirreflujo, ERGE, funduplicatura, selección de pacientes, diagnóstico de reflujo gastroesofágico',
          canonical: 'https://unidad-gastroenterologia.com/cirugia-antireflujo',
          link: '/cirugia-antireflujo',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Importancia de una Evaluación Adecuada',
              content: [
                'La selección de pacientes para cirugía antirreflujo requiere la confirmación del diagnóstico de Enfermedad por Reflujo Gastroesofágico (ERGE) y la identificación de condiciones asociadas que puedan alterar el abordaje quirúrgico.',
                'Pacientes con hernia hiatal no son candidatos a procedimientos endoscópicos, mientras que aquellos con motilidad esofágica reducida podrían beneficiarse más de una funduplicatura parcial.',
                'Un diagnóstico y evaluación preoperatoria precisos son esenciales para el éxito de la cirugía.'
              ],
            },
            {
              titleSection: 'Diagnóstico Clínico y Estudios Relevantes',
              content: [
                'El diagnóstico clínico de ERGE suele basarse en síntomas clásicos como agruras o regurgitación que mejoran con antiácidos. Sin embargo, pacientes con síntomas atípicos como ronquera, tos crónica, o asma requieren una evaluación más profunda.',
                'Los métodos diagnósticos incluyen:',
                '- **Panendoscopia**: Permite observar lesiones como esófago de Barrett, esofagitis eosinofílica, y estenosis. Además, se toman biopsias para confirmar inflamación.',
                '- **pHmetría**: Indicada cuando la endoscopia no confirma el diagnóstico.',
                '- **Manometría esofágica**: Determina alteraciones en el movimiento del esófago, fundamentales para decidir el tipo de funduplicatura.'
              ],
            },
            {
              titleSection: 'Criterios para la Selección de Pacientes',
              content: [
                '- **Pacientes ideales**: Presentan síntomas clásicos de reflujo, buena respuesta a tratamiento con inhibidores de bomba de protones (IBP), y confirmación diagnóstica de ERGE.',
                '- **Pacientes con riesgo de resultados subóptimos**: Síntomas atípicos, pobre respuesta a IBP, o alteraciones como vaciamiento gástrico retardado o motilidad esofágica deficiente.',
                'Factores como esófago corto, disfunción esofágica, o condiciones gástricas asociadas deben ser considerados para adaptar el tratamiento quirúrgico y mejorar los resultados postoperatorios.'
              ],
            },
            {
              titleSection: 'Técnicas Quirúrgicas y Resultados',
              content: [
                'La cirugía antirreflujo más común es la funduplicatura laparoscópica, que puede ser completa o parcial dependiendo de la motilidad esofágica del paciente.',
                'Resultados esperados:',
                '- **Pacientes ideales**: 90-95% de éxito en la eliminación de los síntomas.',
                '- **Pacientes con factores de riesgo**: 75-85% de mejoría, aunque algunos pueden presentar molestias persistentes.'
              ],
            },
            {
              titleSection: 'Importancia del Diagnóstico Integral',
              content: [
                'Los otorrinolaringólogos, dentistas y otros especialistas frecuentemente identifican daños relacionados con el reflujo, como laringitis crónica, erosiones dentales, o infecciones pulmonares recurrentes.',
                'Una evaluación integral que incluya colaboración interdisciplinaria y estudios avanzados mejora la selección de pacientes y los resultados quirúrgicos.'
              ],
            },
          ],
        },
        ComplicacionesColecistitisSindromeMirizzi: {
          title: 'Complicaciones de la Colecistitis de Larga Evolución: El Síndrome de Mirizzi',
          description: 'Información sobre el Síndrome de Mirizzi, sus características, diagnóstico, tratamiento quirúrgico y relación con cáncer de vesícula biliar.',
          keywords: 'Síndrome de Mirizzi, colecistitis crónica, complicaciones de colecistitis, colecistectomía abierta, hepatoyeyuno anastomosis',
          canonical: 'https://unidad-gastroenterologia.com/sindrome-mirizzi',
          link: '/sindrome-mirizzi',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es el Síndrome de Mirizzi?',
              content: [
                'El Síndrome de Mirizzi es una complicación de la colecistitis crónica caracterizada por la obstrucción parcial del conducto hepático común, secundaria a una piedra impactada y la inflamación crónica en el cuello de la vesícula biliar.',
                'Puede evolucionar hacia una fístula erosiva, comprometiendo la pared del conducto hepático y aumentando el riesgo de lesiones en el conducto biliar durante la colecistectomía.'
              ],
            },
            {
              titleSection: 'Clasificación de Csendes',
              content: [
                'La clasificación de Csendes define cuatro tipos de Síndrome de Mirizzi según la gravedad de la lesión:',
                '- **Tipo I**: Compresión externa simple del conducto hepático común.',
                '- **Tipo II**: Presencia de una fístula pequeña.',
                '- **Tipo III**: Fístula grande con daño extenso.',
                '- **Tipo IV**: Destrucción completa de la pared del conducto hepático.'
              ],
            },
            {
              titleSection: 'Diagnóstico del Síndrome de Mirizzi',
              content: [
                'Las técnicas de imagen son esenciales para el diagnóstico:',
                '- **Ultrasonido**: Identifica piedras grandes en el cuello de la vesícula biliar y dilatación de la vía biliar.',
                '- **Tomografía Computarizada (TC)**: Visualiza cavidades irregulares cerca del cuello de la vesícula con piedras fuera de la luz.',
                '- **CPRE/CPRM**: Delinean con precisión el nivel y la causa de la obstrucción o fístula.',
                '- **Colangiografía Percutánea Transhepática**: Complementa el diagnóstico en casos complejos.'
              ],
            },
            {
              titleSection: 'Tratamiento del Síndrome de Mirizzi',
              content: [
                '- **Colecistectomía abierta**: Es el estándar quirúrgico para la reparación del síndrome.',
                '- **Colecistectomía subtotal**: Adecuada si no hay fístula. Para fístulas pequeñas (tipo II), se utiliza un tubo en T en la fístula y se cierra el resto de la vesícula.',
                '- **Hepatoyeyuno anastomosis**: Indicada para fístulas grandes (tipos III y IV).'
              ],
            },
            {
              titleSection: 'Relación con el Cáncer de Vesícula Biliar',
              content: [
                'En un 6% a 27,8% de los pacientes con cáncer de vesícula biliar se identifica el Síndrome de Mirizzi en el preoperatorio.',
                'Es imprescindible realizar estudios histológicos transoperatorios en todos los casos para descartar malignidad.'
              ],
            },
          ],
        },
        EvaluacionInicialColedocolitiasis: {
          title: 'Evaluación Inicial del Paciente con Coledocolitiasis',
          description: 'Guía para la evaluación inicial de la coledocolitiasis, incluyendo pruebas bioquímicas, ultrasonido y criterios diagnósticos.',
          keywords: 'coledocolitiasis, pruebas bioquímicas hepáticas, ultrasonido abdominal, conducto biliar, colangiografía',
          canonical: 'https://unidad-gastroenterologia.com/coledocolitiasis',
          link: '/coledocolitiasis',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es la Coledocolitiasis?',
              content: [
                'La coledocolitiasis es la presencia de cálculos en el conducto biliar común. Se sospecha en cuadros de colelitiasis sintomática (como cólico vesicular o colecistitis aguda) y en pancreatitis aguda biliar.',
                'La formación de cálculos primarios en el conducto biliar en pacientes pos-colecistectomía es menos frecuente.'
              ],
            },
            {
              titleSection: 'Pruebas Bioquímicas en la Evaluación Inicial',
              content: [
                'Las pruebas bioquímicas de función hepática, como la alanina aminotransferasa (ALT), aspartato aminotransferasa (AST), fosfatasa alcalina y bilirrubina total, son esenciales para descartar coledocolitiasis.',
                'Un resultado normal en estas pruebas tiene un 97% de probabilidad de descartar coledocolitiasis. Sin embargo, resultados anormales solo indican un 15% de probabilidad de presencia de la enfermedad.',
                'Pruebas como las bilirrubinas, fosfatasa alcalina y gama glutamil transpeptidasa son indicadores clave que aumentan con la duración y gravedad de la obstrucción biliar.'
              ],
            },
            {
              titleSection: 'Rol del Ultrasonido Abdominal',
              content: [
                'El ultrasonido abdominal tiene una sensibilidad del 22% al 55% para detectar cálculos en el conducto biliar común.',
                'Sin embargo, puede identificar dilataciones del colédoco con una sensibilidad del 77% al 87%, lo cual sugiere obstrucción biliar.',
                'Un diámetro del conducto biliar mayor a 8 mm en un paciente con vesícula intacta es indicativo de obstrucción.'
              ],
            },
            {
              titleSection: 'Factores Predictores de Coledocolitiasis',
              content: [
                'La presencia de cálculos pequeños (menores a 5 mm) en la vesícula aumenta el riesgo de migración hacia el conducto biliar común.',
                'Edad avanzada (mayores de 55 años), elevación de pruebas bioquímicas hepáticas (excepto bilirrubina) y pancreatitis son predictores adicionales de coledocolitiasis.',
                'Un ultrasonido con una vía biliar normal prácticamente descarta coledocolitiasis, con menos del 5% de probabilidad en pacientes no ictéricos.'
              ],
            },
            {
              titleSection: 'Diagnóstico Definitivo',
              content: [
                'La visualización de un cálculo en el colédoco mediante ultrasonido es el predictor más fiable y se confirma posteriormente con colangiografía retrógrada endoscópica (CEPRE) o cirugía.'
              ],
            },
          ],
        },
        Hemorroidectomia: {
          title: 'HEMORROIDECTOMIA: LA CIRUGIA DE HEMORROIDES',
          description: 'Información sobre la hemorroidectomía, técnicas quirúrgicas utilizadas, manejo del dolor postoperatorio, y recuperación tras la cirugía de hemorroides.',
          keywords: 'hemorroidectomía, cirugía de hemorroides, técnicas de hemorroidectomía, tratamiento de hemorroides, dolor postoperatorio, cirugía Ferguson, cirugía Milligan-Morgan',
          canonical: 'https://unidad-gastroenterologia.com/hemorroidectomia',
          link: '/hemorroidectomia',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es la Hemorroidectomía?',
              content: [
                'La hemorroidectomía está indicada para el tratamiento de hemorroides internas y externas Grado III y IV, especialmente en pacientes que no responden a otros métodos de tratamiento o aquellos con enfermedad extensa o sangrado persistente.',
                'La intervención es necesaria en aproximadamente el 20% de los pacientes con enfermedad hemorroidal sintomática.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Técnicas de Hemorroidectomía',
              content: [
                'Existen dos técnicas principales para la hemorroidectomía: la hemorroidectomía abierta (Milligan-Morgan) y la hemorroidectomía cerrada (Ferguson).',
                'La hemorroidectomía abierta es más común en el Reino Unido, mientras que la cerrada se utiliza principalmente en Estados Unidos.',
                'La técnica cerrada tiene la ventaja de un tiempo de cicatrización más rápido en comparación con la abierta, aunque el dolor postoperatorio puede ser más intenso inicialmente.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/tecnicas-hemorroidectomia.jpg',
            },
            {
              titleSection: 'Complicaciones Postoperatorias',
              content: [
                'A pesar de las altas tasas de éxito, el dolor postoperatorio sigue siendo un obstáculo importante.',
                'Los pacientes pueden necesitar una combinación de analgésicos narcóticos, AINEs, relajantes musculares, y baños de asiento para manejar el dolor severo.',
                'La recuperación completa puede tomar entre 1 a 3 semanas, dependiendo de la técnica utilizada.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/complicaciones-hemorroidectomia.jpg',
            },
            {
              titleSection: 'Estudio Comparativo: Hemorroidectomía Abierta vs Cerrada',
              content: [
                'Un estudio comparativo entre las técnicas abierta y cerrada mostró que no existen diferencias significativas en cuanto al tiempo quirúrgico, la estancia hospitalaria o la incapacidad para trabajar.',
                'Sin embargo, la hemorroidectomía cerrada mostró un tiempo de cicatrización significativamente menor que la abierta, aunque los pacientes requerían más medicamentos para el dolor en los primeros días postoperatorios.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/estudio-comparativo.jpg',
            },
            {
              titleSection: '¿Cuál Técnica Elegir?',
              content: [
                'La elección entre una hemorroidectomía abierta o cerrada depende de la preferencia del cirujano y las condiciones específicas del paciente.',
                'Ambas técnicas tienen altas tasas de éxito, pero la técnica cerrada ofrece una recuperación más rápida, aunque con más dolor inicial.',
                'La opción de la bomba de infusión peridural ha demostrado ser eficaz para controlar el dolor en el postoperatorio inmediato.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/eleccion-tecnica-hemorroidectomia.jpg',
            },
          ],
        },
        RelacionColonoscopiaCancerColon: {
          title: 'RELACION ENTRE LA COLONOSCOPIA Y EL CANCER DE COLON',
          description: 'Información sobre la importancia de la colonoscopia en la detección precoz del cáncer de colon, cómo se realiza, y las pautas de seguimiento según los resultados.',
          keywords: 'colonoscopia, cáncer de colon, pólipos, detección precoz, cáncer, pólipos adenomatosos, prevención del cáncer de colon',
          canonical: 'https://unidad-gastroenterologia.com/relacion-colonoscopia-cancer-colon',
          link: '/relacion-colonoscopia-cancer-colon',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es una Colonoscopia?',
              content: [
                'La colonoscopia es un examen médico que permite diagnosticar cáncer y otras enfermedades. Durante este procedimiento, el médico examina el recto y el colon utilizando un tubo largo y flexible con una cámara diminuta en su extremo.',
                'Si se encuentran lesiones elevadas llamadas pólipos, estos pueden ser removidos, ya que algunos pólipos, aunque benignos, pueden convertirse en cáncer a corto o largo plazo.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: '¿Quién se debe realizar una Colonoscopia?',
              content: [
                'Todas las personas mayores de 50 años deben realizarse una colonoscopia para investigar la presencia de cáncer de colon.',
                'Si hay antecedentes familiares de cáncer de colon, la investigación debe comenzar 10 años antes de la edad en la que se diagnosticó el cáncer en el familiar.',
                'Si los resultados de la colonoscopia son normales, no es necesario realizarse otro estudio en al menos 5 años. Sin embargo, si se encuentran pólipos o si la persona ha tenido cáncer de colon, la vigilancia debe ser más frecuente.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/colonoscopy-age.jpg',
            },
            {
              titleSection: 'Pólipos Extirpados que No Son Cáncer',
              content: [
                'Si se han extirpado pólipos, la necesidad de realizar una nueva colonoscopia depende del tipo, tamaño y número de los pólipos extraídos.',
                'Si se retiraron uno o dos pólipos menores de 1 cm, la próxima colonoscopia debe realizarse en 5 años.',
                'Si se retiraron de 3 a 10 pólipos adenomatosos, o cualquier pólipo mayor de 1 cm, la próxima colonoscopia debe realizarse en 2 años.',
                'Si se retiraron 10 o más pólipos adenomatosos, se debe realizar una colonoscopia al año.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/polipos-extirpados.jpg',
            },
            {
              titleSection: 'Pólipos Extirpados que Tienen Cáncer',
              content: [
                'Si los pólipos extirpados contienen cáncer de colon, se necesita realizar otra colonoscopia.',
                'En caso de que se haya extraído un pólipo sésil (un tipo de pólipo que puede no haberse separado completamente), se debe realizar otra colonoscopia en 2 a 6 meses para asegurarse de que el pólipo haya desaparecido por completo.',
                'Si se ha realizado una cirugía para extirpar parte o la totalidad del colon, se requiere una colonoscopia un año después de la cirugía.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/cancer-colon-polipos.jpg',
            },
          ],
        },
        ManejoMedicoDiverticulitis: {
          title: 'MANEJO MEDICO DE LA DIVERTICULITIS',
          description: 'Este artículo ofrece una guía detallada sobre el manejo médico de la diverticulitis, abordando aspectos clave como el uso de antibióticos, reposo intestinal, analgésicos y las indicaciones para hospitalización o intervención quirúrgica.',
          keywords: 'diverticulitis, tratamiento médico de la diverticulitis, antibióticos, tratamiento de la diverticulitis, enfermedad diverticular, intervención quirúrgica en diverticulitis',
          canonical: 'https://unidad-gastroenterologia.com/manejo-medico-diverticulitis',
          link: '/manejo-medico-diverticulitis',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/images/publicaciones/diverticulite.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Tratamiento de la Diverticulitis No Complicada',
              content: [
                'En los casos de diverticulitis no complicada, el tratamiento inicial se enfoca en la administración de antibióticos, reposo intestinal y el manejo del dolor.',
                'Se prescriben antibióticos que cubren bacterias Gram-negativas y anaerobias, durante un período de 7 a 10 días.',
                'El tratamiento ambulatorio es adecuado para pacientes con síntomas leves, que toleran líquidos y mantienen una función intestinal normal.'
              ],
              image: 'https://unidad-gastroenterologia.com/images/publicaciones/diverticulite.jpg',
            },
            {
              titleSection: 'Cuándo Se Requiere Hospitalización',
              content: [
                'En situaciones graves de diverticulitis aguda, ciertos signos clínicos indican la necesidad de hospitalización.',
                'Cuando el paciente no puede ingerir alimentos, presenta vómitos persistentes, requiere analgesia intravenosa o muestra signos de complicaciones, es necesario un manejo hospitalario.',
                'Los pacientes ancianos pueden no tolerar el tratamiento ambulatorio, por lo que deben recibir una vigilancia más estrecha.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/hospitalizacion-diverticulitis.jpg',
            },
            {
              titleSection: 'Tratamiento en Casos Refractarios',
              content: [
                'En algunos casos, a pesar del tratamiento antibiótico agresivo, los pacientes pueden presentar complicaciones y requerir intervención quirúrgica.',
                'El coloproctólogo debe considerar la cirugía cuando ocurren episodios recurrentes de diverticulitis, cuando los síntomas no mejoran con tratamiento médico, o cuando se presentan complicaciones, especialmente en pacientes inmunocomprometidos.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/tratamiento-refractario.jpg',
            },
          ],
        },
        EvaluacionMujerIncontinenciaFecal: {
          title: 'EVALUACION DE LA MUJER QUE SUFRE DE INCONTINENCIA FECAL',
          description: 'Guía para evaluar la incontinencia fecal en mujeres, destacando la importancia de la historia clínica detallada, el examen físico y las pruebas adicionales para el diagnóstico y manejo adecuado.',
          keywords: 'incontinencia fecal, evaluación de incontinencia anal, historia clínica, diagnóstico de incontinencia fecal, manejo de incontinencia, examen físico de incontinencia fecal',
          canonical: 'https://unidad-gastroenterologia.com/evaluacion-mujer-incontinencia-fecal',
          link: '/evaluacion-mujer-incontinencia-fecal',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Objetivos en la Evaluación de la Incontinencia Fecal',
              content: [
                'Hay tres objetivos principales en la evaluación de una mujer con incontinencia fecal:',
                '- Determinar la gravedad del problema y su impacto en la calidad de vida.',
                '- Descartar un proceso de enfermedad subyacente tratable que puede estar contribuyendo a la incontinencia.',
                '- Determinar todos los factores que contribuyen y los medios con los que se cuenta para su manejo.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Historia Clínica Detallada',
              content: [
                'Es fundamental obtener una historia clínica detallada que incluya cómo la paciente describe su incontinencia fecal, hábitos intestinales, actividades diarias y movilidad.',
                'La frecuencia y consistencia de las heces, así como la calidad de la evacuación, son importantes para definir la urgencia del tratamiento.',
                'Además, se debe indagar sobre los cambios en la rutina diaria, como evitar comidas o salir de casa para prevenir la incontinencia.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/historia-clinica.jpg',
            },
            {
              titleSection: 'Exploración Física Completa',
              content: [
                'La exploración física debe incluir la observación de la vagina para detectar cicatrices visibles, prolapso uterino o rectocele.',
                'Se debe realizar una maniobra de Valsalva para detectar posibles descensos del periné, prolapso uterino o rectal.',
                'El examen del ano debe incluir la inspección visual para detectar cicatrices, hemorroides externas, fístulas, dermatitis, infecciones o masas. La anoscopia rectal puede revelar hemorroides internas, rectocele, enterocele o masas en el margen anal.',
                'Durante el examen rectal digital, se evalúa la función motora de los músculos del piso pélvico y esfínter al pedirle a la paciente que contraiga y relaje los músculos.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/examen-fisico.jpg',
            },
            {
              titleSection: 'Pruebas Adicionales y Seguimiento',
              content: [
                'Las pruebas adicionales pueden no ser necesarias tras un examen físico completo, pero son útiles en determinadas situaciones para guiar el diagnóstico y tratamiento.',
                'En los próximos meses, se revisarán estudios especiales para la incontinencia fecal, como la maniobra de Valsalva, que ayuda a aumentar la presión abdominal, o la prueba de toser para evaluar la función del esfínter.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/pruebas-adicionales.jpg',
            },
          ],
        },
        TrombosisHemorroidalExterna: {
          title: 'TROMBOSIS HEMORROIDAL EXTERNA',
          description: 'Información sobre la trombosis hemorroidal externa, su diagnóstico, tratamiento conservador, y opciones quirúrgicas para aliviar los síntomas y prevenir recurrencias.',
          keywords: 'trombosis hemorroidal externa, tratamiento de trombosis hemorroidal, escisión hemorroidal, hemorroides, tratamiento conservador, cirugía hemorroidal, complicaciones de trombosis hemorroidal',
          canonical: 'https://unidad-gastroenterologia.com/trombosis-hemorroidal-externa',
          link: '/trombosis-hemorroidal-externa',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: '¿Qué es la Trombosis Hemorroidal Externa?',
              content: [
                'La trombosis hemorroidal externa se manifiesta como un malestar agudo y la presencia de una masa dolorosa en el ano, generalmente tras esfuerzos o eventos como levantar objetos pesados, esfuerzo excesivo para defecar o diarrea.',
                'En pacientes con episodios repetidos de trombosis, se debe investigar y tratar las causas subyacentes, como el esfuerzo y el estreñimiento.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Tratamiento Conservador',
              content: [
                'El tratamiento conservador incluye baños de asiento, analgésicos y suavizantes de heces para aliviar los síntomas.',
                'El trombo se absorbe gradualmente durante varias semanas, y el dolor generalmente se alivia en 2 a 3 días. La masa se resuelve dentro de los 7 a 10 días.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/tratamiento-conservador.jpg',
            },
            {
              titleSection: 'Escisión Quirúrgica',
              content: [
                'La escisión quirúrgica puede realizarse con anestesia local de manera ambulatoria. Este procedimiento presenta una baja tasa de recurrencia y complicaciones.',
                'La escisión dentro de las primeras 48 a 72 horas desde la aparición de los síntomas a menudo resulta en un alivio rápido.',
                'Se realiza una incisión elíptica en la piel de la trombosis hemorroidal, generalmente de forma radial hasta el esfínter. El sangrado puede controlarse con presión o electrocauterio.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/escision-quirurgica.jpg',
            },
            {
              titleSection: 'Cuidado Postoperatorio y Complicaciones',
              content: [
                'El cuidado postoperatorio incluye la presión para controlar el sangrado, con un vendaje compresivo que el paciente no debe retirar hasta después de unas horas.',
                'Es posible que se observe algo de sangrado después de la intervención, especialmente si la herida se deja abierta y se usó anestesia local con adrenalina. Los baños de asiento y analgésicos son recomendados durante los primeros 7 a 10 días.',
                'Las complicaciones son generalmente leves y pueden incluir sangrado o marcas en la piel, aunque las infecciones locales son poco frecuentes.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/cuidado-postoperatorio.jpg',
            },
            {
              titleSection: 'Tratamiento Conservador vs Quirúrgico',
              content: [
                'Aunque la resolución de los síntomas es más rápida con el tratamiento conservador, el período de recuperación final es más largo y la tasa de recurrencia es más alta que en aquellos tratados quirúrgicamente.',
                'La mayoría de los pacientes tratados con cirugía experimentan una recuperación más rápida y con menos recurrencias.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/tratamiento-conservador-vs-quirurgico.jpg',
            },
          ],
        },
        TratamientoNoQuirurgicoFisuraAnal: {
          title: 'TRATAMIENTO NO QUIRURGICO DE LA FISURA ANAL',
          description: 'Este artículo aborda los tratamientos no quirúrgicos para la fisura anal, incluyendo opciones farmacológicas como la nitroglicerina, bloqueadores de los canales de calcio y Botox, así como las tasas de curación y recurrencia.',
          keywords: 'tratamiento no quirúrgico fisura anal, nitroglicerina para fisura anal, Botox para fisura anal, tratamiento farmacológico fisura anal, fisura anal crónica, curación fisura anal, recurrencia fisura anal',
          canonical: 'https://unidad-gastroenterologia.com/tratamiento-no-quirurgico-fisura-anal',
          link: '/tratamiento-no-quirurgico-fisura-anal',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Clasificación de las Fisuras Anales',
              content: [
                'Las fisuras anales se pueden clasificar como agudas o crónicas según la duración de los síntomas, el aspecto y la morfología de la fisura durante la revisión proctológica.',
                'Las fisuras agudas generalmente curan espontáneamente, pero algunas se vuelven crónicas. Se considera una fisura anal crónica cuando los síntomas persisten por más de 6 semanas o cuando se observan fibras musculares del esfínter anal interno.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/clasificacion-fisura-anal.jpg',
            },
            {
              titleSection: 'Tratamiento de la Fisura Aguda',
              content: [
                'El objetivo del tratamiento de la fisura aguda es romper el ciclo de heces duras, dolor y espasmos del esfínter anal.',
                'Esto se puede lograr mediante una dieta rica en fibra, adecuada ingesta de líquidos y, si es necesario, el uso de ablandadores de heces.',
                'Hasta el 90% de los pacientes diagnosticados con fisuras agudas se curan con estas medidas, apoyadas por pomadas que favorecen la cicatrización.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/tratamiento-fisura-aguda.jpg',
            },
            {
              titleSection: 'Tratamiento Médico de la Fisura Anal Crónica',
              content: [
                'El tratamiento farmacológico busca reducir la presión anormalmente alta del esfínter anal para permitir que la fisura cicatrice.',
                'Varios agentes farmacológicos se han desarrollado con el objetivo de inducir una relajación del esfínter anal.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/tratamiento-medico-fisura-anal.jpg',
            },
            {
              titleSection: 'Nitroglicerina',
              content: [
                'La nitroglicerina fue uno de los primeros tratamientos farmacológicos utilizados para crear una "esfinterotomía química".',
                'El tratamiento aumenta el flujo sanguíneo ano-rectal y relaja el esfínter anal, reduciendo significativamente el dolor durante la defecación después de 2 semanas, incluso en pacientes que no cicatrizan.',
                'A pesar de las bajas tasas de curación (48,6% frente a 37% con placebo), la nitroglicerina sigue siendo una opción válida, aunque las recurrencias son comunes.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/nitroglicerina-fisura-anal.jpg',
            },
            {
              titleSection: 'Bloqueadores de los Canales de Calcio',
              content: [
                'Los bloqueadores de los canales de calcio ayudan a disminuir la contracción del músculo liso, reduciendo así la presión de reposo del esfínter anal.',
                'Aunque los agentes tópicos son más efectivos que los orales, las tasas de curación y las recurrencias a largo plazo no son significativamente mejores que las observadas con nitroglicerina.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/bloqueadores-canales-calcio.jpg',
            },
            {
              titleSection: 'Botox para la Fisura Anal',
              content: [
                'La toxina botulínica, derivada de la bacteria *Clostridium botulinum*, es una neurotoxina potente que, al inyectarse en el esfínter anal, produce una relajación del músculo y mejora la perfusión.',
                'Aunque más invasiva que los tratamientos tópicos, la aplicación de Botox es ambulatoria y proporciona una esfinterotomía química reversible.',
                'Con tasas de curación del 70% frente al 49% con nitroglicerina a los 6 meses, el Botox es una opción prometedora, aunque las recurrencias a largo plazo pueden ser altas.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/botox-fisura-anal.jpg',
            },
            {
              titleSection: 'Consideraciones Finales',
              content: [
                'Aunque el tratamiento no quirúrgico de la fisura anal presenta tasas de curación más bajas y mayor recurrencia en comparación con la cirugía, su bajo riesgo y la posibilidad de ser repetido lo hacen una opción válida para aquellos que desean evitar la cirugía.',
                'La cirugía se reserva para los fracasos del tratamiento no quirúrgico.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/fisura-anal-tratamiento.jpg',
            },
          ],
        },
        FistulaColovesical: {
          title: 'FISTULA COLOVESICAL',
          description: 'Información sobre las causas, síntomas, diagnóstico y tratamiento de la fístula colovesical, incluyendo los enfoques conservadores y quirúrgicos.',
          keywords: 'fístula colovesical, causas de fístula colovesical, neumaturia, polaquiuria, fecaluria, diagnóstico de fístula colovesical, tratamiento fístula colovesical, cirugía fístula colovesical, enfermedad de Crohn',
          canonical: 'https://unidad-gastroenterologia.com/fistula-colovesical',
          link: '/fistula-colovesical',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Causas de la Fístula Colovesical',
              content: [
                'Las fístulas colovesicales ocurren comúnmente en el contexto de enfermedades intestinales como la diverticulitis, el carcinoma colorrectal y la enfermedad de Crohn.',
                'La diverticulitis es la causa más frecuente, representando aproximadamente el 70% de los casos. Otras causas incluyen la radiación, infecciones y traumas (tanto penetrantes externos como quirúrgicos).',
                'El pico de incidencia es entre los 55 y 65 años, aunque las fístulas causadas por la enfermedad de Crohn se presentan a edades más tempranas.',
                'Aproximadamente el 2% de los pacientes con diverticulitis experimentan una fístula colovesical como complicación.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Síntomas de la Fístula Colovesical',
              content: [
                'Los síntomas suelen provenir del tracto urinario, siendo la neumaturia (presencia de aire en la orina) el más común, observado en el 50-70% de los casos.',
                'Otros síntomas urinarios incluyen polaquiuria, urgencia miccional, dolor suprapúbico, infecciones recurrentes del tracto urinario, fecaluria y hematuria.',
                'Los síntomas gastrointestinales pueden incluir dolor y tenesmo. La presentación clásica es el síndrome de Gouverneur, que incluye dolor suprapúbico, polaquiuria, disuria y tenesmo.',
                'Las infecciones urinarias recurrentes o cistitis refractaria al tratamiento antibiótico pueden sugerir una fístula colovesical.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/sintomas-fistula-colovesical.jpg',
            },
            {
              titleSection: 'Diagnóstico de la Fístula Colovesical',
              content: [
                'El diagnóstico generalmente es clínico debido a problemas con los falsos positivos y negativos en las modalidades de diagnóstico.',
                'La cistoscopia es la técnica más precisa para identificar anomalías, con una tasa de hallazgos positivos superior al 90%.',
                'La tomografía computarizada (TC) y la resonancia magnética (RM) son las modalidades de imagen más sensibles, con la TC alcanzando una precisión diagnóstica del 90-100%.',
                'La tríada en la TC que sugiere fístula colovesical incluye el engrosamiento de la pared vesical adyacente a un bucle de colon engrosado, aire en la vejiga y la presencia de divertículos en el colon.',
                'Otras pruebas diagnósticas incluyen el enema de bario, la colonoscopia y la prueba de Bourne, que es útil para detectar pequeñas partículas radiodensas en la orina tras un enema de bario.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/diagnostico-fistula-colovesical.jpg',
            },
            {
              titleSection: 'Tratamiento de la Fístula Colovesical',
              content: [
                'En pacientes con enfermedad de Crohn, el tratamiento inicial debe ser conservador, con medidas específicas como la nutrición parenteral y el reposo intestinal.',
                'El objetivo del tratamiento quirúrgico es separar y cerrar los órganos involucrados con mínima interrupción de la función normal de ambos sistemas.',
                'La cirugía laparoscópica para la fístula colovesical tiene una tasa alta de conversión a la reparación, y la resección intestinal o cistectomía parcial puede ser necesaria en casos complicados con abscesos o flemón pélvico.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/tratamiento-fistula-colovesical.jpg',
            },
            {
              titleSection: 'Palabras Clave',
              content: [
                'Neumaturia: salida de gas a través de la uretra durante la micción o con esfuerzo.',
                'Polaquiuria: micciones frecuentes y generalmente escasas.',
                'Hematuria: presencia de sangre en la orina.',
                'Fecaluria: presencia de restos de materia fecal en la orina.',
                'Tenesmo: sensación de evacuación incompleta.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/palabras-clave-fistula-colovesical.jpg',
            },
          ],
        },
        HemorroidectomiaConEngrapadoraPPH: {
          title: 'HEMORROIDECTOMIA CON ENGRAPADORA PPH',
          description: 'Información sobre la hemorroidectomía con engrapadora PPH, incluyendo la técnica, indicaciones, ventajas, desventajas, y contraindicaciones de este procedimiento quirúrgico para el tratamiento de las hemorroides.',
          keywords: 'hemorroidectomía con engrapadora PPH, técnica de hemorroidopexia, hemorroidectomía con engrapadora, tratamiento de hemorroides, cirugía de hemorroides, hemorroidopexia, PPH, cirugía de prolapso de hemorroides',
          canonical: 'https://unidad-gastroenterologia.com/hemorroidectomia-con-engrapadora-pph',
          link: '/hemorroidectomia-con-engrapadora-pph',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Técnica de Hemorroidectomía con Engrapadora PPH',
              content: [
                'La hemorroidectomía con engrapadora PPH (procedimiento para prolapso de hemorroides) es una técnica quirúrgica que utiliza un dispositivo de engrapadora circular para resecar la mucosa que está en exceso y fijar la mucosa a la pared rectal subyacente.',
                'El procedimiento puede realizarse bajo anestesia regional o general, y se puede llevar a cabo de forma ambulatoria.',
                'Se realiza una jareta circunferencial de sutura continua a 2 cm por encima de la línea dentada, se introduce la grapadora transanal, y se dispara para resecar el exceso de tejido hemorroidal y cerrar el defecto de la mucosa simultáneamente.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/tecnica-engrapadora-pph.jpg',
            },
            {
              titleSection: 'Propósito de la Hemorroidectomía con Engrapadora PPH',
              content: [
                'El objetivo principal de este procedimiento es interrumpir el suministro sanguíneo a las arterias hemorroidales superiores, proximal al tejido hemorroidal, y tratar el prolapso de la mucosa mientras se interrumpe el flujo sanguíneo hacia las hemorroides.',
                'Este procedimiento permite una rápida resolución del prolapso hemorroidal y mejora la perfusión rectal.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/proposito-engrapadora-pph.jpg',
            },
            {
              titleSection: 'Indicaciones para la Hemorroidectomía con Engrapadora PPH',
              content: [
                'Este procedimiento está indicado para pacientes con enfermedad hemorroidal Grado III o IV, especialmente aquellos con prolapso reducible que no responden a tratamientos convencionales como ligaduras con bandas elásticas, rayos infrarrojos o escleroterapia.',
                'Se recomienda cuando otros tratamientos no han sido efectivos.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/indicaciones-engrapadora-pph.jpg',
            },
            {
              titleSection: 'Desventajas de la Hemorroidectomía con Engrapadora PPH',
              content: [
                'A diferencia de la hemorroidectomía convencional, la hemorroidectomía con engrapadora no permite tratar simultáneamente otras afecciones perianales como fisuras anales, papilas anales hipertrofiadas o trombosis hemorroidal aguda.',
                'Este procedimiento no realiza incisiones en el anodermo, lo que limita su capacidad para tratar todos los problemas anales asociados con la enfermedad hemorroidal.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/desventajas-engrapadora-pph.jpg',
            },
            {
              titleSection: 'Contraindicaciones de la Hemorroidectomía con Engrapadora PPH',
              content: [
                'Las contraindicaciones incluyen absceso anal, gangrena o estenosis anal, ya que estos problemas no pueden ser tratados adecuadamente con engrapadora.',
                'El prolapso rectal de espesor completo también es una contraindicación, y la incontinencia anal requiere otras modalidades de tratamiento.',
                'Este procedimiento no es adecuado para pacientes con infecciones urinarias activas, ya que el procedimiento puede no abordar la fuente de la sepsis.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/contraindicaciones-engrapadora-pph.jpg',
            },
            {
              titleSection: 'Ventajas de la Hemorroidectomía con Engrapadora PPH',
              content: [
                'Entre las principales ventajas se incluyen menos dolor postoperatorio, menos sangrado transoperatorio y postoperatorio, un tiempo quirúrgico más corto y una rápida recuperación.',
                'Los pacientes tienen una reincorporación más rápida a sus actividades normales, y el dolor postoperatorio es significativamente menor en comparación con la hemorroidectomía convencional.',
                'La hemorroidectomía con engrapadora tiene una tasa de complicaciones similar a la cirugía convencional, pero con una recuperación más rápida.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/ventajas-engrapadora-pph.jpg',
            },
            {
              titleSection: 'Consideraciones Finales',
              content: [
                'El procedimiento para prolapso de hemorroides es una técnica quirúrgica relativamente nueva que ofrece a los pacientes el tratamiento quirúrgico con menos dolor postoperatorio, menor estancia hospitalaria y tiempo de recuperación, y una tasa de complicaciones similar a la que con la hemorroidectomía convencional.',
                'Es importante mencionar que los resultados a seguimiento de 10 años hasta el momento son muy cercanos a la hemorroidectomia abierta por lo que considero una excelente opción quirurgíca, que aunque el costo de la engrapado es relativamente alto, se compensa mucho con la incorporación pronta a la actividad laboral en pacientes que sus ingresos son por honorarios.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/consideraciones-finales.jpg',
            },
          ],
        },
        DesarterializacionHemorroidalDoppler: {
          title: 'DESARTERIALIZACION HEMORROIDAL TRANS-ANAL GUIADA POR DOPPLER',
          description: 'Descripción del procedimiento de desarterialización hemorroidal trans-anal guiada por Doppler, su técnica, ventajas y consideraciones para su aplicación en pacientes con enfermedad hemorroidal.',
          keywords: 'desarterialización hemorroidal, hemorroides, tratamiento hemorroidal, enfermedad hemorroidal, procedimiento Doppler, tratamiento mínimamente invasivo, cirugía hemorroidal',
          canonical: 'https://unidad-gastroenterologia.com/desarterializacion-hemorroidal-doppler',
          link: '/desarterializacion-hemorroidal-doppler',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle (Cortesía THD Lab)',
          sections: [
            {
              titleSection: 'Suministro de Sangre del Conducto Anal',
              content: [
                'El suministro de sangre al recto y al conducto anal proviene de tres arterias principales: la arteria rectal superior, la arteria media rectal y la arteria rectal inferior.',
                'El plexo hemorroidal interno recibe el suministro sanguíneo exclusivamente de las ramas terminales de la arteria rectal superior, y está compuesto por una red cavernosa artero-venosa llamada corpus cavernosum recti (CCR).',
                'Los defectos en la regulación de los desvíos artero-venosos y el incremento en el flujo sanguíneo arterial son factores que contribuyen al desarrollo de la enfermedad hemorroidal.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Clasificación de la Enfermedad Hemorroidal',
              content: [
                'La enfermedad hemorroidal se clasifica según Goligher en cuatro grados:',
                '- **Grado I**: Hemorroides internas sin prolapso.',
                '- **Grado II**: Hemorroides internas que prolapsan con la defecación, pero se reducen espontáneamente.',
                '- **Grado III**: Hemorroides internas que prolapsan y requieren reducción manual.',
                '- **Grado IV**: Hemorroides internas que prolapsan y no pueden ser reducidas.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/clasificacion-enfermedad-hemorroidal.jpg',
            },
            {
              titleSection: 'Ventajas de la Desarterialización Hemorroidal',
              content: [
                'Las principales ventajas de la desarterialización hemorroidal incluyen:',
                '- **No hay dolor postoperatorio**.',
                '- **Sin complicaciones postoperatorias**.',
                '- **Procedimiento resolutivo**.',
                '- **No requiere anestesia general**.',
                '- **No requiere hospitalización**.',
                '- **Puede repetirse si es necesario**.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/ventajas-desarterializacion.jpg',
            },
            {
              titleSection: 'Abordaje Quirúrgico de la Desarterialización Hemorroidal',
              content: [
                'Este procedimiento requiere un equipo Doppler hemorroidal, incorporado a un espejo rectal o anoscopio quirúrgico, además del material básico del cirujano.',
                'El equipo Doppler localiza las arterias hemorroidales y permite una ligadura selectiva de las arterias mediante una aguja precisa.',
                'La técnica se realiza transanal, con un mínimo de invasión, permitiendo que la intervención sea ambulatoria.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/abordaje-quirurgico-doppler.jpg',
            },
            {
              titleSection: 'Técnica de Desarterialización Hemorroidal',
              content: [
                'El procedimiento consiste en ligar las arterias hemorroidales principales que suministran sangre al tejido hemorroidal, lo que reduce la afluencia arterial a las hemorroides y provoca su colapso.',
                'Las suturas fijas levantan los colchones hemorroidales, y al disminuir la tensión, facilita su contracción y reduce el prolapso.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/tecnica-desarterializacion.jpg',
            },
            {
              titleSection: 'Opinión del Autor',
              content: [
                'La técnica convencional de cirugía abierta en el tratamiento de la enfermedad hemorroidal es efectiva, pero está asociada con dolor postoperatorio significativo.',
                'La desarterialización hemorroidal con Doppler presenta un mínimo dolor postoperatorio y permite una reincorporación a las actividades cotidianas en 2 a 3 días.',
                'Aunque esta técnica no es definitiva en todos los casos, es una excelente opción para pacientes seleccionados, y puede repetirse si las hemorroides se reforman debido a esfuerzo excesivo o evacuaciones duras.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/opinion-del-autor.jpg',
            },
          ],
        },
        IngestaFibraHemorroides: {
          title: 'INGESTA ADECUADA DE FIBRA EN EL MANEJO Y PREVENCIÓN DE LAS HEMORROIDES',
          description: 'La ingesta adecuada de fibra es crucial para el manejo y prevención de las hemorroides. Una dieta rica en fibra ayuda a mantener heces blandas y voluminosas, facilitando la evacuación y reduciendo la presión sobre las venas anales.',
          keywords: 'fibra, manejo de hemorroides, prevención de hemorroides, dieta rica en fibra, suplementos de fibra, hemorroides grado I, hemorroides grado II, salud digestiva',
          canonical: 'https://unidad-gastroenterologia.com/ingesta-fibra-hemorroides',
          link: '/ingesta-fibra-hemorroides',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Monica Suarez',
          sections: [
            {
              titleSection: 'La Importancia de la Fibra en el Manejo de las Hemorroides',
              content: [
                'La ingesta adecuada de fibra es fundamental en el manejo y prevención de las hemorroides. Una dieta rica en fibra ayuda a mantener heces blandas y voluminosas, lo que facilita la evacuación y reduce la necesidad de esfuerzo durante la defecación.',
                'Este enfoque disminuye la presión sobre las venas anales, ayudando a prevenir la formación o el agravamiento de las hemorroides.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Evidencia Científica sobre la Eficacia de la Fibra',
              content: [
                'Aunque algunos estudios han demostrado que los suplementos de fibra pueden reducir la persistencia de los síntomas y el sangrado, su impacto en otros síntomas como el dolor, el picor y el prolapso es menos claro.',
                'La evidencia científica sobre la eficacia de la fibra en el tratamiento de las hemorroides es moderada, por lo que su uso debe considerarse como parte de un enfoque integral.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/evidencia-fibra-hemorroides.jpg',
            },
            {
              titleSection: 'Fibra: Herramienta Útil, pero No una Cura Definitiva',
              content: [
                'Es importante destacar que, aunque la fibra puede aliviar los síntomas y prevenir la recurrencia de las hemorroides, no es una cura definitiva. Se recomienda como parte de un enfoque integral que incluya una dieta equilibrada, adecuada ingesta de líquidos y hábitos saludables.',
                'Es aconsejable consultar con un profesional de la salud para determinar el enfoque más adecuado para cada caso.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/fibra-como-herramienta.jpg',
            },
            {
              titleSection: 'Enfoque Integral para el Manejo de las Hemorroides',
              content: [
                'En resumen, la fibra es una herramienta útil en el manejo de las hemorroides, especialmente en los casos de grado I y II. Sin embargo, su eficacia varía según el individuo y la severidad de la condición.',
                'El tratamiento integral debe incluir cambios en la dieta, ejercicio y, en algunos casos, tratamientos médicos adicionales. Consultar con un profesional de la salud es esencial para encontrar el mejor enfoque para cada paciente.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/enfoque-integral-hemorroides.jpg',
            },
          ],
        },
        SonEfectivosLaxantesHemorroides: {
          title: 'SON EFECTIVOS LOS LAXANTES PARA LAS HEMORROIDES',
          description: 'Exploración sobre la efectividad de los laxantes en el tratamiento de las hemorroides, abordando los estudios realizados sobre el uso de fibra, placebo y otros tratamientos médicos.',
          keywords: 'laxantes para hemorroides, tratamiento de hemorroides, fibra y hemorroides, manejo de hemorroides, tratamiento médico hemorroidal, laxantes y estreñimiento',
          canonical: 'https://unidad-gastroenterologia.com/son-efectivos-los-laxantes-para-las-hemorroides',
          link: '/son-efectivos-los-laxantes-para-las-hemorroides',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Manejo Médico de las Hemorroides',
              content: [
                'Las opciones actuales para el manejo de las hemorroides incluyen tratamientos médicos orales, ligadura con bandas elásticas, escleroterapia, coagulación o fotocoagulación, y hemorroidectomía.',
                'La elección del tratamiento depende del grado de las hemorroides y de la severidad de los síntomas. El objetivo principal del manejo médico es disminuir el estreñimiento y el esfuerzo durante la evacuación, lo que ayuda a reducir la presión sobre las venas anales y a aliviar los síntomas.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Evidencia Científica sobre el Uso de Fibra',
              content: [
                'Diversos estudios han comparado el uso de fibra versus placebo en adultos con síntomas de enfermedad hemorroidal.',
                'Se utilizaron diferentes tipos de fibra, como ispaghula husk, plantago ovata, psyllium sterculia y fibra de trigo sin procesar, durante períodos de hasta 18 meses.',
                'Aunque la fibra mostró una mejoría significativa en el sangrado, no hubo una diferencia clara en la evolución de otros síntomas como el dolor, la comezón y el prolapso hemorroidal.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/uso-fibra-hemorroides.jpg',
            },
            {
              titleSection: 'Estudios Comparativos: Fibra vs Placebo',
              content: [
                'Los estudios mostraron que en el grupo que tomó fibra, entre el 16% y el 40% no mejoró, mientras que en el grupo que tomó placebo, entre el 23% y el 61% no mejoró.',
                'En cuanto al sangrado, la fibra tuvo una mejora significativa. Sin embargo, no se observó una diferencia en el dolor, las molestias anales, la comezón y el prolapso hemorroidal en comparación con el placebo.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/estudios-comparativos-fibra.jpg',
            },
            {
              titleSection: 'Fibra Asociada con Ligadura Hemorroidal',
              content: [
                'En un estudio comparativo entre ligadura hemorroidal con banda elástica y ligadura asociada a fibra, se observó una tasa de recurrencia significativamente menor en los pacientes que recibieron fibra (15%) en comparación con aquellos que solo se sometieron a la ligadura (45%).',
                'Esto sugiere que la fibra puede ser útil para prevenir la recurrencia de las hemorroides tras la ligadura.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/fibra-ligadura-hemorroidal.jpg',
            },
            {
              titleSection: 'Efectos Secundarios de la Fibra',
              content: [
                'En general, los efectos secundarios de la fibra son mínimos, y la mayoría de ellos son gastrointestinales, como flatulencia, distensión abdominal y sensación de plenitud.',
                'Estos efectos secundarios son generalmente temporales y disminuyen con el tiempo a medida que el cuerpo se adapta a la fibra.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/efectos-secundarios-fibra.jpg',
            },
            {
              titleSection: 'Recomendaciones para el Manejo de las Hemorroides',
              content: [
                'Para el manejo médico de las hemorroides sintomáticas, se recomienda una adecuada ingesta de agua y fibra, que deben ser la base del tratamiento.',
                'Cuando los síntomas son más severos, es posible que se requieran analgésicos y esteroides tópicos. Es importante adoptar una dieta equilibrada y hábitos saludables para complementar el tratamiento.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/recomendaciones-hemorroides.jpg',
            },
          ],
        },
        DeteccionTempranaCancerColorrectal: {
          title: '¿CÓMO PUEDE DETECTAR MÁS CASOS DE CÁNCER COLORRECTAL?',
          description: 'El cáncer colorrectal es la segunda causa de muerte por cáncer en México, pero con métodos como el OC FIT-CHEK®, se puede mejorar la detección temprana y aumentar la tasa de supervivencia.',
          keywords: 'cáncer colorrectal, detección temprana cáncer colorrectal, OC FIT-CHEK®, sangre oculta en heces, cáncer, prevención cáncer colorrectal, colonoscopia',
          canonical: 'https://unidad-gastroenterologia.com/deteccion-temprana-cancer-colorrectal',
          link: '/deteccion-temprana-cancer-colorrectal',
          className: 'publicaciones-page',
          image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
          author: 'Dr. Juan Antonio Sánchez Valle',
          sections: [
            {
              titleSection: 'Importancia del Diagnóstico Oportuno de Cancer Colorrectal',
              content: [
                'El cáncer colorrectal es la segunda causa principal de muerte por cáncer en México y afecta a ambos sexos por igual. A pesar de ser comúnmente diagnosticado en pacientes de edad avanzada, cada vez se detectan más casos en personas jóvenes, lo que resalta la importancia de un diagnóstico temprano para mejorar la sobrevida y la calidad de vida.',
                'El uso de métodos como el OC FIT-CHEK® ha demostrado una mayor sensibilidad y especificidad para la detección temprana de cáncer colorrectal, llevando a una mejor identificación de pólipos y cáncer en fases iniciales.'
              ],
              image: 'https://unidad-gastroenterologia.com/static/images/gastro_10.jpg',
            },
            {
              titleSection: 'Aumento de la Conformidad del Paciente',
              content: [
                'El método OC FIT-CHEK® elimina las restricciones dietéticas y medicinales, requiriendo solo una toma de muestra, lo que facilita su uso por parte de los pacientes sin interrumpir su rutina diaria. Esto resulta en un aumento significativo en la conformidad del paciente.',
                'El método más eficaz es el que realmente se realiza, por lo que facilitar la recolección de la muestra aumenta las probabilidades de detección temprana.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/conformidad-paciente-oc-fit.jpg',
            },
            {
              titleSection: 'Recolección Fácil de la Muestra',
              content: [
                'El proceso de recolección de la muestra con OC FIT-CHEK® es sencillo, permitiendo que el médico o el paciente recojan la muestra y la envíen al laboratorio para obtener resultados rápidos y precisos. Solo se requiere una muestra del dispositivo, eliminando la necesidad de varias tomas de muestras separadas.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/recoleccion-muestra-oc-fit.jpg',
            },
            {
              titleSection: 'Sin Restricciones Dietéticas',
              content: [
                'A diferencia de la prueba de guayaco, OC FIT-CHEK® no requiere restricciones en la dieta ni en medicamentos. Esto facilita el proceso para los pacientes, mejorando su experiencia y aumentando la tasa de participación.',
                'Este método ha sido ampliamente utilizado a nivel mundial, con más de 500 millones de pruebas realizadas, demostrando su eficacia y accesibilidad.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/sin-restricciones-dietarias.jpg',
            },
            {
              titleSection: 'Tecnología Avanzada en la Detección',
              content: [
                'El OC FIT-CHEK® utiliza un inmunoensayo altamente preciso que detecta específicamente la hemoglobina humana. Este método procesado mediante equipos como el OC-Auto Micro 80 y el OC-SENSOR Diana ofrece resultados rápidos y precisos, sin necesidad de contacto directo con la muestra, lo que reduce el riesgo de contaminación.',
                'Los equipos pueden procesar hasta 80 muestras por hora, lo que permite una detección eficiente y confiable.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/tecnologia-avanzada-oc-fit.jpg',
            },
            {
              titleSection: 'Inversión Atractiva para Profesionales de la Salud',
              content: [
                'El uso del método OC FIT-CHEK® ofrece un excelente retorno de inversión debido a la alta tasa de satisfacción de los pacientes y la eficiencia del proceso, lo que permite a los profesionales de la salud ofrecer un diagnóstico más preciso y rápido en comparación con otros métodos como la prueba de guayaco.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/inversion-atractiva-oc-fit.jpg',
            },
            {
              titleSection: 'El Futuro de la Detección del Cáncer Colorrectal',
              content: [
                'La detección temprana del cáncer colorrectal es fundamental para reducir la tasa de mortalidad. Con métodos como OC FIT-CHEK®, la tasa de detección temprana ha mejorado significativamente, lo que aumenta las posibilidades de tratamiento exitoso.',
                'Se ha demostrado que el 99.8% de los pacientes devuelven correctamente la muestra, lo que reduce la cantidad de colonoscopias innecesarias y proporciona una tasa de positividad más confiable.'
              ],
              // image: 'https://unidad-gastroenterologia.com/static/images/futuro-deteccion-cancer-colorrectal.jpg',
            },
          ],
        },
        
        
        
        
    },
  };
  
  export default DynamicPagesData;
  