const pagesData = {
  Laparoscopia: {
    title: "Cirugía Laparoscópica | Diagnóstico y Tratamiento Moderno",
    description: "Descubre la cirugía laparoscópica, una técnica quirúrgica mínimamente invasiva que permite el diagnóstico y tratamiento de enfermedades abdominales y pélvicas. Con una rápida recuperación, menos dolor postoperatorio y menor riesgo de complicaciones, es la opción ideal para quienes buscan procedimientos efectivos y con menos tiempo de inactividad.",
    keywords: "cirugía laparoscópica, laparoscopia abdominal, diagnóstico moderno, tratamiento mínimamente invasivo, gastroenterología, hernia hiatal, colecistectomía, cirugía de abdomen, cirugía sin cicatrices grandes",
    canonical: "https://unidad-gastroenterologia.com/Laparoscopia",
    image: "/assets/images/Unidades/laparoscopia-banner.webp",
    sections: [
      {
        "titleSection": "¿Qué es la Cirugía Laparoscópica?",
        "content": [
          "La cirugía laparoscópica es una técnica quirúrgica avanzada y mínimamente invasiva que permite a los cirujanos realizar intervenciones a través de pequeñas incisiones, utilizando una cámara de vídeo y otros instrumentos especializados.",
          "Este procedimiento reduce el riesgo de complicaciones, permite una recuperación más rápida, y minimiza el dolor postoperatorio, en comparación con las técnicas quirúrgicas tradicionales.",
          "La laparoscopia es especialmente útil para tratar diversas afecciones abdominales y pélvicas, incluyendo hernias, problemas de la vesícula biliar, apendicitis y enfermedades gastrointestinales."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/cirugia-laparascopica-img.jpeg"
      },
      {
        "titleSection": "¿Cómo se Realiza la Laparoscopia?",
        "content": [
          "El procedimiento comienza con la realización de una pequeña incisión, que varía entre 0.5 a 1.2 cm. A través de esta incisión, se insufla gas para crear un espacio en el abdomen, lo que permite al cirujano trabajar de manera más eficiente.",
          "Se introduce un laparoscopio, un tubo delgado con una cámara en su extremo, que transmite imágenes del interior del abdomen a un monitor de vídeo.",
          "El cirujano puede realizar más pequeñas incisiones o puertos para insertar otros instrumentos, como pinzas o tijeras, y realizar la intervención necesaria, ya sea para extirpar un órgano o reparar una afección.",
          "Al finalizar el procedimiento, se retiran los instrumentos, y las incisiones se cierran con suturas mínimamente invasivas."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/laparoscopia-1.gif"
      },
      {
        titleSection: "Beneficios de la Laparoscopia",
        content: [
          "Recuperación más rápida: Dado que las incisiones son pequeñas, el tiempo de recuperación es significativamente más corto que en la cirugía abierta.",
          "Menor dolor postoperatorio: La cirugía laparoscópica reduce el trauma en los tejidos, lo que resulta en menos dolor y una menor necesidad de analgésicos.",
          "Menos riesgo de complicaciones: Al ser mínimamente invasiva, hay menos probabilidad de infecciones, cicatrices grandes o hemorragias.",
          "Mejores resultados estéticos: Las cicatrices son mucho más pequeñas, lo que mejora la apariencia estética después de la cirugía."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/beneficios-malla-laparoscopia.jpg"
      },
      {
        titleSection: "Tipos Comunes de Cirugía Laparoscópica",
        content: [
          "Hernia inguinal: La reparación de la hernia a través de laparoscopia es menos invasiva, con menos dolor y una recuperación más rápida.",
          "Colecistectomía: La extirpación de la vesícula biliar utilizando laparoscopia es ahora el estándar, gracias a sus menores riesgos y recuperación rápida.",
          "Apendicectomía: En el caso de apendicitis aguda, la laparoscopia permite una intervención más precisa y menos traumática.",
          "Cirugía bariátrica: En algunos procedimientos para perder peso, la laparoscopia es la opción preferida debido a su efectividad y tiempos de recuperación reducidos."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/tipos-cirugia.gif"
      },
      {
        titleSection: "Recuperación Después de la Laparoscopia",
        content: [
          "La mayoría de los pacientes pueden regresar a sus actividades normales en unos pocos días, dependiendo del tipo de cirugía realizada.",
          "Es importante seguir las recomendaciones postoperatorias, como evitar actividades físicas intensas durante un tiempo determinado y realizar visitas de seguimiento para asegurarse de que la recuperación progresa adecuadamente.",
          "En general, los pacientes experimentan menos dolor y molestias que con la cirugía abierta, lo que permite una rehabilitación más rápida."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/alta-postoperatoria.jpeg"	
      },
      {
        titleSection: "¿Por Qué Elegir la Cirugía Laparoscópica?",
        content: [
          "La cirugía laparoscópica es la mejor opción para quienes buscan un procedimiento efectivo, menos invasivo y con tiempos de recuperación más cortos.",
          "En nuestra clínica, contamos con cirujanos altamente capacitados que utilizan equipos de última generación para garantizar la mejor atención y resultados para nuestros pacientes.",
          "La laparoscopia permite tratamientos de alta calidad para una variedad de condiciones, con una menor cantidad de complicaciones postoperatorias y una pronta reincorporación a la vida diaria."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/por-que-elegir.gif"
      }
    ]
  },

  Funduplicatura: {
    title: "Funduplicatura Laparoscópica | Tratamiento de Reflujo Gastroesofágico",
    description: "La funduplicatura laparoscópica es un procedimiento avanzado para tratar el reflujo gastroesofágico y mejorar la calidad de vida. Conoce más sobre esta técnica.",
    keywords: "funduplicatura laparoscópica, reflujo gastroesofágico, cirugía antirreflujo, gastroenterología avanzada, tratamiento de ERGE",
    canonical: "https://unidad-gastroenterologia.com/funduplicatura",
    image: "/assets/images/Unidades/funduplicatura-banner.webp",
    sections: [
      {
        titleSection: "Funduplicatura",
        content: [
          "La funduplicatura es un procedimiento quirúrgico utilizado para tratar el reflujo gastroesofágico (ERGE). Este procedimiento fortalece la válvula entre el esófago y el estómago.",
        ],
        // image: "/assets/images/Unidades/funduplicatura.webp"'
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Antes de tomar la decisión de realizar un procedimiento anti reflujo, la presencia de reflujo gastro-esofágico con todas las complicaciones que acompañan, como la esofagitis por reflujo o la formación de estenosis debe ser confirmada.",
          "La deglución de bario preoperatorio debe ser revisada para decartar esófago corto, estenosis esofágica.",
          "El vaciamiento gástrico y la pHmetria de 24 horas deben ser monitoreadas para correlación de dolor del paciente con episodios de reflujo gástrico.",
          "Debido al uso de medicamentos como la ranitidina o el omeprazol la aclorhidria gástrica puede permitir el sobrecrecimiento bacteriano, y por lo tanto los antibióticos peri operatorios deben ser administrados."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica.gif"
      },
      {
        titleSection: "Posición",
        content: ["El paciente se coloca en la posición de litotomía para permitir al cirujano operar entre las piernas del paciente. Esto permite un cómodo acceso al hiato esofágico."],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Insición y Establecimiento del Neumoperitoneo",
        content: ["La insición inicial es transversal y en el pliegue que se hace por encima del ombligo, se diseca en forma roma el tejido subcutáneo hasta encontrar la línea alba.",
          "Se toma la piel con dos pinzas de campo y se la levanta para insertar la aguja de Veress través de la fascia hasta la cavidad peritoneal.",
          "La punta de la aguja de Veress debe ser dirigida hacia los pies del paciente para evitar la lesión inadvertida de la aorta abdominal.",
          "Se inicia la insuflación Intra abdominal cosa que la cavidad abdominal se distiende uniformemente dando un sonido timpánico a la percusión. La presión intra-abdominal debe tener un aumento gradual. Una vez que la presión intra-abdominal ha alcanzado alrededor de 15 mmHg y se ha introducido 2,5 a 3 litros de CO2 se retira la aguja de Veress."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de Puertos",
        content: ["Cuatro puertos de exploración se sitúan a lo largo de un semicírculo frente al apéndice xifoides. El ayudante en el lado derecho del paciente opera la cámara. El paciente se coloca en una posición de Trendelenburg."],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-2.gif"
      },
      {
        titleSection: "Técnica de la Funduplicatura Laparoscópica",
        content: ["Después de la colocación de todos los puertos, se hace una abertura en el ligamento gastrohepático y se extiende por el esófago intra-abdominal. La disección de todo el hiato esofágico es similar a la del procedimiento abierto.",
          "Los dos pilares son claramente identificados, y la parte inferior de 3 a 4 cm. del esófago abdominal es movilizado. El esófago se retrae a la derecha, y el defecto hiatal se cierra con suturas de seda, prolene o ethibon. Para evitar la tensión excesiva del cierre se puede colocar una bujía esofágica de 60 P. Maloney.",
          "A continuación, con la ayuda de un bisturí armónico, se diseca los vasos cortos en la parte proximal de la curvatura mayor para poder liberarla.",
          "Un drenaje de Penrose se coloca alrededor del esófago abdominal para elevarlo hacia la pared abdominal anterior. Esto abre el espacio retroesofágica, mediante el cual el asistente puede captar el fondo de ojo previamente liberado y pasarlo por debajo y al lado izquierdo del esófago.",
          "Por último, el envoltorio del fondo, de aproximadamente 2 cm., se sutura de modo que queda fondo-esófago-fondo gástrico de una manera similar a la técnica abierta.",
          "Una vez finalizado el procedimiento, la zona del hiato esofágico es irrigada con solución salina. Los sitios del puerto se retiran bajo visión directa."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-3.gif"
      },
      {
        titleSection: "Cierre",
        content: ["Los defectos de la aponeurosis se cierran con puntos en cruz o en ocho con sutura 0-0 absorbibles y la piel se cierra con sub-dérmico de dermalon 3/0.",
        ]
      }
    ]
  },
  Apendicectomia: {
    title: "Apendicectomía Laparoscópica | Tratamiento de Apendicitis",
    description: "La apendicectomía laparoscópica es un tratamiento moderno para la apendicitis aguda. Menor tiempo de recuperación y mejores resultados.",
    keywords: "apendicectomía laparoscópica, cirugía de apendicitis, tratamiento quirúrgico moderno, gastroenterología avanzada",
    canonical: "https://unidad-gastroenterologia.com/apendicectomia-laparoscopica",
    image: "/assets/images/Unidades/funduplicatura-banner.webp",
    sections: [
      {
        titleSection: "Apendicectomía",
        content: [
          "La apendicectomía es una técnica quirúrgica utilizada para extraer el apéndice, un pequeño tubo con forma de dedo que se encuentra entre el intestino delgado y el grueso.",
          "Se realiza principalmente en casos de apendicitis aguda, cuando el apéndice está inflamado o infectado. La cirugía se lleva a cabo bajo anestesia general y puede realizarse de dos maneras:",
          "Apendicectomía abierta: Se hace una incisión en el lado derecho del abdomen y se extirpa el apéndice.",
          "Apendicectomía laparoscópica: Se realizan tres o cuatro pequeñas incisiones en el abdomen para quitar el apéndice de forma menos invasiva.",
        ],
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Una vez que se determina el diagnóstico de la apendicitis aguda y se toma la decisión de explorar al paciente, es imperativo hidratar al paciente de forma adecuada con líquidos intravenosos. Se debe administrar antibióticos por vía intravenosa.",
          "En los pacientes jóvenes, los estudios de gabinete son mínimos cuando el cuadro clínico es muy sospechoso de apendicitis.",
          "Si la masa es palpable en el cuadrante inferior derecho, la tomografía computarizada de abdomen y de pelvis puede ser valiosa para evaluar la presencia de absceso apendicular o bien un tumor maligno que se origina desde el ciego, el apéndice o el intestino delgado.",
          "Dependiendo de la experiencia del radiólogo, a menudo un ultrasonido puede ser utilizado para la visualización del apéndice engrosado"
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica.gif"
      },
      {
        titleSection: "Establecimiento de Neumoperitoneo",
        content: ["La insuflación intra-abdominal de CO2 es establecida en la forma habitual a través del puerto peri umbilical. El paciente se coloca en la posición de Trendelenburg para mejorar la visualización de la pelvis y el abdomen inferior.",
          "Por norma se realiza de examen de diagnóstico laparoscópico de toda la cavidad abdominal."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de los Puertos",
        content: ["Dos puertos están colocados, un puerto de 10-mm en la zona suprapúbica y un puerto de 5 mm en el cuadrante inferior izquierdo. Ambos están dirigidos hacia el apéndice."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica-1.gif"
      },
      {
        titleSection: "Técnica Quirúrgica",
        content: ["Con una técnica de dos manos, el cirujano expone el apéndice con el uso de una pinza atraumática. Una vez localizado, la punta del apéndice se toma con una pinza atraumática y se la levanta, hacia la pared abdominal.",
        "El meso apéndice se diseca cerca de la base del apéndice con una pinza curva y se liga, se engrapa o se corta con bisturí armónico.",
        "La base del apéndice se libera cuidadosamente de cualquier tejido adiposo que lo rodee y se seccionado en su base con engrapadota, corte y endolup o ligadura transfictiva endocorporal.",
        "El apéndice extirpado se coloca dentro de una bolsa de extracción.",
        "El cuadrante inferior derecho se riega con solución salina para eliminar cualquier material contaminado.",
        "Si se encuentra alguna dificultad durante la disección, el procedimiento debe ser convertido a una apendicetomía abierta.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica-2.gif"
      },
      {
        titleSection: "Cierre",
        content: ["Los defectos de la aponeurosis se cierran con puntos en cruz o en ocho con sutura 0-0 absorbibles y la piel se cierra con sub-dérmico de dermalon 3/0.",
        ]
      }
    ]
  },
  Colecistectomia: {
    title: "Colecistectomía Laparoscópica | Tratamiento de la Vesícula Biliar",
    description: "La colecistectomía laparoscópica es una cirugía mínimamente invasiva para tratar enfermedades de la vesícula biliar. Aprende sobre el procedimiento y sus beneficios.",
    keywords: "colecistectomía laparoscópica, cirugía de vesícula biliar, tratamiento quirúrgico, gastroenterología avanzada",
    canonical: "https://unidad-gastroenterologia.com/colecistectomia-laparoscopica",
    // image: "/assets/images/Unidades/colecistectomia-banner.webp",
    sections: [
      {
        titleSection: "COLECISTECTOMIA LAPAROSCÓPICA",
        content: [
          "La preparación preoperatoria para la colecistectomía laparoscópica es similar a la colecistectomía abierta, básicamente se requiere de ayuno de por lo menos 6 horas.",
        ],
        // image: "/assets/images/Unidades/funduplicatura.webp"
      },
      {
        titleSection: "Posición para Colecistectomia Laparoscópica",
        content: [
          "Se coloca al paciente en posición acostado boca arriba, y bajo anestesia general con intubación endotraqueal. Para evitar posibles lesiones inadvertidas en el estómago o la vejiga, a veces se coloca una sonda naso gástrica y un catéter vesical.",
          "El paciente está preparado y cubierto en la habitual forma estéril. Tubo de insuflación, los cables de la cámara, y el tubo de succión están garantizados. La cámara laparoscópica es a color.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/trocares-colecistectomia.gif"
      },
      {
        titleSection: "Incisión y establecimiento del Neumoperitoneo",
        content: ["Se hace una incisión en la piel en forma transversal por encima del ombligo, y con disección roma se llega a la línea alba. Con dos pinzas de piel el cirujano levanta la piel y la pared abdominal e inserta la aguja de Veress para insuflación de gas o directamente el trocar de 10 mm a través de la fascia en la cavidad peritoneal.",
          "La punta de la aguja de Veress debe ser dirigida hacia los pies del paciente para evitar la lesión inadvertida de la aorta abdominal.",
          "Para confirmar la presencia de la aguja dentro de la cavidad abdominal, se realiza una prueba de caída con una gota de agua: mientras la pared abdominal se levanta con las pinzas, una gota de solución salina se coloca en el centro de la aguja. La gota de solución salina debe caer rápidamente si la punta de la aguja de Veress está dentro de la cavidad peritoneal.",
          "Se inicia la insuflación abdominal, y la lectura de la presión inicial debe seguir siendo relativamente baja. Presiones de alta durante el inicio de la insuflación indican que la aguja probablemente no está bien colocado y debe ser reubicada o reinsertada."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de trocares para la Colecistectomia Laparoscopica",
        content: ["Un puerto de 10-mm, se coloca a través de la incisión transversal en ombligo. La cámara de vídeo se transmite a través de la cánula para realizar un examen cuidadoso para confirmar su presencia dentro de la cavidad intra abdominal, para excluir algún daño involuntario, y para ver la vesícula biliar.",
          "A continuación, tres puertos necesitan ser colocados a lo largo de la línea media, línea medio clavicular y la línea axilar anterior a lo largo de una línea recta imaginaria que es equivalente a la incisión tradicional de la cirugía abierta.",
          "La ubicación exacta de estos puertos, sin embargo, puede ser determinada por empujar la pared abdominal en el exterior y luego de confirmar que esta sería la ubicación adecuada de visualización con la cámara de vídeo.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica.gif"
      },
      {
        titleSection: "Técnica de Colecistectomia Laparoscopica",
        content: [
          "Bajo visión directa, primero un puerto de 10-mm, se coloca en la línea media de aproximadamente 5 a 6 cm por debajo del apéndice xifoides. A continuación, otro puerto de 5-mm se coloca en la línea clavicular media y en la línea axilar anterior.",
          "Uno de los fundamentos de la seguridad en la cirugía laparoscópica es que cada vez que un instrumento es pasado a través del puerto debe ser bajo visión directa, los instrumentos no debe ser insertado a ciegas, para evitar una lesión intra-abdominal.",
          "Antes que comience la disección, el paciente se coloca en la posición de Trendelenburg y una pinza pasa a través del puerto más lateral para tomar el fondo de la vesícula biliar y llevarla hacia el hombro derecho.",
          "Otra pinza se pasa a través del puerto medio clavicular para tomar el infundíbulo o cuello de la vesícula biliar, y se retraen lateralmente para colocar el conducto cístico en tensión.",
          "El revestimiento peritoneal en el triángulo de Calot es cuidadosamente disecado usando la tijera o el gancho laparoscopico. Se limpia el tejido graso de este lugar para poder identificar el conducto cístico. Con un disector de ángulo recto se diseca el conducto cístico y también su unión con el conducto biliar común el cual debe ser claramente visualizado. Es importante asegurarse de que la unión de la vesícula biliar con el conducto cístico para evitar una lesión inadvertida del conducto hepático derecho.",
          "Si es difícil identificar el conducto cístico se debe disecar el peritoneo de la vesícula biliar, el peritoneo que cubre a cada lado el lecho de la vesícula biliar para ser cuidadosamente dividida.",
          "Si la unión del conducto cístico con la vesícula biliar no está todavía identificada con seguridad, el procedimiento debe ser convertido a una colecistectomía abierta.",
          "La conversión a un procedimiento abierto no debería ser considerada una derrota, sino una decisión prudente por parte del cirujano.",

        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica-2.gif"
      },
      {
        content: [
          "Una vez identificado el conducto cístico se aplican dos clips proximales y dos clips distales y cortar conducto cístico. Con más disección roma y cortante en el triángulo de Calot, se identifica la arteria cística y se remontan a la pared de la vesícula biliar. Una vez bien identificada la arteria cística, es ligada con hemoclips y dividida.",
          "A continuación, se diseca la vesícula biliar de su lecho hepático. Para lograr esto, el infundíbulo de la vesícula biliar es captado junto a la hemoclips. Mientras que la vesícula biliar se gira de lado a lado, la cubierta peritoneal y el tejido areolar se dividen con el gancho del electro bisturí. A medida que la disección se acerca al fondo de la vesícula biliar, se reduce la retracción cefálica.",
          "El infundíbulo se retrae, la cama de la vesícula biliar es inspeccionada, y la hemostasia se completa.",
          "Una vez que la vesícula biliar es completamente libre de su lecho hepático, la cámara se transfiere al puerto que está en la parte superior del abdomen y con una bolsa laparoscópica que se introduce por el puerto umbilical se retira la vesícula biliar.",
          "La incisión umbilical puede ser necesario extenderse si hay dificultad para extracción de la vesícula biliar distendida por piedras grandes o inflamación importante de su pared. Cualquier derrame de bilis o de sangre es aspirado. Si se va colocar un drenaje, este se exterioriza a través del puerto más lateral y se dirige hacia la bolsa de Morison subhepático bajo visión directa."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica-3.gif"
      },
      {
        titleSection: "Cierre de la Pared Posterior a Colecistectomia",
        content: ["Los defectos de la fascia o apreturas de los puertos, en la región peri umbilical e infra xifoidea se cierran con suturas absorbibles. La piel se aproxima con puntos subdermicos. Una vez que el paciente está despierto en la sala de recuperación, la sonda naso gástrica y el catéter vesical puede ser retirados.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica-5.jpg"
      },
    ]
  },
  PlastiaInguinalConMalla: {
    title: "Plastia Inguinal con Malla | Tratamiento de Hernias Inguinales",
    description: "La plastia inguinal con malla es un procedimiento quirúrgico avanzado para tratar las hernias inguinales, ofreciendo mejores resultados y menor tasa de recidiva. Conoce cómo esta cirugía ayuda a la reparación de la pared abdominal.",
    keywords: "plastia inguinal con malla, hernia inguinal, cirugía de hernia, tratamiento de hernia, cirugía mínimamente invasiva, gastroenterología, reparación de hernia inguinal",
    canonical: "https://unidad-gastroenterologia.com/Laparoscopia/plastia-inguinal-con-malla",
    image: "/assets/images/Unidades/plastia-inguinal-banner.webp",
    sections: [
      {
        titleSection: "¿Qué es la Plastia Inguinal con Malla?",
        content: [
          "La plastia inguinal con malla es un procedimiento quirúrgico utilizado para reparar las hernias inguinales. La técnica se realiza mediante una incisión pequeña en la región inguinal, donde se inserta una malla para reforzar la pared abdominal debilitada.",
          "El uso de la malla ayuda a reducir la tasa de recidiva, mejorando los resultados a largo plazo. Esta cirugía se realiza bajo anestesia general o local y generalmente permite una recuperación más rápida en comparación con otros enfoques."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/malla-plastia.avif"
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Antes de la cirugía, es esencial realizar una evaluación completa del paciente para asegurar que no existan contraindicaciones para la cirugía. Esto incluye estudios de laboratorio, evaluación de la función cardiaca y pulmonar, y evaluación de los hábitos de vida.",
          "El paciente debe ayunar durante al menos 6 horas antes de la operación. Si el paciente toma medicamentos, estos deben ser revisados para determinar si es necesario suspender alguno antes de la intervención."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/evaluacion-preoperatoria-1024x683.jpg"
      },
      {
        titleSection: "Técnica Quirúrgica de la Plastia Inguinal con Malla",
        content: [
          "La cirugía comienza con una incisión en la región inguinal, a través de la cual el cirujano accede a la hernia. El saco herniario se identifica y se separa de los tejidos circundantes.",
          "Se coloca una malla sintética o biológica para reforzar la pared abdominal en el área debilitada. Esta malla es fijada en su lugar utilizando suturas o grapas.",
          "Una vez colocada la malla, el cirujano realiza una revisión minuciosa para asegurarse de que no haya complicaciones como sangrado o lesiones a órganos cercanos."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/hernia-inguinal-laparoscopia.png"
      },
      {
        titleSection: "Posición del Paciente y Técnica de Insuflación",
        content: [
          "El paciente es colocado en posición supina, con una ligera inclinación para facilitar el acceso al área inguinal. La insuflación abdominal no es necesaria en este tipo de cirugía, pero es importante que el cirujano tenga una buena visibilidad del área de trabajo.",
          "La anestesia general o local se administra para garantizar la comodidad del paciente durante todo el procedimiento."
        ],
        // image: "https://unidad-gastroenterologia.com/images/plastia-inguinal/posicion-paciente.gif"
      },
      {
        titleSection: "Cierre de la Incisión",
        content: [
          "Una vez que la malla está correctamente colocada y asegurada, el cirujano procede a cerrar la incisión. Se utilizan suturas absorbibles para cerrar la piel y los tejidos subyacentes.",
          "El proceso de cierre es importante para evitar complicaciones como infecciones o seromas. La cirugía generalmente dura entre 45 minutos y una hora."
        ],
        // image: "https://unidad-gastroenterologia.com/images/plastia-inguinal/cierre-incision.gif"
      },
      {
        titleSection: "Recuperación Postoperatoria",
        content: [
          "La recuperación después de la plastia inguinal con malla es generalmente rápida. El paciente puede ser dado de alta el mismo día o al día siguiente, dependiendo de la evolución.",
          "Es importante evitar actividades físicas intensas durante al menos 4-6 semanas para permitir que la malla se fije correctamente en la pared abdominal.",
          "El dolor postoperatorio suele ser mínimo y puede ser controlado con analgésicos comunes. Se recomienda usar ropa ajustada o un soporte abdominal para reducir la presión sobre la herida durante los primeros días."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/alta-postoperatoria.jpeg"	
      },
      {
        titleSection: "Complicaciones y Riesgos",
        content: [
          "Como en cualquier cirugía, existen algunos riesgos asociados con la plastia inguinal con malla. Las complicaciones más comunes incluyen infecciones, seromas (acumulación de líquido), y recurrencia de la hernia.",
          "En casos raros, pueden presentarse complicaciones más graves como daño a los nervios o problemas con la malla. El seguimiento postoperatorio es crucial para detectar cualquier problema de manera temprana."
        ],
        // image: "https://unidad-gastroenterologia.com/images/plastia-inguinal/complicaciones.gif"
      },
      {
        titleSection: "Cierre Final y Alta Médica",
        content: [
          "Al finalizar la cirugía, se realiza una revisión para asegurarse de que la hernia ha sido correctamente reparada y que no hay complicaciones inmediatas.",
          "El paciente recibe instrucciones claras sobre cómo cuidar la herida, cuándo retomar las actividades normales, y cómo manejar el dolor postoperatorio.",
          "El seguimiento a corto plazo es esencial para asegurar que no haya problemas con la malla y que la hernia no recurra."
        ],
        // image: "https://unidad-gastroenterologia.com/images/plastia-inguinal/cierre-final.gif"
      },
      {
        titleSection: "Beneficios de la Plastia Inguinal con Malla",
        content: [
          "El uso de malla en la reparación de hernias inguinales ha demostrado ser efectivo para reducir las tasas de recurrencia, proporcionando una solución duradera.",
          "La técnica laparoscópica con malla ofrece una recuperación más rápida y menos dolorosa en comparación con la cirugía abierta tradicional.",
          "Además, el procedimiento tiene menos riesgo de complicaciones como infecciones y hematomas, y permite una reincorporación más temprana a las actividades diarias del paciente."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/beneficios-malla-laparoscopia.jpg"
      },
      
    ]
  },
  Precios: {
    title: "Precios de Servicios Médicos | Unidad de Gastroenterología",
    description: "Consulta nuestros precios para tratamientos y diagnósticos médicos especializados en gastroenterología y cirugía laparoscópica. Atención accesible y profesional.",
    keywords: "precios médicos, costos de tratamientos, servicios médicos especializados, precios gastroenterología, cirugía laparoscópica, unidad de gastroenterología",
    canonical: "https://unidad-gastroenterologia.com/precios",
  },
  Endoscopia: {
    title: "Clínica de Endoscopia Digestiva",
  description: "La endoscopia digestiva es un procedimiento eficaz para diagnosticar y tratar afecciones como gastritis, úlceras y reflujo. Descubre nuestras soluciones personalizadas.",
  keywords: "endoscopia digestiva, diagnóstico médico, tratamiento de reflujo, gastritis, úlceras gástricas, gastroenterología avanzada",
  canonical: "https://unidad-gastroenterologia.com/Endoscopia-Digestiva",
  },
  Coloproctologia: {
    title: "Coloproctología | Diagnóstico y Tratamiento de Enfermedades Colorrectales",
  description: "Expertos en el cuidado del colon, recto y ano. Ofrecemos tratamientos avanzados para colitis, hemorroides y más. Atención médica de excelencia.",
  keywords: "coloproctología, tratamientos colorrectales, cuidado del colon, cirugía de hemorroides, gastroenterología avanzada",
  canonical: "https://unidad-gastroenterologia.com/Clinica-de-Coloproctologia",
  },
  
  CheckUp: {
    title: "Check-Up Médico | Monitoreo Integral de la Salud",
    description: "Nuestro Check-Up médico ofrece un monitoreo completo de tu estado de salud. Prevención y diagnóstico temprano de enfermedades.",
    keywords: "check-up médico, monitoreo de salud, diagnóstico temprano, prevención de enfermedades, unidad de gastroenterología",
    canonical: "https://unidad-gastroenterologia.com/check-up",
  },
  PhMetria: {
    title: "PhMetria y Manometría",
    description: "Procedimiento para medir el nivel de ácido en el esófago y evaluar el reflujo gastroesofágico.",
    keywords: "Dialización, reflujo gastroesofágico, gastroenterología, phmetria, manometría",
    canonical: "https://unidad-gastroenterologia.com/phmetria-y-manometria",
    image: "https://unidad-gastroenterologia.com/images/phmetria-y-manometria.jpg",
  },
  ReflujoHerniaHiatal: {
    title: "Tratamiento de Hernia Hiatal",
    description: "Explora cómo el ultrasonido abdominal y pélvico permite un diagnóstico preciso de enfermedades digestivas y pélvicas. Tecnología moderna para tu salud.",
    keywords: "ultrasonido abdominal, ultrasonido pélvico, ultrasonido endoanal, diagnóstico por imagen, gastroenterología, salud digestiva, tecnología médica",
    canonical: "https://unidad-gastroenterologia.com/Reflujo-Hernia-Hiatal", 
    image: "https://unidad-gastroenterologia.com/images/enfermedades/hernia-hiatal-a.gif",
    sections: [
      {
        titleSection: "Tratamiento Hernia Hiatal",
        image: "https://unidad-gastroenterologia.com/images/enfermedades/hernia-hiatal-a.gif",
        content: [
          "Explora cómo el ultrasonido abdominal y pélvico permite un diagnóstico preciso de enfermedades digestivas y pélvicas. Tecnología moderna para tu salud.",
          "Conoce las diferentes técnicas de tratamiento que pueden ayudar a tus pacientes con hernia hiatal, gastritis, síndrome del intestino irritable, entre otros.",
          "Aprende a tratar con ultrasonido abdominal y pélvico, y cómo puedes ayudar a tus pacientes con hernia hiatal, gastritis, síndrome del intestino irritable, entre otros.",
        ]
      },
    ]
  },

  Ultrasonido: {
    title: "Ultrasonido Abdominal y Pélvico | Diagnóstico Avanzado",
    description: "Explora cómo el ultrasonido abdominal y pélvico permite un diagnóstico preciso de enfermedades digestivas y pélvicas. Tecnología moderna para tu salud.",
    keywords: "ultrasonido abdominal, ultrasonido pélvico, ultrasonido endoanal, diagnóstico por imagen, gastroenterología, salud digestiva, tecnología médica",
    canonical: "https://unidad-gastroenterologia.com/Ultrasonido-Abdominal",
    image: "https://unidad-gastroenterologia.com/images/ultrasonido.jpg",
  },  
  Ultrasonido2: {
    title: "Ultrasonido General | Tecnología de Diagnóstico No Invasiva",
    description: "Obtén un diagnóstico confiable con ultrasonidos generales, abdominales y pélvicos. Rápido, seguro y efectivo para detectar enfermedades.",
    keywords: "ultrasonido general, ultrasonido no invasivo, diagnóstico médico, gastroenterología, salud pélvica, ultrasonido de alta precisión",
    canonical: "https://unidad-gastroenterologia.com/Ultrasonido",
    image: "https://unidad-gastroenterologia.com/images/ultrasonido.jpg",
  },
  Pacientes: {
    title: "Precios y Servicios Médicos | Unidad de Gastroenterología",
    description: "Conoce nuestros precios y servicios médicos especializados en gastroenterología. Atención personalizada y tecnología avanzada.",
    keywords: "precios de gastroenterología, servicios médicos, atención personalizada, tratamientos digestivos, consulta médica, unidad de gastroenterología",
    canonical: "https://unidad-gastroenterologia.com/pacientes",
  },
  Contact: {
    title: "Contacto | Unidad de Gastroenterología",
    description: "Contáctanos para consultas médicas especializadas en gastroenterología. Estamos aquí para ayudarte con atención profesional y personalizada.",
    keywords: "contacto gastroenterología, consulta médica, contacto unidad de gastroenterología, atención médica personalizada, horarios de atención",
    canonical: "https://unidad-gastroenterologia.com/contacto",
    image: "https://unidad-gastroenterologia.com/images/contacto.jpg",
  },
  Doctor: {
    title: "Equipo Médico Especializado | Unidad de Gastroenterología",
    description: "Conoce a nuestro equipo médico altamente capacitado en gastroenterología. Especialistas dedicados a tu salud digestiva.",
    keywords: "doctor gastroenterología, médicos especializados, equipo médico gastroenterología, especialistas en salud digestiva, atención médica profesional",
    canonical: "https://unidad-gastroenterologia.com/doctor",
    image: "https://unidad-gastroenterologia.com/images/doctor.jpg",
  },
  EquipoMedico: {
    title: "Equipo Médico | Unidad de Gastroenterología",
    description: "Nuestro equipo médico está formado por especialistas en gastroenterología comprometidos con brindar tratamientos basados en evidencia.",
    keywords: "equipo médico gastroenterología, especialistas en salud digestiva, médicos capacitados, atención médica profesional, tratamientos basados en evidencia",
    canonical: "https://unidad-gastroenterologia.com/Equipo-Medico",
    // image: "https://unidad-gastroenterologia.com/images/equipo-medico.jpg",
  },
  Enfermedades: {
    title: "Enfermedades Digestivas | Unidad de Gastroenterología",
    description: "Información detallada sobre enfermedades digestivas comunes. Diagnóstico y tratamientos avanzados en gastroenterología.",
    keywords: "enfermedades digestivas, diagnóstico médico, tratamiento de enfermedades gástricas, gastroenterología avanzada, salud digestiva, colitis, gastritis",
    canonical: "https://unidad-gastroenterologia.com/enfermedades",
  },
  

};

export default pagesData;
