// components/ScrollToTopContext.js
import { createContext, useContext, useState } from 'react';

const ScrollToTopContext = createContext();

export const useScrollToTopContext = () => useContext(ScrollToTopContext);

export const ScrollToTopProvider = ({ children }) => {
  const [shouldScroll, setShouldScroll] = useState(true);

  return (
    <ScrollToTopContext.Provider value={{ shouldScroll, setShouldScroll }}>
      {children}
    </ScrollToTopContext.Provider>
  );
};
