import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ServiceCard from './extras/ServiceCard.jsx';
import PreciosyServicios from '../components/PreciosyServicios.jsx'; // Asegúrate de importar los servicios

const ServiceListPH = ({ serviceToShow, moduleToShow }) => {
    // const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [originalPrices, setOriginalPrices] = useState({});
  
    // El orden personalizado para esta página (PH Metría)
    const customOrder = useMemo(() => [
        'Consulta Especializada',
        'Manometría Esofágica Convencional y de Alta Resolución',
        'Manometría Endoanal de Alta Resolución',
        'pHmetría Esofágica Convencional y con Impedancia',
        'Biofeedback o Electroestimulación Anal'
    ], []);
    
      const filteredServices = useMemo(() => {
        let filtered = Object.values(PreciosyServicios).filter(service =>
          Array.isArray(service.modules) && service.modules.includes(moduleToShow)
        );
      
        if (serviceToShow) {
          filtered = filtered.filter(service =>
            service.service.includes(serviceToShow)
          );
        }
      
        // Ordenar según el customOrder de manera parcial
        filtered.sort((a, b) => {
          const aIndex = customOrder.findIndex(order => a.name.toLowerCase().includes(order.toLowerCase()));
          const bIndex = customOrder.findIndex(order => b.name.toLowerCase().includes(order.toLowerCase()));
          return aIndex - bIndex;
        });
      
        return filtered;
      }, [serviceToShow, moduleToShow, customOrder]);  // Agregar customOrder aquí
  
    // Guardar precio original solo una vez
    useEffect(() => {
      filteredServices.forEach(service => {
        if (!originalPrices[service.id]) {
          setOriginalPrices(prevState => ({
            ...prevState,
            [service.id]: {
              conventional: service.priceVariations?.conventional?.price || 0,
              highResolution: service.priceVariations?.highResolution?.price || 0
            }
          }));
        }
      });
    }, [filteredServices, originalPrices]);
  
    const handleClose = () => setSelectedService(null);
    const handleShow = (service) => setSelectedService(service);
  
    return (
        <Container>
          <Row className="d-flex justify-content-center">
            {filteredServices.map((service) => (
              <Col md={6} lg={4} key={service.id} className="d-flex justify-content-center">
                <ServiceCard 
                  id={service.id} 
                  name={service.name} 
                  service={service.service.join(', ')} 
                  photoUrl={service.photoUrl} 
                  group={service.group} 
                  link={service.link}
                  price={originalPrices[service.id]?.conventional || service.priceVariations?.conventional?.price} // Usar el precio convencional guardado
                  needIva={service.priceVariations?.conventional?.NeedIva || ''} // Si no existe IVA, usar cadena vacía
                  onClick={() => handleShow(service)} // Expandir tarjeta al hacer clic
                />
              </Col>
            ))}
          </Row>

      {/* Modal para la información expandida del servicio */}
      {selectedService && (
        <Modal size="lg" show={true} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedService.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={selectedService.photoThumbnail} className="img-fluid mb-3 service-details-img-expand" alt={`Imagen de ${selectedService.name}`} />
            <p>{selectedService.descriptionSercice}</p>
            {selectedService.modules.includes('Gastroenterología') && selectedService.service.includes('Consulta') && (
              <>
                <h5>Consulta Especializada</h5> 
                <p>Nuestro equipo de Médicos Especialistas está comprometido a brindarle la mejor atención y
                  servicio a sus pacientes, garantizando así una atención médica de alta calidad y respaldada por lo ultimos avances en la Gastroenterología.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                {/* Mostrar precio convencional si está disponible */}
                <p className="text-uppercase">
                {selectedService.priceVariations?.conventional?.name}:&nbsp;
                <span className="text-card-price">
                    ${originalPrices[selectedService.id]?.conventional || selectedService.priceVariations?.conventional?.price} MXN {selectedService.priceVariations?.conventional?.NeedIva}
                </span>
                </p>

                {/* Mostrar precio de alta resolución si está disponible */}
                {selectedService.priceVariations?.highResolution && (
                <p className="text-uppercase">
                    {selectedService.priceVariations?.highResolution?.name}:&nbsp;
                    <span className="text-card-price">
                    ${originalPrices[selectedService.id]?.highResolution || selectedService.priceVariations?.highResolution?.price} MXN {selectedService.priceVariations?.highResolution?.NeedIva}
                    </span>
                </p>
                )}

              </>
            )}
            {selectedService.modules.includes('Gastroenterología') && selectedService.name.includes('Manometría Esofágica Convencional y de Alta Resolución') && (
              <>
                <h5>Manometría Esofágica</h5> 
                <p> La manometría esofágica es un procedimiento diagnostico que evalúa la motilidad del esófago, midiendo la presión y la coordinación de las contracciones musculares en este organo. Es particularmente útil para diagnosticar trastornos como acalasía, reflujo gastroesofágico y otro problemas de deglución. Es realizada por un médico gástroenterologo con alta especialidad en fisiología digestiva.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <p>Todos los estudios se realizan en <span className='text-primary'>Medicci: Unidad de Gastroenterología y Endoscopia Digestiva</span>, en sala de Endoscopia y bajo sedación anestésica.</p>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                <h6 className="text-uppercase py-3">¿Qué costo tiene una {selectedService.name}?</h6>
                {/* Mostrar precio convencional si está disponible */}
            <p className="text-uppercase">
              {selectedService.priceVariations?.conventional?.name}:&nbsp;
              <span className="text-card-price">
                ${originalPrices[selectedService.id]?.conventional || selectedService.priceVariations?.conventional?.price} MXN {selectedService.priceVariations?.conventional?.NeedIva}
              </span>
            </p>

            {/* Mostrar precio de alta resolución si está disponible */}
            {selectedService.priceVariations?.highResolution && (
              <p className="text-uppercase">
                {selectedService.priceVariations?.highResolution?.name}:&nbsp;
                <span className="text-card-price">
                  ${originalPrices[selectedService.id]?.highResolution || selectedService.priceVariations?.highResolution?.price} MXN {selectedService.priceVariations?.highResolution?.NeedIva}
                </span>
              </p>
            )}
              </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('Manometría Endoanal de Alta Resolución') && (
              <>
              <h4 className='text-primary text-uppercase'>{selectedService.typeService} {selectedService.name}</h4>
              <p>La Manometria Endoanal es una  herramienta de diagnóstico de vanguardia diseñada para evaluar la función del esfínter anal y la sensación rectal, ¡incluso antes de que llegues a la clínica!</p>
              <p>La manometría endoanal evalúa la función del esfínter anal y la sensibilidad de la región anal. Este procedimiento se utiliza comúnmente para investigar trastornos anales, como la incontinencia fecal, el estreñimiento crónico y otros problemas relacionados con la función anal.</p>
              <p className='h5 text-primary'>Objetivos de la Manometría Endoanal</p>
              <ul>
                <li><span className='text-primary'>Determinar la Sensibilidad Anal:</span> Evalúa la capacidad del paciente para percibir estímulos (tacto y presión) en la región anal.</li>
                <li><span className='text-primary'>Observar la Función de Relajación:</span> En ciertas maniobras, es importante observar cómo el esfínter anal se relaja, especialmente durante el intento de evacuación.</li>
              </ul>
              <p className='h5 text-primary'>Cómo se realiza la Manometria Endoanal.</p>
              <ul>
                <li><span className='text-primary'>Preparación:</span> Se puede solicitar al paciente que siga algunas indicaciones, como no comer durante ciertas horas antes del examen.</li>
                <li><span className='text-primary'>Ejecución:</span> Se inserta un catéter flexible en la región anal, y se utilizan sensores para medir las presiones. El examen puede incluir la realización de maniobras como contracciones voluntarias e intentos de evacuación.</li>
                <li><span className='text-primary'>Duración:</span> Generalmente, el examen dura entre 15 y 30 minutos.</li>
              </ul>
              <p className='h5 text-primary'>Interpretación de los Resultados</p>
              <p>Los resultados son analizados por un médico especialista, quien puede determinar si hay alguna anormalidad en la función del esfínter, la sensibilidad o otras condiciones que puedan contribuir a los síntomas del paciente.</p>
              <p className='h5 text-primary'>Conclusión.</p>
              <p>La manometría endoanal es una herramienta importante para el diagnóstico y tratamiento de trastornos anales. Si está considerando este examen, es esencial discutir todas sus preocupaciones y las implicaciones de los resultados con un profesional de salud calificado.</p>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                </span>
              </p>
            </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('Biofeedback o Electroestimulación Anal') && (
              <>
              <h4 className='text-primary text-uppercase'>{selectedService.typeService} {selectedService.name}</h4>
              <p>El biofeedback es una técnica que implica el uso de dispositivos de monitoreo electrónico para transmitir información sobre los procesos fisiológicos del cuerpo en este caso de la función de los músculos del complejo esfintérico anal.</p>
              <p>El objetivo es mejorar la salud aprendiendo a controlar la funciones corporales de contracción y relajación del los esfínteres anales.</p>
              <p className='h5 text-primary'>Propósito del Biofeedback.</p>
              <ul>
                <li>Ayudar a las personas a tomar conciencia y controlar la continencia y el mecanismo de defecación.</li>
              </ul>
              <p className='h5 text-primary'>Cómo Funciona:</p>
              <ul>
                <li>Se colocan sensores en el ano para medir señales fisiológicas de construcción y relajación.</li>
                <li>Estas señales se muestran en un monitor en tiempo real, lo que permite a las personas ver las respuestas de su cuerpo.</li>
                <li>Se utiliza retroalimentación para enseñar al participante cómo modificar sus respuestas físicas, promoviendo la relajación y el control.</li>
              </ul>
              <p className='h5 text-primary'>Aplicaciones.</p>
              <ul>
                <li>Mejorar la continencia fecal.</li>
                <li>Rehabilitación de lesiones.</li>
                <li>Mejora la relajación y el mecanismo de defecación.</li>
                <li>Tratamiento de condiciones médicas específicas como.
                  <ul className='fw-semibold'>
                    <li>Incontinencia fecal</li>
                    <li>Disfunciones del piso pélvico</li>
                    <li>Anismus</li>
                    <li>Dolor anal crónico</li>
                    <li>Proctalgia fugax</li>
                  </ul>
                </li>
              </ul>
              <p className='h5 text-primary'>Consideraciones:</p>
              <ul className='unstyled'>
                <li>El biofeedback debe usarse como un enfoque complementario a los tratamientos médicos tradicionales.</li>
                <li>Las personas pueden necesitar entrenamiento de un profesional certificado en biofeedback para entender e interpretar las señales de manera efectiva.</li>
              </ul>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva} por Sesión
                </span>
              </p>
            </>
            )}
            {selectedService.modules.includes('Gastroenterología') && selectedService.service.includes('Phmetria') && (
              <>
                <h5>{selectedService.name}</h5> 
                <p>La pHMetría esofágica es un estudio que mide el pH del contenido esofágico para evaluar la presencia y la frecuencia del reflujo ácido. Este procedimiento es útil para diagnosticar transtornos como el reflujo gastroesofágico. Para llevar a cabo la pHMería se introduce un sensor a traves de la nariz y se coloca en el esofágo, donde permanece durante 24 horas para registrar los niveles de acidez. Este procedimiento lo realiza un Gástroenterologo con alta especialidad en fisiología digestiva.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}
                <p className='h5 text-primary'>{selectedService.promotion}</p>
                
                {selectedService.included && (
                <>
                    <h6>Incluye:</h6>
                    <ul>
                    {selectedService.included.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </>
                )}

                {selectedService.excluded && (
                <>
                    <h6>No incluye:</h6>
                    <ul>
                    {selectedService.excluded.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                </>
                )}
            </Modal.Body>
            <Modal.Footer>
            <a
                href={`https://api.whatsapp.com/send?phone=5215534816054&text=Hola!%2C%20Me%20interesa%20${encodeURIComponent(selectedService.service)}%20de%20${encodeURIComponent(selectedService.name)}%2C%20podrias%20darme%20mas%20informes%3F`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Button variant="secondary">
                Más Información
                </Button>
            </a>
            <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default ServiceListPH;
