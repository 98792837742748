import React, { useState, useCallback } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import '../assets/css/ContactSection/styles.css';
import { locations } from './extras/Direcciones';

const Map = React.lazy(() => import('./Map'));

function ContactSection() {
  const [activeTab, setActiveTab] = useState('menu1');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = locations.location1.whatsapp;
    const messageBody = `Nombre: ${name}%0AEmail: ${email}%0AAsunto: ${subject}%0AMensaje: ${message}`;
    window.open(`https://wa.me/${whatsappNumber}?text=${messageBody}`, '_blank');
  };

  const activeLocation = locations[`location${activeTab.replace('menu', '')}`];
  const mapSrc = {
    menu1: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.26775114840035!2d-99.18472500000001!3d19.356851!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff479106075%3A0x225b7743e5e0d0b5!2sMEDICCI%20Unidad%20de%20Gastroenterolog%C3%ADa!5e0!3m2!1ses!2sus!4v1714846621873!5m2!1ses!2sus",
    menu2: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.193782946831!2d-99.17257604174053!3d19.408062034771806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff602026717%3A0x3be1126e6f314f52!2sMedicci%20Condesa%2C%20Unidad%20de%20Gastroenterolog%C3%ADa%20y%20Endoscopia%20Digestiva!5e0!3m2!1ses-419!2smx!4v1715483335410!5m2!1ses-419!2smx",
    menu3: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.35694204232857!2d-99.1562328!3d19.2949269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce005766e2ae35%3A0x3b037f8c0652f7e7!2sMedicci%2C%20Tlalpan%20Zona%20de%20Hospitales!5e0!3m2!1ses-419!2smx!4v1715456127773!5m2!1ses-419!2smx"
  }[activeTab];

  return (
    <section id="contact" className="contact">
      <Container>
        <div>
          <h4 className='text-primary h1'>
            Ubica tu Clínica más cercana
          </h4>
        </div>
      </Container>
      <Container className='mt-5'>
        <Nav className="nav nav-tabs d-flex justify-content-center">
          <Nav.Item className='mt-3'>
            <Nav.Link
              onClick={() => handleTabChange('menu1')}
              className={activeTab === 'menu1' ? 'active' : ''}
            >Medicci Guadalupe Inn</Nav.Link>
          </Nav.Item>
          <Nav.Item className='mt-3'>
            <Nav.Link
              onClick={() => handleTabChange('menu2')}
              className={activeTab === 'menu2' ? 'active' : ''}
            >Medicci Condesa</Nav.Link>
          </Nav.Item>
          <Nav.Item className='mt-3'>
            <Nav.Link
              onClick={() => handleTabChange('menu3')}
              className={activeTab === 'menu3' ? 'active' : ''}
            >Medicci Tlalpan Zona de Hospitales</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="tab-content">
          {['menu1', 'menu2', 'menu3'].map((tab) => (
            <div key={tab} className={`tab-pane fade ${activeTab === tab ? 'show active' : ''}`}>
              <Row className="gy-4 mt-3">
                <Col md={6}>
                  <div className="info-item d-flex align-items-center">
                    <i className="bi bi-map flex-shrink-0 icon"></i>
                    <div>
                      <h3>Dirección</h3>
                      <p>{activeLocation.address}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="info-item d-flex align-items-center">
                    <i className="bi bi-envelope flex-shrink-0 icon"></i>
                    <div>
                      <h3>Email</h3>
                      <p>{activeLocation.email}</p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="info-item d-flex align-items-center justify-content-center">
                    <div>
                      <h3>Teléfonos</h3>
                      <div className='d-flex align-items-center'>
                        <i className="bi bi-telephone flex-shrink-0 icon-secondary icon-blue"></i>
                        <a className="text-decoration-none fs-4 ms-2" href={`tel:${activeLocation.phone}`}>+52 {activeLocation.phone}</a>
                      </div>
                      <div className='d-flex align-items-center mt-3'>
                        <i className="bi bi-whatsapp flex-shrink-0 icon-secondary icon-green"></i>
                        <a
                          href={`https://api.whatsapp.com/send?phone=52${activeLocation.whatsapp}`}
                          className="fs-4 ms-2 text-decoration-none"
                          target="_blank"
                          rel="noreferrer">+52 {activeLocation.whatsapp}</a>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="info-item d-flex align-items-center">
                    <i className="bi bi-share flex-shrink-0 icon"></i>
                    <div className='d-flex flex-column justify-content-center col'>
                      <h3>Horario de Trabajo</h3>
                      <div>
                        <strong>Lunes a Viernes:</strong> 8AM - 6PM;<br></br><strong>Sábado:</strong> 7AM - 3PM<br></br> <strong>Domingo:</strong> Cerrado
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  {activeTab === tab && (
                    <React.Suspense fallback={<div>Cargando mapa...</div>}>
                      <Map src={mapSrc} />
                    </React.Suspense>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </Container>
      

      <Container fluid className='mt-5 fondo-contacto'>
        <div className='p-4'>
          <h3 data-aos="fade-in" className='text-primary h1'>
            ¿Tienes dudas?<span className='text-white'><br />¡Contáctanos!</span>
          </h3>
        </div>
        <form onSubmit={handleSubmit} id="contactForm" className="p-3 p-md-4 contactForm">
          <Row className='d-flex'>
            <Col xl={6} className=" d-flex flex-column">
              <Col className="form-group">
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Tu Nombre" required />
              </Col>
              <Col className="form-group">
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Tu Email" required />
              </Col>
              <Col className="form-group">
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" placeholder="Asunto" required />
              </Col>
              <Col className="form-group">
                <input type="text" className="form-control" placeholder="5+11" required />
              </Col>
            </Col>
            <Col xl={6} className="form-group mt-3">
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="form-control" rows="5" placeholder="Mensaje" required></textarea>
              <div className="my-3">
                <div className="loading">Cargando</div>
                <div className="error-message"></div>
                <div className="sent-message">¡Tu mensaje fue enviado con éxito!</div>
              </div>
              <div className="text-center">
                <button className='php-email-form' type="submit">Enviar Mensaje</button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </section>
  );
}

export default ContactSection;