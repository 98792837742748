import React, { useState, Suspense, lazy } from 'react';
import DoctorList from './DoctorList';
const BolsaDeTrabajo = lazy(() => import('./BolsaDeTrabajo.jsx'));


const DoctoresSection = ({ pageData }) => {
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);

  const handleSpecialtySelect = (specialty) => {
    setSelectedSpecialty(specialty);
  };

  return (
    <main className="doctor-section">
{/* SVG  */}
      <div className='doctor-section-content'>
        <h2>Selecciona una especialidad:</h2>
        <div className="button-group">
          <button 
            className={`button-menu-doctor ${selectedSpecialty === 'Coloproctología' ? 'active' : ''}`} 
            onClick={() => handleSpecialtySelect('Coloproctología')}
          >
            Coloproctología
          </button>
          <button 
            className={`button-menu-doctor ${selectedSpecialty === 'Gastroenterología' ? 'active' : ''}`} 
            onClick={() => handleSpecialtySelect('Gastroenterología')}
          >
            Gastroenterología
          </button>
          <button 
            className={`button-menu-doctor ${selectedSpecialty === 'Psicologia' ? 'active' : ''}`} 
            onClick={() => handleSpecialtySelect('Psicologia')}
          >
            Psicología
          </button>
          
          {/* Botón para mostrar todos */}
          <button 
            className={`button-menu-doctor ${!selectedSpecialty ? 'active' : ''}`} 
            onClick={() => setSelectedSpecialty(null)}
          >
            Mostrar Todos
          </button>
        </div>
        
        {/* Lista de Doctores */}
        <DoctorList specialtyToShow={selectedSpecialty} />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <BolsaDeTrabajo/>
      </Suspense>

    </main>

    
  );
};

export default DoctoresSection;
