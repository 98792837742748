import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ServiceCard2 = ({ 
  service = {
    name: 'Servicio no disponible',
    photoUrl: 'https://via.placeholder.com/150',
    priceVariations: {
      conventional: {
        price: 100000,
        NeedIva: '',
      },
    },
    included: [],
  } 
}) => {
  const [originalPrices, setOriginalPrices] = useState({});

  const name = service.name || 'Servicio no disponible';
  const photoUrl = service.photoUrl || 'https://via.placeholder.com/150';
  const price = service.priceVariations?.conventional?.price || 0;
  const needIva = service.priceVariations?.conventional?.NeedIva || '';
  const included = Array.isArray(service.included) ? service.included : [];
  const serviceId = service.id; // Usamos el ID del servicio como clave única

  // Guardamos el precio original solo una vez, cuando el componente se monta
  useEffect(() => {
    // Verifica si el precio ya está almacenado, de no ser así, lo guarda
    if (!originalPrices[serviceId]) {
      setOriginalPrices(prevState => ({
        ...prevState,
        [serviceId]: price, // Guardamos el precio original
      }));
    }
  }, [serviceId, price, originalPrices]); // Dependencias: Solo ejecuta cuando cambia el serviceId o el precio

  // Enlace de WhatsApp
  const whatsappLink = `https://api.whatsapp.com/send?phone=5215534816054&text=Hola!%2C%20Me%20interesa%20el%20servicio%20de%20Check%20Up%20${encodeURIComponent(
    name
  )}%20Podrías%20darme%20más%20información%3F%20El%20precio%20es%20${encodeURIComponent(
    originalPrices[serviceId] || price // Compara precio original || precio final
  )}%20MXN%20${encodeURIComponent(needIva)}`;

  const handleWhatsappClick = () => {
    // Lógica adicional antes de enviar el precio
    console.log('Precio final enviado al WhatsApp:', originalPrices[serviceId] || price);
  };

  return (
    <div className="service-details my-4">
      <h4 className="text-uppercase text-primary">{name}</h4>
      <img src={photoUrl} className="card-img-top img-fluid" alt={`Imagen de ${name}`} />
      <p className="text-price">Costo:</p>
      <p className='text-bold'>${originalPrices[serviceId] || price} MXN {needIva}</p>
      <h5 className="text-primary">Incluye:</h5>
      <ul>
        {included.map((item, index) => (
          <li key={index}><i className="bi bi-check-lg"></i>{item}</li>
        ))}
      </ul>
      <a
        href={whatsappLink} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="btn-contacto btn-general"
        onClick={handleWhatsappClick}
      >
        ¡Pedir Ahora!
      </a>
    </div>
  );
};

ServiceCard2.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number.isRequired, // Confirmar si el servicio tiene un ID único
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    priceVariations: PropTypes.shape({
      conventional: PropTypes.shape({
        price: PropTypes.number,
        NeedIva: PropTypes.string,
      }),
    }),
    included: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ServiceCard2;
