// App.js
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import NavBar from './components/NavBar';
import RoutesConfig from './components/RoutesConfig';
import ScrollToTop from './components/ScrollTop';
import { ScrollToTopProvider } from './components/ScrollToTopContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { locations } from './components/extras/Direcciones';
import BotonWsp from './components/extras/BotonWsp';
import ThemeContext from './components/extras/ThemeContext';

// Lazy load para componentes que no se necesitan de inmediato
const ContactSection = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));

const whatsappNumber = locations.location1.whatsapp;
const phoneNumber = locations.location1.phone;

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      document.documentElement.setAttribute('data-bs-theme', savedTheme);
      setIsDarkMode(savedTheme === 'dark');
    }
  }, []);
  
  const toggleTheme = () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    document.documentElement.setAttribute('data-bs-theme', newTheme);
    localStorage.setItem('theme', newTheme);
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router
      future={{
        v7_startTransition: true,  // Habilita la transición de React.startTransition
        v7_relativeSplatPath: true // Habilita la nueva resolución de rutas relativas en Splat
      }}
    >
      <ScrollToTopProvider>
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
          <ScrollToTop />
          <HelmetProvider>
            <div className="App">
              <NavBar />
              <RoutesConfig />
              <BotonWsp whatsappNumber={whatsappNumber} phoneNumber={phoneNumber} />
              <Suspense fallback={<div>Cargando Seccion de Mapas, Si no Carga Reinicia la Página...</div>}>
                <ContactSection />
                <Footer />
              </Suspense>
            </div>
          </HelmetProvider>
        </ThemeContext.Provider>
      </ScrollToTopProvider>
    </Router>
  );
}

export default App;
