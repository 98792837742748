import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DynamicPagesData from '../assets/data/DynamicPagesData'; // Ajusta el path según tu estructura

const PublicacionesPage = () => {
  const publicaciones = DynamicPagesData.Publicaciones; // Accedemos a las publicaciones

  return (
    <>
      <Helmet>
        <title>Publicaciones - Unidad de Gastroenterología</title>
        <meta
          name="description"
          content="Explora nuestras publicaciones sobre temas de gastroenterología y coloproctología."
        />
        <meta
          name="keywords"
          content="publicaciones, gastroenterología, coloproctología, salud digestiva"
        />
      </Helmet>

      <section>
        <Container>
          <h1 className="text-center my-4 text-primary">Publicaciones</h1>
          <Row>
            {Object.entries(publicaciones).map(([key, publicacion]) => (
              <Col md={4} className="mb-4" key={key}>
                <div className="card-publicaciones">
                  <img
                    src={publicacion.image}
                    alt={publicacion.title}
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{publicacion.title}</h5>
                    <p className="card-text">
                      {publicacion.description.length > 100
                        ? `${publicacion.description.substring(0, 100)}...`
                        : publicacion.description}
                    </p>
                    <Link
                      to={publicacion.link}
                      className="btn-publicaciones"
                    >
                      Leer más
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PublicacionesPage;
