import React, { useEffect, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../assets/css/UnidadesPages/styles.css';
// import ServiceListPH from '../components/ServiceListPH.jsx';
import { useScrollToTopContext } from '../components/ScrollToTopContext';
// import ServiceList from '../components/ServiceList.jsx';
const CarouselSeguros = lazy(() => import('../components/Carousel-Seguros.jsx'));

const LaparoscopiaPage = ({ pageData }) => {
  // const [selectedService, setSelectedService] = useState(null);
  
  //   const handleServiceSelect = (service) => {
  //     setSelectedService(service);
  //   };
    const { setShouldScroll } = useScrollToTopContext();

  useEffect(() => {
    // Desactiva el scroll hacia arriba para esta página
    setShouldScroll(false);

    // Vuelve a habilitarlo cuando se desmonte el componente
    return () => {
      setShouldScroll(true);
    };
  }, [setShouldScroll]);
  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <div className="unidades-section">
        <h1>Cirugía Laparoscópica</h1>
        <div className="p-5 row">
          <div className="col-md-4">
            <img src="https://unidad-gastroenterologia.com/images/laparoscopia/laparascopio.jpg" loading='lazy' alt="Cirugía Laparoscópica" className="img-fluid mb-3 rounded-5"/>
          </div>
          <div className="col-md-8">
            <p>Es un procedimiento que permite que el cirujano visualice los órganos del abdomen, como intestinos, estómago, apéndice y vesícula biliar, y en mujeres los órganos pélvicos, como los ovarios, útero y en algunos casos practique una intervención quirúrgica para reparación (cistocele) o extirpación en dichos órganos (apendicectomía).</p>
            <p>La Cirugía Laparoscópica o Laparoscopia es un tipo de cirugía en la que en lugar de hacer una incisión para exponer la cavidad abdominal se inserta un tubo fino y largo en el abdomen que permite visualizar los órganos abdominales con una cámara de vídeo.</p>
            <p>Habitualmente, la cirugía laparoscópica da lugar a una estancia hospitalaria más breve que la tradicional. Dado que las incisiones relacionadas con la laparoscopia son muy pequeñas, el dolor que se asocia con el procedimiento es menor y la recuperación más rápida.</p>
            </div>
          </div>
        </div>
        {/* <Container className='doctor-section-content'>
          <h2 className='color-text-primary fw-bold fz-2-5'>Servicios de Cirugía Laparoscópica:</h2>
          <h4 className='p-2 p-lg-5 text-start text-lg-center'>Bienvenido a nuestra Clínica de Cirugía Laparoscópica, un centro especializado en procedimientos quirúrgicos mínimamente invasivos para el tratamiento de diversas condiciones abdominales y pélvicas.</h4>
          <div className='text-start p-3 p-lg-5 fz-1-1'>
            <p>Nos destacamos por utilizar técnicas de vanguardia como la cirugía laparoscópica, que permite una recuperación más rápida y con menos dolor postoperatorio.</p> 
            <p>Contamos con un equipo de cirujanos altamente capacitados, expertos en la realización de procedimientos de alta precisión, como la reparación de hernias, colecistectomías y apendicectomías, asegurando resultados óptimos y tiempos de recuperación mínimos.</p> 
          </div>
          <div>
            <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
            <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
            <button className={`button-menu-doctor ${selectedService === 'Procedimiento' ? 'active' : ''}`} onClick={() => handleServiceSelect('Procedimiento')}>Procedimiento</button>
            <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
          </div>
          <ServiceListPH serviceToShow={selectedService} moduleToShow="Laparoscopia" />
        </Container> */}
        <div>
          <h4 className='m-4 color-text-primary fw-bold fz-2-5'>Información relacionada con la cirugía laparoscópica:</h4>
          <div className='d-flex flex-column flex-md-row justify-content-center'>
            {/* <Link to="/Laparoscopia" className={`button-menu-doctor ${pageData.title === 'Laparoscopía' ? 'active' : ''}`}>Laparoscopía</Link> */}
            <Link to="/Laparoscopia/Colecistectomia" className={`button-menu-doctor ${pageData.title === 'Colecistectomía' ? 'active' : ''}`}>Colecistectomía</Link>
            <Link to="/Laparoscopia/Funduplicatura" className={`button-menu-doctor ${pageData.title === 'Funduplicatura' ? 'active' : ''}`}>Funduplicatura</Link>
            <Link to="/Laparoscopia/Apendicectomia" className={`button-menu-doctor ${pageData.title === 'Apendicectomía' ? 'active' : ''}`}>Apendicectomía</Link>
            <Link to="/Laparoscopia/plastia-inguinal-con-malla" className={`button-menu-doctor ${pageData.title === 'PlastiaInguinalConMalla' ? 'active' : ''}`}>Plastia Inguinal con Malla</Link>
          </div>
        </div>
        <section className="unidades-section">
          <Container fluid>
            <div className="my-5">
              {pageData.sections && pageData.sections.map((section, index) => (
                <div key={index} className="">
                  {index === 0 ? (
                    <h1>{section.titleSection}</h1>
                  ) : (
                    <h2>{section.titleSection}</h2>
                  )}
                  <Row className='p-5'>
                    {section.image ? (
                      index % 2 === 0 ? (
                        <>
                          <Col md={4}>{<img src={section.image} alt={section.titleSection} loading='lazy' className="img-fluid mb-3 rounded-5" />}</Col>
                          <Col md={8}>{section.content.map((paragraph, i) => (
                            <p key={i}>{paragraph}</p>
                          ))}</Col>
                        </>
                      ) : (
                        <>
                          <Col md={8}>{section.content.map((paragraph, i) => (
                            <p key={i}>{paragraph}</p>
                          ))}</Col>
                          <Col md={4}>{<img src={section.image} alt={section.titleSection} loading='lazy' className="img-fluid mb-3 rounded-5" />}</Col>
                        </>
                      )
                    ) : (
                      <Col>
                        {section.content.map((paragraph, i) => (
                          <p key={i}>{paragraph}</p>
                        ))}
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
            </div>
          </Container>
          <Suspense fallback={<div className="loader">Cargando seguros..</div>}>
            <CarouselSeguros/>
          </Suspense>
          
        </section>
    </>
  );
};

export default LaparoscopiaPage;
