// components/RoutesConfig.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import GastroenterologiaPage from '../pages/Gastroenterologia';
import ColoproctologiaPage from '../pages/Coloproctologia';
import LaparoscopiaPage from '../pages/Laparoscopia';
import UnidadesPage from '../pages/UnidadesMedicci';
import DoctorPage from '../pages/DoctorPage.jsx';
import CurriculumPage from '../pages/Curriculum';
import NotFoundSection from '../components/404Section';
import AvisoPrivPage from '../pages/AvisoPriv';
import PHMetriaManometriaPage from '../pages/pHMetria-Manometria';
import ReflujoHerniaPage from '../pages/Reflujo-Hiatal';
import EndoscopíaPage from '../pages/Endoscopia';
import Redirector from '../components/Redirector';
import Sitemap from '../components/Sitemap';
import CalculatorIMC from '../components/extras/CalculatorIMC';
import pagesData from '../assets/data/PagesData';
import gastroenterologiaPagesData from '../assets/data/GastroenterologiaPagesData';
import PreciosPage from '../pages/Precios';
import ContactPage from '../pages/Contact';
import DynamicPage from './DynamicPage.jsx';
import DynamicPagesData from '../assets/data/DynamicPagesData.jsx';
// import StaticPage from './pages/StaticPage.jsx';
import CheckUpPage from '../pages/CheckUp';
import UltrasonidoPage from '../pages/Ultrasonido';
import EnfermedadesPage from '../pages/Enfermedades.jsx';
import PublicacionesPage from '../pages/Publicaciones';
import SitemapJSX from '../pages/Sitemap';
import priceData from '../assets/data/Services.json';
import DynamicPricePage from './DynamicPricePage.jsx';


const dynamicRoutes = Object.entries(DynamicPagesData).flatMap(([moduleKey, moduleData]) =>
  Object.entries(moduleData).map(([pageKey, pageData]) => ({
    path: pageData.link,
    title: pageData.title || pageKey, // Usa el título o el nombre de la página como fallback
    component: <DynamicPage pageData={pageData} /> // El componente que se renderiza
  }))
);
const dynamicPriceRoutes = priceData.map((pageData) => ({
  path: pageData.link,
  title: pageData.name || pageData.id,
  component: <DynamicPricePage pageData={pageData} />
}));

export const staticRoutes = [
  { path: "/", title: "Inicio" },
  { path: "/Laparoscopia", title: "Laparoscopia" },
  { path: "/colecistectomia-laparoscopica", title: "Colecistectomía Laparoscópica" },
  { path: "/404", title: "Página no encontrada" },
  { path: "/colecistectomia-laparoscopica", title: "Colecistectomía Laparoscópica" },
  { path: "/funduplicatura-laparoscopica", title: "Funduplicatura Laparoscópica" },
  { path: "/apendicectomia-laparoscopica", title: "Apendicectomía Laparoscópica" },
  { path: "/Laparoscopia/plastia-inguinal-con-malla", title: "Plastia-Inguinal" },
  { path: "/gastroenterologia", title: "Gastroenterología" },
  { path: "/clinical-gastroenterologia", title: "Clínica de Gastroenterología" },
  { path: "/endoscopia-digestiva", title: "Endoscopia Digestiva" },
  { path: "/phmetria-y-manometria", title: "Phmetria y Manometria" },
  { path: "/unidades-medicas", title: "Unidades Médicas" },
  { path: "/doctores", title: "Doctores" },
  { path: "/aviso-privacidad", title: "Aviso de Privacidad" },
  { path: "/calculadora-imc", title: "Calculadora IMC" },
  { path: "/precios", title: "Precios" },
  { path: "/pacientes", title: "Pacientes" },
  { path: "/enfermedades-de-esofago", title: "Enfermedades de Esofago" },
  { path: "/enfermedades-de-estomago", title: "Enfermedades de Estomago" },
  { path: "/enfermedades-de-higado-y-vias-biliares", title: "Enfermedades de Higado y Vias Biliares" },
  { path: "/doctor-juan-antonio", title: "Doctor Juan Antonio" },
  { path: "/aviso-privacidad", title: "Aviso de Privacidad" },
  { path: "/sitemap", title: "Sitemap" },
  
];


// Exportar routesArray para que pueda ser usado en otros componentes
export const routesArray = [
  ...staticRoutes,
  ...dynamicRoutes,
  ...dynamicPriceRoutes
];

const RoutesConfig = () => {
  return (
    <Routes>
      <Route 
        path="/" 
        element={<HomePage 
          title="Unidad de Gastroenterología - Inicio"
          description="Unidad de Gastroenterología."
          keywords="Manga gástrica, Home, Página principal"
          canonical="https://unidad-gastroenterologia.com/"
        />} 
      />
      <Route path='/index' element={<HomePage/>} />
      <Route 
        path="/Laparoscopia"
        element={<LaparoscopiaPage 
          pageData={pagesData['Laparoscopia']}
        />} 
      />
      <Route 
        path="/Laparoscopia/Colecistectomia"
        element={<LaparoscopiaPage 
          pageData={pagesData['Colecistectomia']}
        />} 
      />
      <Route 
        path='/colecistectomia-laparoscopica'
        element={<LaparoscopiaPage 
          pageData={pagesData['Colecistectomia']}
        />} 
      />
      <Route 
        path="/Laparoscopia/Funduplicatura"
        element={<LaparoscopiaPage 
          pageData={pagesData['Funduplicatura']}
        />}
      />
      <Route 
        path="/funduplicatura-laparoscopica"
        element={<LaparoscopiaPage 
          pageData={pagesData['Funduplicatura']}
        />}
      />
      <Route 
        path="/Laparoscopia/Apendicectomia"
        element={<LaparoscopiaPage 
          pageData={pagesData['Apendicectomia']}
        />}
      />
      <Route 
        path="/apendicectomia-laparoscopica"
        element={<LaparoscopiaPage 
          pageData={pagesData['Apendicectomia']}
        />}
      />
      <Route 
        path="/Laparoscopia/plastia-inguinal-con-malla"
        element={<LaparoscopiaPage 
          pageData={pagesData['PlastiaInguinalConMalla']}
        />}
      />
      <Route 
        path="/Gastroenterologia"
        element={<GastroenterologiaPage
          pageData={gastroenterologiaPagesData['Gastroenterologia']}
        />}
      />
      <Route 
        path="/Clinica-de-Coloproctologia"	
        element={<ColoproctologiaPage pageData={pagesData['Coloproctologia']}/>}
      />
      <Route
        path="/Endoscopia-Digestiva"
        element={<EndoscopíaPage
          pageData={pagesData['Endoscopia']}
        />}
      />
      <Route path="/PHmetria-y-Manometria" element={<PHMetriaManometriaPage
      pageData={pagesData['PhMetria']}
      />} />
      <Route path="/Reflujo-Hernia-Hiatal" element={<ReflujoHerniaPage
      pageData={pagesData['ReflujoHerniaHiatal']}
      />} />
      <Route path="/check-up" element={<CheckUpPage
        pageData={pagesData['CheckUp']}
      />} />
      <Route path="/ultrasonido-abdominal" element={<UltrasonidoPage pageData={pagesData['Ultrasonido']}/>} /> 
      <Route path="/ultrasonido" element={<UltrasonidoPage pageData={pagesData['Ultrasonido2']} />} /> 
      <Route path='/publicaciones' element={<PublicacionesPage />} />
      {/* <Route path="/pages/:pageName" element={<StaticPage />} /> */}
      <Route path="/Unidades-Medicas" element={<UnidadesPage />} />
      <Route path="/Doctores" element={<DoctorPage pageData={pagesData['Doctor']} />} />
      <Route path="/Equipo-Medico" element={<DoctorPage pageData={pagesData['EquipoMedico']} />} />
      <Route path="/Contacto" element={<ContactPage pageData={pagesData['Contact']} />} />
      <Route path="/ubicacion" element={<ContactPage />} />
      <Route path="/ubicaciones" element={<ContactPage />} />
      <Route path="/Enfermedades" element={<EnfermedadesPage pageData={pagesData['Enfermedades']} />} />
      <Route path="/Doctor-Juan-Antonio" element={<CurriculumPage />} />
      <Route path="/Aviso-Privacidad" element={<AvisoPrivPage />} />
      <Route path="/Calculadora-IMC" element={<CalculatorIMC />} />
      <Route path="/404" element={<NotFoundSection />} />
      <Route path="/sitemap" element={<SitemapJSX />} />
      <Route path="/sitemap.xml" element={<Sitemap />} />
      <Route path="/precios" element={<PreciosPage
        title="Unidad de Gastroenterología - Precios"
        description="Unidad de Gastroenterología."
        keywords="Manga gástrica, Home, Página principal de precios"
        canonical="https://unidad-gastroenterologia.com/Precos"
      />} />
      <Route path="/pacientes" element={<PreciosPage
      title="Unidad de Gastroenterología - Pacientes"
      description="Unidad de Gastroenterología."
      keywords="Manga gástrica, Home, Página principal de precios"
      canonical="https://unidad-gastroenterologia.com/pacientes"
    />} />

    {/* Rutas dinámicas */}
    {Object.entries(DynamicPagesData).map(([moduleKey, moduleData]) =>
        Object.entries(moduleData).map(([pageKey, pageData]) => (
          <Route
            key={`${moduleKey}-${pageKey}`}
            path={pageData.link}
            element={<DynamicPage pageData={pageData} />}
          />
        ))
      )}
      {/* Rutas dinámicas de precios */}
      {dynamicPriceRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}


      <Route path="*" element={<Redirector />} />
    </Routes>
  );
};

export default RoutesConfig;
