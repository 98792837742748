import React from 'react';
import PropTypes from 'prop-types';

const ServiceCard3 = ({ id, name, photoUrl, group, requirements = 'No requiere preparación', dynamicDetails = 'No requiere preparación', priceVariations = { conventional: { price: 'N/A', NeedIva: '' } }, services }) => (
  <div className="table-responsive">
    <table className="table table-bordered table-hover">
      <thead className="table-light">
        <tr>
          <th>Nombre del Procedimiento</th>
          <th>Preparación</th>
          {/* <th>Detalles dinámicos</th> */}
          {/* <th className='d-none d-md-block'>Descripción / Información adicional</th> */}
          <th>Precio</th>
        </tr>
      </thead>
      <tbody>
        {services.map(service => (
          <tr key={service.id}>
            <td className='color-text-primary fw-bold'>{service.name}</td>
            <td>{service.requirements || requirements}</td>
            {/* <td>{service.dynamicDetails || dynamicDetails}</td> */}
            {/* <td className='d-none d-md-block'>{service.description || 'No requiere preparación'}</td> */}
            <td>
              ${ 
                service.priceVariations?.conventional?.price 
                ? new Intl.NumberFormat('es-MX').format(service.priceVariations.conventional.price) 
                : 'N/A'
              } MXN {service.priceVariations?.conventional?.NeedIva || priceVariations.conventional.NeedIva}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

ServiceCard3.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      requirements: PropTypes.string,
      priceVariations: PropTypes.shape({
        conventional: PropTypes.shape({
          price: PropTypes.number.isRequired,
          NeedIva: PropTypes.string,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default ServiceCard3;
