import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DropdownMenu = ({ title, items, className, handleNavLinkClick }) => {
  const [show, setShow] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const closeTimeout = useRef(null); // Ref para el timeout

  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    updateScreenSize(); // Inicializa el estado en el primer render
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  const handleMouseEnter = () => {
    if (!isSmallScreen) {
      clearTimeout(closeTimeout.current); // Cancela el cierre si el mouse regresa
      setShow(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isSmallScreen) {
      closeTimeout.current = setTimeout(() => setShow(false), 200); // Retraso de 300ms antes de cerrar
    }
  };

  const handleToggleClick = () => {
    setShow((prevShow) => !prevShow);
  };

  const handleItemClick = () => {
    setShow(false);
    if (handleNavLinkClick) {
      handleNavLinkClick(); // Cierra el Navbar si existe esta función
    }
  };

  return (
    <Dropdown
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={show}
    >
      <Dropdown.Toggle
        id="dropdown-basic"
        className={className}
        as="div"
        onClick={isSmallScreen ? handleToggleClick : undefined} // Solo usa click en pantallas pequeñas
        aria-expanded={show ? "true" : "false"} // Se ajusta dinámicamente con el estado `show`
        role="button" // Asignar el rol de "button"
        aria-label="Abrir menú desplegable de opciones"
      >
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="custom-dropdown-menu"
        onMouseEnter={handleMouseEnter} // Evita que se cierre si el mouse entra al menú
        onMouseLeave={handleMouseLeave} // Permite el retraso al salir del menú
      >
        {items.map((item, index) => (
          item.target === '_blank' ? (
            <Dropdown.Item
              key={index}
              href={item.to}
              target={item.target}
              rel="noopener noreferrer"
              className="custom-dropdown-item"
              onClick={handleItemClick}
              aria-label={item.ariaLabel || "Abrir en una nueva pestaña"} // Asignar aria-label a elementos con target _blank
            >
              {item.label}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              as={Link}
              key={index}
              to={item.to}
              className="custom-dropdown-item"
              onClick={handleItemClick}
              aria-label={item.ariaLabel || `Ir a ${item.label}`} // Añadir aria-label a los enlaces
            >
              {item.label}
            </Dropdown.Item>
          )
        ))}
      </Dropdown.Menu>

    </Dropdown>
  );
};

export default DropdownMenu;
