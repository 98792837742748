import React from 'react';
import PropTypes from 'prop-types';

const ServiceCard = ({ 
  id, 
  name, 
  title = 'Título no disponible', 
  service = 'Servicio no especificado', 
  photoUrl = 'https://via.placeholder.com/150', 
  group = 'default-group', 
  link = '#', 
  onClick = () => {} 
}) => (
  <div className={`card-doctor doctor-card ${group}`} onClick={onClick}>
    <img src={photoUrl} className="card-img-top img-fluid" alt={`Imagen de ${name}`} />
    <div className="card-body">
      <h5 className="card-title text-uppercase">{name || 'Servicio Desconocido'}</h5>
      <p className="card-text"><small className="text-muted">{service}</small></p>
    </div>
  </div>
);

ServiceCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  service: PropTypes.string,
  photoUrl: PropTypes.string,
  group: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func
};

export default ServiceCard;