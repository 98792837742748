// components/ScrollToTop.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollToTopContext } from './ScrollToTopContext';

function ScrollToTop() {
  const { pathname } = useLocation();
  const { shouldScroll } = useScrollToTopContext();

  useEffect(() => {
    if (shouldScroll) {
      window.scrollTo(0, 0);
    }
  }, [pathname, shouldScroll]);

  return null;
}

export default ScrollToTop;
