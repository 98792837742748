import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const endoscopiaData = [
  {
    title: 'Stretta o Gastroplastia Esofago Gastrica Con Radiofrecuencia',
    image: 'https://unidad-gastroenterologia.com/images/stretta-o-gastroplastia-esofago-gastrica.png',
    description: 'Tratamiento avanzado para reflujo gastroesofágico mediante radiofrecuencia.',
    link: '/stretta-gastroplastia-radiofrecuencia',
  },
  {
    title: 'Tratamiento Sangrado de Ulcera Gastrica',
    image: 'https://unidad-gastroenterologia.com/images/tratamiento-sangrado-de-ulcera-gastrica.jpg',
    description: 'Método endoscópico para detener sangrados de úlcera gástrica.',
    link: '/ulcera',
  },
  {
    title: 'Dilatación Esofágica',
    image: 'https://unidad-gastroenterologia.com/images/dilatacion-esofagica.jpg',
    description: 'Procedimiento para aliviar la obstrucción esofágica.',
    link: '/dilatacion-endoscopica',
  },
  {
    title: 'Ligadura de Varices Esofágicas',
    image: 'https://unidad-gastroenterologia.com/images/ligadura-de-varices-esofagicas.jpg',
    description: 'Procedimiento para tratar varices esofágicas, evitando hemorragias graves.',
    link: '/ligadura-de-varices-esofagicas',
  },
  {
    title: 'Balón Intragástrico Ajustable Spatz 3 de 1 año',
    image: 'https://unidad-gastroenterologia.com/images/balon-intragastrico-ajustable-spatz.jpeg',
    description: 'Tratamiento para la obesidad utilizando un balón intragástrico ajustable.',
    link: 'http://www.balongastricocdmx.com/',
    external: true,
  },
  {
    title: 'Polipéctomias y Mucosectomias Endoscópicas',
    image: 'https://unidad-gastroenterologia.com/images/polipectomia-con-asa.jpg',
    description: 'Procedimiento endoscópico para extirpar pólipos y mucosas del tracto digestivo.',
    link: '/polipectomia-y-mucosectomia-endoscopica',
  },
  {
    title: 'Manga Gástrica Endoscópica',
    image: 'https://unidad-gastroenterologia.com/images/manga-gastrica-endoscopica.jpg',
    description: 'Procedimiento endoscópico para realizar una reducción del tamaño del estómago.',
    link: 'http://mangagastricaendoscopica.mx/',
    external: true,
  },
  {
    title: 'Tratamiento Endoscópico con Argon Plasma',
    image: 'https://unidad-gastroenterologia.com/images/tratamiento-endoscopico-con-argon-plasma.jpg',
    description: 'Uso de plasma de argón para tratar lesiones del tracto gastrointestinal.',
    link: '/tratamiento-endoscopico-argon-plasma',
  },
  {
    title: 'Ablación de Esófago de Barret con Radiofrecuencia',
    image: 'https://unidad-gastroenterologia.com/images/ablacion-de-esofago-de-barret-con-radiofrecuencia.jpg',
    description: 'Tratamiento para la displasia esofágica utilizando radiofrecuencia.',
    link: '/ablacion-esofago-barrett',
  },
];


const EndoscopiaCards = () => {
  return (
    <Row className="mt-5">
      {endoscopiaData.map((endoscopia, index) => (
        <Col key={index} md={4} className="mt-4">
          <div className="Cards-Endoscopia">
            <img
              src={endoscopia.image}
              alt={`Imagen de ${endoscopia.title}`}
              className="img-fluid"
            />
            <div className="card-body">
              <h5 className="card-title">{endoscopia.title}</h5>
              <p className="card-text">
                {endoscopia.description.length > 100
                  ? `${endoscopia.description.substring(0, 100)}...`
                  : endoscopia.description}
              </p>
              <Link to={endoscopia.link} className="btn-publicaciones">
                Leer más
              </Link>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default EndoscopiaCards;
