export const locations = {
    location1: {
      address: "Insurgentes Sur No.1809 4to. Piso Col. Guadalupe Inn, Del. Alvaro Obregón, CDMX. Entre Metrobús Francia y Olivo",
      email: "medica.medicci@gmail.com",
      phone: "5559153601",
      whatsapp: "5534816054",
      hours: "Lunes a Sábado: 8AM - 10PM; Domingo: Cerrado",
      map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.26775114840035!2d-99.18472500000001!3d19.356851!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff479106075%3A0x225b7743e5e0d0b5!2sMEDICCI%20Unidad%20de%20Gastroenterolog%C3%ADa!5e0!3m2!1ses!2sus!4v1714846621873!5m2!1ses!2sus",
    },
    location2: {
      address: "Av Nuevo León 161 Piso 3, Colonia Hipódromo Condesa, CDMX",
      email: "mediccicondesa@gmail.com",
      phone: "5559227836",
      whatsapp: "5655881218",
      hours: "Lunes a Sábado: 8AM - 10PM; Domingo: Cerrado",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.193782946831!2d-99.17257604174053!3d19.408062034771806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fff602026717%3A0x3be1126e6f314f52!2sMedicci%20Condesa%2C%20Unidad%20de%20Gastroenterolog%C3%ADa%20y%20Endoscopia%20Digestiva!5e0!3m2!1ses-419!2smx!4v1715483335410!5m2!1ses-419!2smx",
    },
    location3: {
      address: "Calzada de Tlalpan 4585 - 104, Toriello Guerra, CDMX",
      email: "mediccitlalpan@gmail.com",
      phone: "5593088390",
      whatsapp: "5634395330",
      hours: "Lunes a Viernes: 8AM - 6PM;<br>Sábado: 8AM - 3PM <br> Domingo: Cerrado",
      map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.35694204232857!2d-99.1562328!3d19.2949269!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce005766e2ae35%3A0x3b037f8c0652f7e7!2sMedicci%2C%20Tlalpan%20Zona%20de%20Hospitales!5e0!3m2!1ses-419!2smx!4v1715456127773!5m2!1ses-419!2smx",
    },
  };
  