const PreciosyServicios = {
    1: {
      modules: ["Gastroenterología", "Endoscopia Digestiva", "pHMetria", "Reflujo", "Hernia Hiatal"],
      name: "Consulta Especializada",
      link: "/precio-consulta-especializada-gastroenterologia",
      service: ["Consulta"],
      typeService: "Consulta",
      photoUrl: "https://img.freepik.com/foto-gratis/primer-plano-paciente-femenino-sentado-cerca-medico-informe-medico_23-2148050599.jpg",
      photoThumbnail: "https://cdn.milenio.com/uploads/media/2021/11/20/consulta-medica-y-ginecologica-especial.jpg",
      group: "Consulta",
      keywords: "consulta especializada, consulta médica especializada en gastroenterología, consulta gastroenterológica, atención especializada en digestivo",
      canonical: "https://unidad-gastroenterologia.com/precio-consulta-especializada-gastroenterologia",
      description: "Descubre la mejor consulta especializada en gastroenterología con atención personalizada. Agenda tu cita hoy y recibe un diagnóstico experto.",
      priceVariations: {
        conventional: {
          name: "Consulta con especialista",
          price: 1400,
        },
      },
      promotion: "Consulta Promoción por pago en efectivo",
      id: 1,
    },
    2: {
      modules: ["Coloproctología"],
      name: "Consulta Especializada en Coloproctología",
      link: "/precio-consulta-especializada-coloproctologia",
      service: ["Consulta"],
      typeService: "Consulta",
      photoUrl: "https://img.freepik.com/foto-gratis/primer-plano-paciente-femenino-sentado-cerca-medico-informe-medico_23-2148050599.jpg",
      photoThumbnail: "https://cdn.milenio.com/uploads/media/2021/11/20/consulta-medica-y-ginecologica-especial.jpg",
      group: "Consulta",
      keywords: "consulta especializada, consulta médica coloproctología, atención a trastornos coloproctológicos, consulta en coloproctología avanzada",
      canonical: "https://unidad-gastroenterologia.com/precio-consulta-especializada-coloproctologia",
      description: "Accede a consultas especializadas en coloproctología para el diagnóstico y tratamiento de enfermedades del colon y recto. Cuidado médico experto.",
      priceVariations: {
        conventional: {
          name: "Consulta con especialista",
          price: 1400,
        },
      },
      promotion: "Consulta Promoción por pago en efectivo",
      id: 2,
    },
    3: {
      modules: ["Gastroenterología", "Endoscopia Digestiva", "Reflujo"],
      name: "Endoscopia Digestiva Alta",
      link: "/precio-endoscopia-digestiva-alta",
      service: ["Estudios"],
      typeService: "Estudio",
      photoUrl: "https://unidad-gastroenterologia.com/images/endoscopia-digestiva-alta.jpg",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/clinica-de-balon-gastrico-header.jpg",
      group: "Estudios",
      keywords: "endoscopia digestiva alta, estudio endoscópico alta precisión, diagnóstico digestivo avanzado, tratamiento para reflujo y úlceras",
      canonical: "https://unidad-gastroenterologia.com/precio-endoscopia-digestiva-alta",
      description: "Realiza tu endoscopía digestiva alta con médicos expertos para un diagnóstico preciso de enfermedades gástricas. Con tecnología de última generación.",
      priceVariations: {
        conventional: {
          name: "Endoscopía Digestiva",
          price: 4300,
          NeedIva: "+ IVA",
        },
      },
      included: ["Honorarios Médicos del Endoscopista y Anestesiólogo", "Procedimiento Endoscópico", "Reporte con Fotografías"],
      excluded: ["Análisis Histopatológico", "Pruebas Diagnósticas", "Grabación del estudio en DVD / USB"],
      id: 3,
    },
    4: {
      modules: ["Gastroenterología"],
      name: "Colonoscopia",
      link: "/precio-colonoscopia",
      service: ["Estudios"],
      photoUrl: "https://unidad-gastroenterologia.com/images/endoscopia-digestiva-alta.jpg",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/clinica-de-balon-gastrico-header.jpg",
      group: "Estudios",
      keywords: "precio colonoscopia, colonoscopia precio, estudio de colon, diagnóstico intestinal, precio colonoscopia precio",
      canonical: "https://unidad-gastroenterologia.com/precio-colonoscopia",
      description: "Consulta los precios de la colonoscopia en nuestra página. Obtén un diagnóstico preciso de enfermedades intestinales con la mejor tecnología médica.",
      priceVariations: {
        conventional: {
          name: "COLONOSCOPÍA",
          price: 4850,
          NeedIva: "+ IVA",
        },
      },
      included: ["Honorarios Médicos del Endoscopista y Anestesiólogo", "Procedimiento Endoscópico", "Reporte con Fotografías"],
      excluded: ["Análisis Histopatológico", "Pruebas Diagnósticas", "Grabación del estudio en DVD / USB"],
      id: 4,
    },
    5: {
      modules: ["Gastroenterología", "pHMetria", "Reflujo"],
      name: "Manometría Esofágica Convencional y de Alta Resolución",
      link: "/precio-manometria-esofagica",
      service: ["Manometria"],
      photoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXd1mLxnXzdz29Bqin-iuYrqQwZcHhAjNi0g&s",
      photoThumbnail: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXd1mLxnXzdz29Bqin-iuYrqQwZcHhAjNi0g&s",
      group: "Estudios",
      keywords: "precio manometría esofágica, manometría esofágica precio, estudio esofágico, manometría alta resolución, precio manometría",
      canonical: "https://unidad-gastroenterologia.com/precio-manometria-esofagica",
      description: "Consulta los precios de la manometría esofágica convencional y de alta resolución. Diagnóstico preciso para trastornos de motilidad esofágica.",
      priceVariations: {
        conventional: {
          name: "Manometría Convencional",
          price: 5500,
          NeedIva: "+ IVA",
        },
        highResolution: {
          name: "Manometría de Alta Resolución",
          price: 9000,
          NeedIva: "+ IVA",
        },
      },
      id: 5,
    },
    6: {
      modules: ["Gastroenterología", "pHMetria", "Reflujo"],
      name: "pHmetría Esofágica Convencional y con Impedancia",
      link: "/precio-phmetria-esofagica",
      service: ["Phmetria"],
      photoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXd1mLxnXzdz29Bqin-iuYrqQwZcHhAjNi0g&s",
      photoThumbnail: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXd1mLxnXzdz29Bqin-iuYrqQwZcHhAjNi0g&s",
      group: "Estudios",
      keywords: "phmetría precio, precio phmetría esofágica, phmetría convencional, diagnóstico de reflujo, pHmetría con impedancia precio",
      canonical: "https://unidad-gastroenterologia.com/precio-phmetria-esofagica",
      description: "Consulta los precios de la pHmetría esofágica, tanto convencional como con impedancia. Evaluación precisa del reflujo y trastornos gástricos.",
      priceVariations: {
        conventional: {
          name: "pHmetría Convencional",
          price: 5500,
          NeedIva: "+ IVA",
        },
        highImpedance: {
          name: "pHmetría con Impedancia",
          price: 9000,
          NeedIva: "+ IVA",
        },
      },
      id: 6,
    },
    7: {
      modules: ["Gastroenterología"],
      name: "Prueba de Aliento para Detección de Helicobacter Pylori",
      link: "/precio-prueba-aliento-helicobacter",
      service: ["PruebaAliento"],
      photoUrl: "https://unidad-gastroenterologia.com/images/unidades/Prueba-Aliento-Helicobacter.avif",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/Prueba-Aliento-Helicobacter.avif",
      group: "Estudios",
      keywords: "precio prueba de aliento helicobacter, prueba helicobacter pylori precio, diagnóstico no invasivo, análisis de aliento precio",
      canonical: "https://unidad-gastroenterologia.com/precio-prueba-aliento-helicobacter",
      description: "Consulta el precio de la prueba de aliento para detectar Helicobacter Pylori. Es un diagnóstico rápido, preciso y no invasivo.",
      priceVariations: {
        conventional: {
          name: "Prueba de Aliento Helicobacter Pilory",
          price: 1500,
          NeedIva: "+ IVA",
        },
      },
      id: 7,
    },
    8: {
      modules: ["Coloproctología", "Endoscopia Digestiva"],
      name: "Colonoscopia y Rectosigmoidoscopia",
      link: "/precio-colonoscopia-rectosigmoidoscopia",
      service: ["Estudios"],
      photoUrl: "https://unidad-gastroenterologia.com/images/endoscopia-digestiva-alta.jpg",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/clinica-de-balon-gastrico-header.jpg",
      group: "Estudios",
      keywords: "precio colonoscopia y rectosigmoidoscopia, colonoscopia precio, precio colonoscopia rectosigmoidoscopia, estudio colon recto precio",
      canonical: "https://unidad-gastroenterologia.com/precio-colonoscopia-rectosigmoidoscopia",
      description: "Consulta el precio de la colonoscopia y rectosigmoidoscopia para diagnóstico de enfermedades intestinales y rectales. Atención especializada con los mejores precios.",
      priceVariations: {
        conventional: {
          name: "Estudio de Colonoscopía o Rectosigmoidoscopia",
          price: 4500,
          NeedIva: "+ IVA",
        },
      },
      included: ["Honorarios Médicos del Endoscopista y Anestesiólogo", "Procedimiento Endoscópico", "Reporte con Fotografías"],
      excluded: ["Análisis Histopatológico", "Pruebas Diagnósticas para Helicobacter Pylori.", "Procedimiento COLONOSCOPIA (ligaduras de hemorroides, dilataciones, hemostasia de sangrados, etc.)"],
      id: 8,
    },
    9: {
      modules: ["Coloproctología"],
      name: "Clínica de VPH Anal",
      link: "/precio-clinica-vph-anal",
      service: ["Procedimiento"],
      typeService: "Consulta",
      photoUrl: "https://unidad-gastroenterologia.com/images/servicios/estudio-vph.jpg",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/servicios/estudio-vph.jpg",
      group: "Consulta",
      keywords: "precio clínica de VPH anal, consulta VPH anal precio, diagnóstico de VPH precio, tratamiento de VPH anal",
      canonical: "https://unidad-gastroenterologia.com/precio-clinica-vph-anal",
      description: "Consulta el precio de la clínica de VPH anal, un procedimiento especializado para la detección y tratamiento del VPH en el área anal.",
      priceVariations: {
        conventional: {
          name: "Consulta con especialista",
          price: 1500,
        },
      },
      promotion: "Consulta Promoción por pago en efectivo",
      id: 9,
    },
    10: {
      modules: ["Coloproctología", "pHMetria"],
      name: "Manometría Endoanal de Alta Resolución",
      link: "/precio-manometria-endoanal",
      service: ["Estudios"],
      typeService: "Estudio",
      photoUrl: "https://unidad-gastroenterologia.com/images/servicios/manometria.webp",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/servicios/manometria.webp",
      group: "Estudios",
      keywords: "precio manometría endoanal alta resolución, manometría endoanal precio, diagnóstico de motilidad anal precio, precio manometría anal",
      canonical: "https://unidad-gastroenterologia.com/precio-manometria-endoanal",
      description: "Consulta el precio de la manometría endoanal de alta resolución para el diagnóstico de trastornos en la motilidad del ano. Estudio preciso y confiable.",
      priceVariations: {
        conventional: {
          name: "Manometría de Alta Resolución",
          price: 9500,
          NeedIva: "+ IVA",
        },
      },
      included: ["Honorarios Médicos", "Procedimiento", "Reporte con Imagenes"],
      id: 10,
    },
    11: {
      modules: ["Coloproctología", "pHMetria"],
      name: "Biofeedback o Electroestimulación Anal",
      link: "/precio-biofeedback-electroestimulacion-anal",
      service: ["Procedimiento"],
      typeService: "Estudios",
      photoUrl: "https://unidad-gastroenterologia.com/images/servicios/terapia-electroestimulacion.jpg",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/servicios/terapia-electroestimulacion.jpg",
      group: "Estudios",
      keywords: "precio biofeedback anal, electroestimulación anal precio, tratamiento de disfunción anal, procedimiento electroestimulación anal",
      canonical: "https://unidad-gastroenterologia.com/precio-biofeedback-electroestimulacion-anal",
      description: "Consulta los precios para realizar el tratamiento de biofeedback o electroestimulación anal, utilizado para mejorar la función del esfínter anal.",
      priceVariations: {
        conventional: {
          name: "Manometría de Alta Resolución",
          price: 2500,
          NeedIva: "+ IVA",
        },
      },
      included: ["Honorarios Médicos", "Procedimiento"],
      id: 11,
    },
    12: {
        modules: ["CheckUp"],
        name: "Paquete Básico Check Up",
        link: "/precio-paquete-basico-check-up",
        service: ["Check Up"],
        typeService: "Check Up",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        group: "Check Up",
        keywords: "precio paquete básico check up, check up precio, paquete básico diagnóstico precio, paquete básico médico",
        canonical: "https://unidad-gastroenterologia.com/precio-paquete-basico-check-up",
        description: "Consulta el precio del Paquete Básico Check Up. Incluye consulta con gastroenterólogo, ultrasonido abdominal y laboratorios para control médico.",
        priceVariations: {
          conventional: {
            name: "Básico",
            price: 3150,
            NeedIva: "+ IVA",
          },
        },
        included: ["CONSULTA Con Gastroenterólogo", "LABORATORIOS Perfil de control médico 1", "ULTRASONIDO Abdominal"],
        id: 12,
      },
      13: {
        modules: ["CheckUp"],
        name: "Paquete Silver Check Up",
        link: "/precio-paquete-silver-check-up",
        service: ["Check Up"],
        typeService: "Check Up",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        group: "Check Up",
        keywords: "precio paquete silver check up, check up silver precio, paquete silver diagnóstico, precio paquete médico avanzado",
        canonical: "https://unidad-gastroenterologia.com/precio-paquete-silver-check-up",
        description: "Consulta el precio del Paquete Silver Check Up. Incluye consulta con gastroenterólogo, biopsia de unión esófago-gástrica, endoscopía digestiva alta, y más.",
        priceVariations: {
          conventional: {
            name: "Silver",
            price: 7290,
            NeedIva: "+ IVA",
          },
        },
        included: ["CONSULTA Con Gastroenterólogo", "LABORATORIOS Perfil de control médico 1", "BIOPSIA de unión esófago-gástrica", "ENDOSCOPÍA Digestiva Alta", "BIOPSIA protocolo de SYDNEY"],
        id: 13,
      },
      14: {
        modules: ["CheckUp"],
        name: "Paquete Gold Check Up",
        link: "/precio-paquete-gold-check-up",
        service: ["Check Up"],
        typeService: "Check Up",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        group: "Check Up",
        keywords: "precio paquete gold check up, check up gold precio, paquete gold diagnóstico, diagnóstico avanzado check up",
        canonical: "https://unidad-gastroenterologia.com/precio-paquete-gold-check-up",
        description: "Consulta el precio del Paquete Gold Check Up. Incluye consultas, estudios de ultrasonido, biopsias, endoscopía y más, para un diagnóstico completo.",
        priceVariations: {
          conventional: {
            name: "Gold",
            price: 8550,
            NeedIva: "+ IVA",
          },
        },
        included: ["CONSULTA Con Gastroenterólogo", "LABORATORIOS Perfil de control médico 1", "ULTRASONIDO Abdominal", "BIOPSIA de unión esófago-gástrica", "ENDOSCOPÍA Digestiva Alta", "BIOPSIA protocolo de SYDNEY"],
        id: 14,
      },
      15: {
        modules: ["CheckUp"],
        name: "Paquete Platinum Check Up",
        link: "/precio-paquete-platinum-check-up",
        service: ["Check Up"],
        typeService: "Check Up",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/CheckUp.webp",
        group: "Check Up",
        keywords: "precio paquete platinum check up, check up platinum precio, paquete platinum diagnóstico completo, paquete médico completo precio",
        canonical: "https://unidad-gastroenterologia.com/precio-paquete-platinum-check-up",
        description: "Consulta el precio del Paquete Platinum Check Up. El paquete más completo, incluye consultas, análisis de laboratorio, ultrasonido, endoscopía y colonoscopía.",
        priceVariations: {
          conventional: {
            name: "Platinum",
            price: 13600,
            NeedIva: "+ IVA",
          },
        },
        included: ["CONSULTA Con Gastroenterólogo", "LABORATORIOS Perfil de control médico 1", "ULTRASONIDO Abdominal", "BIOPSIA de unión esófago-gástrica", "ENDOSCOPÍA Digestiva Alta", "BIOPSIA protocolo de SYDNEY", "COLONOSCOPÍA"],
        id: 15,
      },
      16: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido de Hígado y Vías Biliares",
        link: "/precio-ultrasonido-higado-vias-biliares",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido hígado, ultrasonido hígado y vías biliares precio, diagnóstico ultrasonido hígado, ultrasonido hígado precio México",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-higado-vias-biliares",
        description: "Consulta el precio del Ultrasonido de Hígado y Vías Biliares. Un estudio no invasivo que ayuda a evaluar el estado de los órganos abdominales y detectar posibles alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido de hígado y vías biliares",
            price: 1600,
            NeedIva: "+ IVA",
          },
        },
        requirements: "Se necesita ayuno mínimo de 6 horas, si el estudio es de urgencia no necesita ayuno",
        id: 16,
      },
      17: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido de Hígado y Vías Biliares Dinámico",
        link: "/precio-ultrasonido-higado-dinamico",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido dinámico hígado, ultrasonido dinámico hígado precio, estudio hígado dinámico precio, precio ultrasonido hígado México",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-higado-dinamico",
        description: "Consulta el precio del Ultrasonido de Hígado y Vías Biliares Dinámico. Este estudio avanzado permite un análisis detallado de la funcionalidad del hígado, evaluando posibles alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido dinámico",
            price: 1800,
            NeedIva: "+ IVA",
          },
        },
        requirements: "Se necesita ayuno de 12 horas",
        dynamicDetails: "Después de la primera revisión, el paciente debe desayunar algo con contenido graso (ej. carnitas), regresa en un lapso de una hora y nuevamente se revisa. Ahí concluye el estudio",
        id: 17,
      },
      18: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Renal",
        link: "/precio-ultrasonido-renal",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido renal, ultrasonido renal precio, estudio renal precio México, diagnóstico ultrasonido renal precio",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-renal",
        description: "Consulta el precio del Ultrasonido Renal. Un examen no invasivo que permite detectar condiciones que afectan los riñones, tales como piedras o infecciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido renal",
            price: 1600,
            NeedIva: "+ IVA",
          },
        },
        requirements: "No requiere preparación",
        id: 18,
      },
      19: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Abdomen Completo",
        link: "/precio-ultrasonido-abdomen-completo",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido abdomen completo, ultrasonido abdomen precio, estudio abdomen completo precio, diagnóstico abdomen completo",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-abdomen-completo",
        description: "Consulta el precio del Ultrasonido Abdomen Completo. Un estudio de imágenes que proporciona una evaluación detallada de todos los órganos del abdomen.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido abdomen completo",
            price: 1200,
            NeedIva: "+ IVA",
          },
        },
        requirements: "Requiere ayuno mínimo de 6 horas. En caso de urgencia no requiere preparación",
        id: 19,
      },
      20: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido de Pared Abdominal",
        link: "/precio-ultrasonido-pared-abdominal",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido pared abdominal, ultrasonido pared abdominal precio, estudio pared abdominal precio, diagnóstico pared abdominal hernias",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-pared-abdominal",
        description: "Consulta el precio del Ultrasonido de Pared Abdominal. Un estudio de alta resolución para evaluar los tejidos blandos de la pared abdominal y descartar hernias o alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido de pared abdominal",
            price: 1300,
            NeedIva: "+ IVA",
          },
        },
        requirements: "Agendar cita previamente, Llegar a tiempo",
        id: 20,
      },
      21: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido de Pared Abdominal 360",
        link: "/precio-ultrasonido-pared-abdominal-360",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "ultrasonido pared abdominal 360, ultrasonido diagnóstico pared abdominal 360",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-pared-abdominal-360",
        description: "Estudio especializado para la evaluación de la pared abdominal en 360 grados.",
        priceVariations: {
          conventional: {
            name: "Estudio Ultrasonido 360",
            price: 1500,
            NeedIva: "+ IVA",
          },
        },
        id: 21,
      },
      22: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Pared Abdominal",
        link: "/precio-ultrasonido-doppler-pared-abdominal",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler pared abdominal, ultrasonido doppler pared abdominal precio, diagnóstico doppler pared abdominal",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-pared-abdominal",
        description: "Consulta el precio del Ultrasonido Doppler Pared Abdominal. Un estudio especializado para evaluar el flujo sanguíneo en la pared abdominal.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler pared abdominal",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 22,
      },
      23: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido de Vías Urinarias",
        link: "/precio-ultrasonido-vias-urinarias",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "ultrasonido vías urinarias, diagnóstico ultrasonido vías urinarias",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-vias-urinarias",
        description: "Consulta el precio del Ultrasonido de Vías Urinarias, un diagnóstico avanzado para detectar trastornos renales o infecciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido vías urinarias",
            price: 1600,
            NeedIva: "+ IVA",
          },
        },
        id: 23,
      },
      24: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Abdomen Superior",
        link: "/precio-ultrasonido-abdomen-superior",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido abdomen superior, ultrasonido abdomen superior precio, diagnóstico abdomen superior precio",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-abdomen-superior",
        description: "Consulta el precio del Ultrasonido Abdomen Superior. Un examen especializado para evaluar los órganos superiores del abdomen.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido abdomen superior",
            price: 900,
            NeedIva: "+ IVA",
            discountCash: 800,
          },
        },
        requirements: "Requiere ayuno mínimo de 6 horas",
        id: 24,
      },
      25: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Apéndice",
        link: "/precio-ultrasonido-apendice",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido apéndice, ultrasonido apéndice precio, diagnóstico apéndice precio",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-apendice",
        description: "Consulta el precio del Ultrasonido Apéndice. Un examen que permite evaluar el estado del apéndice y detectar posibles inflamaciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido apéndice",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "No requiere preparación especial",
        id: 25,
      },
      26: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Dinámico de Vesícula",
        link: "/precio-ultrasonido-dinamico-vesicula",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido dinámico vesícula, ultrasonido vesícula dinámica precio, diagnóstico vesícula dinámica",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-dinamico-vesicula",
        description: "Consulta el precio del Ultrasonido Dinámico de Vesícula. Un estudio avanzado para detectar alteraciones funcionales en la vesícula biliar.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido vesícula dinámica",
            price: 1300,
            NeedIva: "+ IVA",
            discountCash: 1200,
          },
        },
        requirements: "Ayuno mínimo de 12 horas",
        id: 26,
      },
      27: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Abdomen Completo",
        link: "/precio-ultrasonido-doppler-abdomen-completo",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler abdomen completo, ultrasonido doppler abdomen completo precio, diagnóstico doppler abdomen completo",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-abdomen-completo",
        description: "Consulta el precio del Ultrasonido Doppler Abdomen Completo. Un estudio especializado para evaluar el flujo sanguíneo y las estructuras vasculares del abdomen.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler abdomen completo",
            price: 2500,
            NeedIva: "+ IVA",
            discountCash: 2400,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 27,
      },
      28: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Hígado y Vías Biliares",
        link: "/precio-ultrasonido-doppler-higado-vias-biliares",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler hígado y vías biliares, ultrasonido doppler hígado precio, diagnóstico doppler hígado vías biliares",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-higado-vias-biliares",
        description: "Consulta el precio del Ultrasonido Doppler Hígado y Vías Biliares. Estudio que evalúa la circulación sanguínea en el hígado y las vías biliares.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler hígado y vías biliares",
            price: 1900,
            NeedIva: "+ IVA",
            discountCash: 1800,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 28,
      },
      29: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Inguinal Bilateral",
        link: "/precio-ultrasonido-doppler-inguinal-bilateral",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler inguinal bilateral, ultrasonido doppler inguinal precio",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-inguinal-bilateral",
        description: "Consulta el precio del Ultrasonido Doppler Inguinal Bilateral. Un estudio para evaluar el flujo sanguíneo en la zona inguinal y detectar posibles hernias.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler inguinal bilateral",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 29,
      },
      
      30: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Próstata",
        link: "/precio-ultrasonido-doppler-prostata",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler próstata, ultrasonido doppler próstata precio, diagnóstico doppler próstata",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-prostata",
        description: "Consulta el precio del Ultrasonido Doppler Próstata. Un estudio que evalúa el flujo sanguíneo en la próstata y detecta posibles alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler próstata",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 30,
      },
      31: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Renal",
        link: "/precio-ultrasonido-doppler-renal",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler renal, ultrasonido doppler renal precio, diagnóstico doppler renal",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-renal",
        description: "Consulta el precio del Ultrasonido Doppler Renal. Un estudio especializado para evaluar el flujo sanguíneo en los riñones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler renal",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 31,
      },
      32: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Testicular",
        link: "/precio-ultrasonido-doppler-testicular",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler testicular, ultrasonido doppler testicular precio, diagnóstico doppler testicular",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-testicular",
        description: "Consulta el precio del Ultrasonido Doppler Testicular. Un examen especializado para estudiar el flujo sanguíneo en los testículos.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler testicular",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 32,
      },
      33: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Tiroides",
        link: "/precio-ultrasonido-doppler-tiroides",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler tiroides, ultrasonido doppler tiroides precio, diagnóstico doppler tiroides",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-tiroides",
        description: "Consulta el precio del Ultrasonido Doppler Tiroides. Un estudio que evalúa el flujo sanguíneo en la tiroides y detecta alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler tiroides",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 33,
      },
      34: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Doppler Vejiga",
        link: "/precio-ultrasonido-doppler-vejiga",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio doppler vejiga, ultrasonido doppler vejiga precio, diagnóstico doppler vejiga",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-doppler-vejiga",
        description: "Consulta el precio del Ultrasonido Doppler Vejiga. Un estudio especializado para evaluar el flujo sanguíneo en la vejiga y detectar posibles trastornos.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido doppler vejiga",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 34,
      },
      35: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Inguinal BILATERAL (Hernia)",
        link: "/precio-ultrasonido-inguinal-bilateral",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido inguinal bilateral, ultrasonido inguinal bilateral precio, diagnóstico ultrasonido inguinal bilateral",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-inguinal-bilateral",
        description: "Consulta el precio del Ultrasonido Inguinal Bilateral (Hernia). Un estudio que evalúa las posibles hernias en la zona inguinal de ambos lados.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido inguinal bilateral",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 35,
      },
      36: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Inguinal UNILATERAL (Hernia)",
        link: "/precio-ultrasonido-inguinal-unilateral",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido inguinal unilateral, ultrasonido inguinal unilateral precio, diagnóstico ultrasonido inguinal unilateral",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-inguinal-unilateral",
        description: "Consulta el precio del Ultrasonido Inguinal Unilateral (Hernia). Un estudio para evaluar la presencia de hernias en un solo lado de la zona inguinal.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido inguinal unilateral",
            price: 900,
            NeedIva: "+ IVA",
            discountCash: 800,
          },
        },
        requirements: "Ayuno mínimo de 6 horas",
        id: 36,
      },
      37: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Próstata",
        link: "/precio-ultrasonido-prostata",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido próstata, ultrasonido próstata precio, diagnóstico ultrasonido próstata",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-prostata",
        description: "Consulta el precio del Ultrasonido de Próstata. Un examen no invasivo para evaluar el estado de la próstata y detectar posibles alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido próstata",
            price: 1300,
            NeedIva: "+ IVA",
            discountCash: 1200,
          },
        },
        requirements: "No requiere preparación",
        id: 37,
      },
      38: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Renal",
        link: "/precio-ultrasonido-renal",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido renal, ultrasonido renal precio, estudio renal precio México, diagnóstico ultrasonido renal precio",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-renal",
        description: "Consulta el precio del Ultrasonido Renal. Un examen no invasivo que permite detectar condiciones que afectan los riñones, tales como piedras o infecciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido renal",
            price: 1300,
            NeedIva: "+ IVA",
            discountCash: 1200,
          },
        },
        requirements: "No requiere preparación especial",
        id: 38,
      },
      39: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Tejidos Blandos",
        link: "/precio-ultrasonido-tejidos-blandos",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido tejidos blandos, ultrasonido tejidos blandos precio, diagnóstico ultrasonido tejidos blandos",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-tejidos-blandos",
        description: "Consulta el precio del Ultrasonido de Tejidos Blandos. Un estudio para examinar los tejidos blandos y detectar posibles lesiones o trastornos.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido tejidos blandos",
            price: 1700,
            NeedIva: "+ IVA",
            discountCash: 1600,
          },
        },
        requirements: "No requiere preparación especial",
        id: 39,
      },
      40: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Testicular",
        link: "/precio-ultrasonido-testicular",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido testicular, ultrasonido testicular precio, diagnóstico ultrasonido testicular",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-testicular",
        description: "Consulta el precio del Ultrasonido Testicular. Un examen para evaluar los testículos y detectar posibles problemas como quistes o tumores.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido testicular",
            price: 1500,
            NeedIva: "+ IVA",
            discountCash: 1400,
          },
        },
        requirements: "No requiere preparación especial",
        id: 40,
      },
      41: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Tiroides",
        link: "/precio-ultrasonido-tiroides",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido tiroides, ultrasonido tiroides precio, diagnóstico ultrasonido tiroides",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-tiroides",
        description: "Consulta el precio del Ultrasonido de Tiroides. Un estudio no invasivo para evaluar la glándula tiroides y detectar posibles alteraciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido tiroides",
            price: 1300,
            NeedIva: "+ IVA",
            discountCash: 1200,
          },
        },
        requirements: "No requiere preparación especial",
        id: 41,
      },
      42: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Vejiga",
        link: "/precio-ultrasonido-vejiga",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido vejiga, ultrasonido vejiga precio, diagnóstico ultrasonido vejiga",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-vejiga",
        description: "Consulta el precio del Ultrasonido de Vejiga. Un estudio para evaluar el estado de la vejiga y detectar alteraciones o infecciones.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido vejiga",
            price: 900,
            NeedIva: "+ IVA",
            discountCash: 800,
          },
        },
        requirements: "No requiere preparación especial",
        id: 42,
      },
      43: {
        modules: ["Ultrasonido"],
        name: "Ultrasonido Vesícula",
        link: "/precio-ultrasonido-vesicula",
        service: ["Ultrasonido"],
        typeService: "Estudio",
        photoUrl: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/unidades/ultrasonido.webp",
        group: "Estudios",
        keywords: "precio ultrasonido vesícula, ultrasonido vesícula precio, diagnóstico ultrasonido vesícula",
        canonical: "https://unidad-gastroenterologia.com/precio-ultrasonido-vesicula",
        description: "Consulta el precio del Ultrasonido Vesícula. Un estudio para evaluar el estado de la vesícula biliar y detectar piedras u otros trastornos.",
        priceVariations: {
          conventional: {
            name: "Ultrasonido vesícula",
            price: 900,
            NeedIva: "+ IVA",
            discountCash: 800,
          },
        },
        requirements: "No requiere preparación especial",
        id: 43,
      },
      44: {
        modules: ["Cirugia"],
        name: "Procedimiento de Stretta",
        link: "/precio-procedimiento-stretta",
        service: ["Stretta", "Procedimiento"],
        typeService: "Procedimiento",
        photoUrl: "https://unidad-gastroenterologia.com/images/enfermedades/stretta-procedimiento.jpeg",
        photoThumbnail: "https://unidad-gastroenterologia.com/images/enfermedades/stretta-procedimiento.jpeg",
        group: "Estudios",
        keywords: "precio procedimiento Stretta, procedimiento Stretta precio, tratamiento Stretta",
        canonical: "https://unidad-gastroenterologia.com/precio-procedimiento-stretta",
        description: "Consulta el precio del Procedimiento de Stretta. Un tratamiento innovador para el reflujo gastroesofágico y otras afecciones digestivas.",
        priceVariations: {
          conventional: {
            name: "Procedimiento Stretta",
            price: 120000,
            NeedIva: "+ IVA",
          },
        },
        requirements: "No requiere preparación especial",
        id: 44,
      },
    45: {
      modules: ["Cirugia"],
      name: "Funduplicatura Laparoscópica",
      link: "/precio-funduplicatura-laparoscopica",
      service: ["Funduplicatura Laparoscópica", "Procedimiento"],
      typeService: "Procedimiento",
      photoUrl: "https://unidad-gastroenterologia.com/images/enfermedades/funduplicatura-laparascopica-procedimiento.jpg",
      photoThumbnail: "https://unidad-gastroenterologia.com/images/enfermedades/funduplicatura-laparascopica-procedimiento.jpg",
      group: "Estudios",
      keywords: "precio funduplicatura laparoscópica, funduplicatura laparoscópica precio, tratamiento laparoscópico reflujo",
      canonical: "https://unidad-gastroenterologia.com/precio-funduplicatura-laparoscopica",
      description: "Consulta el precio de la Funduplicatura Laparoscópica. Un tratamiento mínimamente invasivo para el reflujo gastroesofágico y trastornos relacionados.",
      priceVariations: {
        conventional: {
          name: "Funduplicatura Laparoscópica",
          price: 180000,
          NeedIva: "+ IVA",
        },
      },
      requirements: "Requiere preparación especial para la cirugía.",
      id: 45,
    },
      
  };
  export default PreciosyServicios;
  