import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/hero/styles.css';
import ImagenLogoSVG from '../assets/images/logos/logo-unidad-gastroenterologia-completo-blanco.jsx';




function HeroSection() {

  return (
    <section id="hero" className="hero d-flex align-items-center section-bg backgroundHero">
      <Container>
        <Row className="justify-content-between">
        <Col lg={4} order={1} className="order-lg-1 text-lg-start d-flex flex-column align-items-center">
        <ImagenLogoSVG clase='logo-home'/>
          </Col>
          <Col lg={8} order={2} className="order-lg-2 d-flex flex-column justify-content-center align-items-center align-items-center content-col">
            <h1>Tu <span className="banner-span-green">salud</span> digestiva <span className='text-primary'> es nuestra </span><span className="banner-span-green">Prioridad</span></h1>
            <p className='fw-4'>Alta especialidad en todas las ramas de Gástroentelorogía: <span className='banner-span-green'>Clínica Medicci</span></p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroSection;
