import React, { useState, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import DoctorList from '../components/DoctorList.jsx';
import ServiceList from '../components/ServiceList.jsx';
// import CarouselSeguros from '../components/Carousel-Seguros.jsx';
import DynamicPagesData from '../assets/data/DynamicPagesData';
import { Link } from 'react-router-dom';

const CarouselSeguros = lazy(() => import('../components/Carousel-Seguros.jsx'));


const HerniaHiatalPage = ({ pageData }) => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  const [selectedCategory] = useState(null);

  const publicaciones = {
    esofago: Array.isArray(DynamicPagesData.EnfermedadesDeEsofago)
      ? DynamicPagesData.EnfermedadesDeEsofago
      : Object.values(DynamicPagesData.EnfermedadesDeEsofago), // Convierte el objeto a array
  };
  const EnfermedadesReflujo = {
    esofago: Array.isArray(DynamicPagesData.EnfermedadesReflujoHernia)
      ? DynamicPagesData.EnfermedadesReflujoHernia
      : Object.values(DynamicPagesData.EnfermedadesReflujoHernia), // Convierte el objeto a array
  };

 

  const filteredPublicaciones = selectedCategory
    ? publicaciones[selectedCategory]
    : [ ...publicaciones.esofago];

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section className="unidades-section">
        <Container fluid>
          <div className="my-5">
            <h1>Unidad de Tratamiento de Reflujo Gastroesofágico y Hernia Hiatal</h1>
            <hr />
            <Row className="p-lg-5 d-flex align-items-center">
              <Col className="p-lg-5" md={6}>
                <div className="info-item d-flex align-items-center">
                  <div>
                    <p>
                    El reflujo gastroesofágico (RGE) es una condición en la cual el contenido ácido del estómago regresa al esófago, causando una serie de síntomas y potenciales complicaciones. Aquí te detallo lo que siente un paciente y las consecuencias que puede tener esta afección.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="p-5 align-items-center" md={6}>
                <div className="info-item d-flex justify-content-around">
                  <div>
                    <img
                      className="img-fluid rounded-4"
                      width={300}
                      src="https://unidad-gastroenterologia.com/images/unidades/reflujo-grastro-removebg.png"
                      alt="Imagen de Hernia Hiatal"
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='px-2 px-lg-4'>
              <div>
                <h3 className='my-3 my-lg-5'>Síntomas que siente el paciente</h3>
                <ul className="text-start unstyled">
                  <li><strong>Pirosis (acidez estomacal)</strong>: Sensación ardiente en el pecho, que puede irradiar hacia la garganta.</li>
                  <li><strong>Regurgitación</strong>: Sabor ácido o amargo en la boca debido al retorno de contenido estomacal.</li>
                  <li><strong>Dificultad para tragar</strong>: Sensación de que la comida se queda atascada en el esófago.</li>
                  <li><strong>Dolor en el pecho</strong>: Puede ser confundido con un problema cardíaco.</li>
                  <li><strong>Tos persistente o sibilancias</strong>: Especialmente en la noche.</li>
                  <li><strong>Irritación de la garganta</strong>: Resequedad o dolor de garganta frecuente.</li>
                  <li><strong>Náuseas</strong>: En algunos casos, los pacientes sienten náuseas, especialmente después de comer.</li>
                  <li><strong>Mal aliento</strong>: Debido a la presencia de ácido en la boca y el esófago.</li>
                </ul>
                
                <h3 className='my-3 my-lg-5'>Consecuencias del Reflujo Gastroesofágico</h3>
                <ul className="text-start unstyled">
                  <li><strong>Esofagitis</strong>: Inflamación del esófago que puede causar dolor y dificultar la deglución.</li>
                  <li><strong>Estenosis esofágica</strong>: Cicatrización causada por la exposición crónica al ácido, que puede estrechar el esófago y dificultar la deglución.</li>
                  <li><strong>Barrett esofágico</strong>: Cambios en el revestimiento del esófago que aumentan el riesgo de cáncer esofágico.</li>
                  <li><strong>Cáncer de esófago</strong>: Las personas con RGE crónico tienen un riesgo elevado de desarrollar adenocarcinoma esofágico.</li>
                  <li><strong>Problemas respiratorios</strong>: El reflujo puede contribuir a asma, laringitis, o neumonía por aspiración debido a la inhalación de ácidos.</li>
                  <li><strong>Deterioro de la calidad de vida</strong>: El dolor y las molestias asociadas con RGE pueden afectar el bienestar general y la capacidad para llevar a cabo actividades diarias.</li>
                  <li><strong>Problemas dentales</strong>: El ácido puede erosionar el esmalte dental, lo que puede llevar a caries y sensibilidad dental.</li>
                </ul>

                <h3 className='my-3 my-lg-5'>Conclusión</h3>
                <p>
                  Es importante que los pacientes con síntomas de reflujo gastroesofágico busquen atención médica para recibir un diagnóstico adecuado y tratamiento. La gestión del RGE puede incluir cambios en la dieta, medicamentos y, en casos graves, intervenciones quirúrgicas. Reconocer y tratar el RGE a tiempo es esencial para prevenir complicaciones a largo plazo.
                </p>
              </div>
            </Row>




            <div className="doctor-section-content">
              <h2>Servicios que ofrecemos en la <br />Unidad de Reflujo y Hernia Hiatal:</h2>
              <hp className="p-5 color-text-primary fw-medium fz-1-2">
                Bienvenido a nuestra Unidad de Hernia Hiatal, un centro especializado en el diagnóstico, tratamiento y prevención de esta afección. Utilizamos técnicas avanzadas como la cirugía laparoscópica, tratamiento con láser y terapias de última generación para un manejo eficaz y seguro.
              </hp>
              <div>
                <button
                  className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`}
                  onClick={() => handleServiceSelect('Consulta')}
                >
                  Consulta
                </button>
                <button
                  className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`}
                  onClick={() => handleServiceSelect('Estudios')}
                >
                  Estudios
                </button>
                <button
                  className={`button-menu-doctor ${selectedService === 'Procedimiento' ? 'active' : ''}`}
                  onClick={() => handleServiceSelect('Procedimiento')}
                >
                  Procedimiento
                </button>
                <button
                  className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`}
                  onClick={() => handleServiceSelect(null)}
                >
                  Mostrar Todos
                </button>
              </div>
              <ServiceList serviceToShow={selectedService} moduleToShow="Reflujo" />
              {/* <ServiceList serviceToShow={selectedService} moduleToShow="Cirugia" /> */}
            </div>

            <div className="doctor-section-content">
              <h2>Conoce a nuestro Equipo Médico:</h2>
              <p>
                Contamos con un equipo de especialistas en hernia hiatal, comprometidos a ofrecer tratamientos avanzados basados en las últimas investigaciones médicas.
              </p>
              <div>
                <DoctorList specialtyToShow="Reflujo y Hernia" />
              </div>
            </div>
            <Row>
              <h4 className='fz-2 fw-bolder my-2 my-lg-5'>Tratamientos Relacionados con el Reflujo Gastroesofágico</h4>

              {/* //AQUI VAN LOS TRATAMIENTOS DE STRETTA y Funduplicatura  */}
              {Object.values(EnfermedadesReflujo.esofago).map((publicacion, index) => (
                <Col md={4} className="mb-4" key={index}>
                  <div className="card-publicaciones">
                    {/* <img
                      src={publicacion.image}
                      alt={publicacion.title}
                      className="card-img-top"
                      aria-describedby={`description-${index}`}
                    /> */}
                    <div className="card-body">
                      <h5 className="card-title" id={`title-${index}`}>
                        {publicacion.title}
                      </h5>
                      <p className="card-text" id={`description-${index}`}>
                        {publicacion.description}
                      </p>
                      <Link to={publicacion.link} className="btn-publicaciones" aria-labelledby={`title-${index}`}>
                        Leer más
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <h4 className='fz-2 fw-bolder my-2 my-lg-5'>Articulos Relacionados con el Reflujo Gastroesofágico</h4>
              {filteredPublicaciones.map((publicacion, index) => (
                <Col md={4} className="mb-4" key={index}>
                  <div className="card-publicaciones">
                    {/* <img
                      src={publicacion.image}
                      alt={publicacion.title}
                      className="card-img-top"
                      aria-describedby={`description-${index}`}
                    /> */}
                    <div className="card-body">
                      <h5 className="card-title" id={`title-${index}`}>
                        {publicacion.title}
                      </h5>
                      <p className="card-text" id={`description-${index}`}>
                        {publicacion.description.length > 100
                          ? `${publicacion.description.substring(0, 100)}...`
                          : publicacion.description}
                      </p>
                      <Link to={publicacion.link} className="btn-publicaciones" aria-labelledby={`title-${index}`}>
                        Leer más
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Suspense fallback={<div className="loader">Cargando seguros..</div>}>
            <CarouselSeguros/>
          </Suspense>
          {/* <CarouselSeguros/> */}
        </Container>
      </section>
    </>
  );
};

export default HerniaHiatalPage;
