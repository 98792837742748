import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import ThemeContext from '../extras/ThemeContext'

const DarkButton = ({ toggleScroll, handleNavLinkClick }) => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  const handleClick = () => {
    toggleTheme();
    toggleScroll();
    handleNavLinkClick();
  };

  return (
    <Button variant={isDarkMode ? 'dark' : 'light'} className="dark-button" aria-label='Boton de Cambio de tema' onClick={handleClick}>
      {isDarkMode ? <i className="bi bi-sun-fill h4"></i> : <i className="bi bi-moon-fill text-secondary h4"></i>}
    </Button>
  );
};

export default DarkButton;
